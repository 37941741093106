export const checkIban = (iban) => {
    let result = null
    if (validateIBAN(iban)) {
        result = RETURN_CODES.SUCCESS
    } else {
        result = RETURN_CODES.INVALID_FORMAT
    }
    return result
}

export const RETURN_CODES = {
    SUCCESS: 0,
    INVALID_FORMAT: 1
}

const validateIBAN = (input) => {
    var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''),
        // keep only alphanumeric characters
        code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/),
        // match and capture (1) the country code, (2) the check digits, and (3) the rest
        digits = void 0;
    // check syntax and length
    if (!code || BANKCODE_LENGTHS[code[1]] && iban.length !== BANKCODE_LENGTHS[code[1]]) {
        return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
    });
    // final check
    return mod97(digits) === 1;
}

const mod97 = (string) => {
    var checksum = string.slice(0, 2),
        fragment;

    for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
}

const BANKCODE_LENGTHS = {
    AL: 28,
    AD: 24,
    AE: 23,
    AO: 25,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BF: 27,
    BG: 22,
    BH: 22,
    BI: 16,
    BJ: 28,
    BR: 29,
    BY: 28,
    CG: 27,
    CH: 21,
    CI: 28,
    CM: 27,
    CR: 21,
    CY: 28,
    CV: 25,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    DZ: 24,
    EE: 20,
    EG: 27,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GA: 27,
    GB: 22,
    GE: 22,
    GG: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IM: 22,
    IQ: 23,
    IR: 26,
    IS: 26,
    IT: 27,
    JE: 22,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LC: 32,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MG: 27,
    MK: 19,
    ML: 28,
    MR: 27,
    MT: 31,
    MU: 30,
    MZ: 25,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SC: 31,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    SN: 28,
    ST: 25,
    TL: 23,
    TN: 24,
    TR: 26,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20
}

