<template>
    <div class="row ubo-form-step" :class="{ 'ubo-form-step--active': activeFormStep(3), 'has-errors': stepHasErrors }">
        <div class="col-12 d-xl-none ubo-step box-spacing" :class="activeStep(3)">
            <div class="ubo-step-number">
                <span>3</span>
            </div>
            <div class="ubo-step-name" data-qa="menue-mobil-rechtsform-geschaeftstaetigkeit" @click.prevent="close"><span v-if="inhabergefuehrt">Rechts­form & Ge­schäfts­tä­tig­keit</span><span v-else>Ge­schäfts­tä­tig­keit</span></div>
            <div class="ubo-step-progress">
                <span class="text-small">{{ fuellgrad.anteilRechtsformGeschaeftstaetigkeit }}</span>
            </div>
            <i class="crefo-ui-icon" aria-hidden="true"></i>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="h1"><span v-if="inhabergefuehrt">Rechts­form & Ge­schäfts­tä­tig­keit</span><span v-else>Ge­schäfts­tä­tig­keit</span></div>
            <p>
                Hier tragen Sie Informationen zur Geschäftstätigkeit ein.
            </p>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter" v-if="inhabergefuehrt">
            <div class="ubo-form-mode-view" v-if="!isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-bearbeiten-rechtsform" @click.prevent="open">
                        Bearbeiten
                        <i class="crefo-ui-icon icon-edit-pen" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Rechts­form</div>
                <div class="row no-gutters ubo-form-display">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Rechts­form</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ formatter.formatRechtsform(this.gewerblichRegistriert) }}
                    </div>
                </div>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-schliessen-rechtsform" @click.prevent="close">
                        Schliessen
                        <i class="crefo-ui-icon icon-close" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Rechts­form</div>
                <div class="row">
                    <div class="col-12">
                        <div class="crefo-input mb-small">
                            <div class="input-group">
                                <div class="radio-group">
                                    <div class="input-wrapper">
                                        <ValidationProvider
                                            v-slot="{ changed }">
                                            <div class="crefo-radio">
                                                <div class="radio-btn">
                                                    <input type="radio" id="true" name="rechtsform"
                                                           value=true
                                                           v-model="gewerblichRegistriert"
                                                           data-qa="ubo-form-rechtsform-gewerblich-registriert"
                                                           data-no-crefo-ui="false">
                                                    <label for="true">
                                                        <span class="radio-box"><span
                                                            class="radio-box-circle"></span></span>
                                                        <span class="radio-label--ubo"
                                                              :class="{'has-changed':changed&&gewerblichRegistriert===true}"> gewerblich registriert </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider
                                            v-slot="{ changed }">
                                            <div class="crefo-radio">
                                                <div class="radio-btn">
                                                    <input type="radio" id="false" name="rechtsform"
                                                           value=false
                                                           v-model="gewerblichRegistriert"
                                                           data-qa="ubo-form-rechtsform-gewerblich-registriert"
                                                           data-no-crefo-ui="false">
                                                    <label for="false">
                                                        <span class="radio-box"><span
                                                            class="radio-box-circle"></span></span>
                                                        <span class="radio-label--ubo"
                                                              :class="{'has-changed':changed&&gewerblichRegistriert===false}"> freiberuflich tätig </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb">
                        <button class="btn btn-default btn-min btn-small" :disabled="stepHasErrors" data-qa="ubo-form-aenderungen-uebernehmen"
                                @click.prevent="updateFormData">Änderungen
                            übernehmen
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="ubo-form-mode-view" v-if="!isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-bearbeiten-geschaeftstaetigkeit" @click.prevent="open">
                        Bearbeiten
                        <i class="crefo-ui-icon icon-edit-pen" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Ge­schäfts­tä­tig­keit</div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('geschaeftstaetigkeit-eingetragenerGegenstand') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Eingetragener Gegenstand</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ this.eingetragenerGegenstand }}
                        <i class="crefo-brand-icon" :class="{'icon-lock':!inhabergefuehrt}" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('geschaeftstaetigkeit-anzahlKunden') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Gesamtanzahl Kunden</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.anzahlKunden }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('geschaeftstaetigkeit-prozentanteilPrivatkunden') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Anteil Privatkunden</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ formatter.formatPercent(this.prozentanteilPrivatkunden) }}
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('geschaeftstaetigkeit-prozentanteilOeffentlicheHand') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Anteil öffentliche Hand</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ formatter.formatPercent(this.prozentanteilOeffentlicheHand) }}
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('geschaeftstaetigkeit-anzahlLieferanten') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Anzahl Hauptlieferanten</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.anzahlLieferanten }}</div>
                </div>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-schliessen-geschaeftstaetigkeit" @click.prevent="close">
                        Schliessen
                        <i class="crefo-ui-icon icon-close" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Ge­schäfts­tä­tig­keit</div>
                <div class="row">
                    <div class="col-12">
                        <ubo-input
                            field="geschaeftstaetigkeit-eingetragenerGegenstand"
                            data-qa="ubo-form-eingetragener-gegenstand-message"
                            is-valid=is-valid
                        >
                            <span class="crefo-ui-tooltip">
                                <span class="inline-wrapper">
                                    <i class="crefo-ui-icon icon-circle-info" aria-hidden="true"></i>
                                    <span class="crefo-tooltip">{{
                                            $t("UboEingabeformularView.Tooltip.RechtsformGewerblichRegistriert")
                                        }}</span>
                                </span>
                            </span>
                            <textarea v-model="eingetragenerGegenstand"
                                      :readonly="!inhabergefuehrt"
                                      data-qa="ubo-form-eingetragener-gegenstand"
                                      data-no-crefo-ui="true">
                            </textarea>
                        </ubo-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <input-field rules="max_value:99999999"
                                     type="text"
                                     field="geschaeftstaetigkeit-anzahlKunden"
                                     :label="$t('UboEingabeformularView.Label.GeschaeftstaetigkeitAnzahlKunden')"
                                     :tooltip="$t('UboEingabeformularView.Tooltip.GeschaeftstaetigkeitAnzahlKunden')"
                                     v-model="anzahlKunden" />
                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field rules="max_percent"
                                     type="text"
                                     field="geschaeftstaetigkeit-prozentanteilPrivatkunden"
                                     unit="%"
                                     :label="$t('UboEingabeformularView.Label.GeschaeftstaetigkeitProzentanteilPrivatkunden')"
                                     v-model="prozentanteilPrivatkunden" />

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <input-field rules="max_percent"
                                     type="text"
                                     field="geschaeftstaetigkeit-prozentanteilOeffentlicheHand"
                                     unit="%"
                                     :label="$t('UboEingabeformularView.Label.GeschaeftstaetigkeitProzentanteilOeffentlicheHand')"
                                     v-model="prozentanteilOeffentlicheHand" />

                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field rules="max_value:99999999"
                                     type="text"
                                     field="geschaeftstaetigkeit-anzahlLieferanten"
                                     :label="$t('UboEingabeformularView.Label.GeschaeftstaetigkeitAnzahlLieferanten')"
                                     :tooltip="$t('UboEingabeformularView.Tooltip.GeschaeftstaetigkeitAnzahlLieferanten')"
                                     v-model="anzahlLieferanten" />

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb">
                        <button class="btn btn-default btn-min btn-small" :disabled="stepHasErrors" data-qa="ubo-uebernehmen-geschaeftstaetigkeit"
                                @click.prevent="updateFormData">Änderungen
                            übernehmen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as formatter from '@/helper/formatter'
import {ValidationProvider, extend} from 'vee-validate'
import InputDate from '@/components/helper/input-date'
import UboInput from '@/components/commons/ubo-input'
import InputField from '@/components/commons/input-field'
export default {
    name: 'RechtsformGeschaeftstaetigkeit',
    components: { ValidationProvider, InputDate, UboInput, InputField },
    props: {
        menuConfig: {},
        errors: {},
        fuellgrad: {}
    },
    data() {
        return {
            rechtsform: {},
            geschaeftstaetigkeit: {},
            stepHasErrors: false
        }
    },
    computed: {
        renderEditMode() {
            return (this.menuConfig.edit && this.menuConfig.activeStep === 3)
                || this.$store.state.userModule.unternehmensdatenPreValidated === false
                || this.stepHasErrors
        },
        isEditModeActive() {
            return this.menuConfig.edit && this.menuConfig.activeStep === 3
        },
        hasErrors: function () {
            return fieldname => this.errors && this.errors[fieldname] instanceof Array && this.errors[fieldname].length
        },
        gewerblichRegistriert: {
            get() {
                let result = null
                if (this.rechtsform) {
                    result = this.rechtsform.gewerblichRegistriert
                }
                return result
            },
            set(newGewerblichRegistriert) {
                if (this.rechtsform) {
                    this.rechtsform.gewerblichRegistriert = formatter.parseBoolean(newGewerblichRegistriert)
                }
            }
        },
        inhabergefuehrt:{
            get() {
                let result = null
                if (this.rechtsform) {
                    result = this.rechtsform.inhabergefuehrt
                }
                return result
            }
        },
        anzahlKunden: {
            get() {
                let result = null
                if (this.geschaeftstaetigkeit) {
                    result = formatter.formatNumber(this.geschaeftstaetigkeit.anzahlKunden)
                }
                return result
            },
            set(newAnzahlKunden) {
                if (this.geschaeftstaetigkeit) {
                    this.geschaeftstaetigkeit.anzahlKunden = formatter.parseNumber(newAnzahlKunden)
                }
            }
        },
        prozentanteilPrivatkunden: {
            get() {
                let result = null
                if (this.geschaeftstaetigkeit) {
                    result = formatter.formatNumber(this.geschaeftstaetigkeit.prozentanteilPrivatkunden)
                }
                return result
            },
            set(newProzentanteilPrivatkunden) {
                if (this.geschaeftstaetigkeit) {
                    this.geschaeftstaetigkeit.prozentanteilPrivatkunden = formatter.parseNumber(newProzentanteilPrivatkunden)
                }
            }
        },
        prozentanteilOeffentlicheHand: {
            get() {
                let result = null
                if (this.geschaeftstaetigkeit) {
                    result = formatter.formatNumber(this.geschaeftstaetigkeit.prozentanteilOeffentlicheHand)
                }
                return result
            },
            set(newProzentanteilOeffentlicheHand) {
                if (this.geschaeftstaetigkeit) {
                    this.geschaeftstaetigkeit.prozentanteilOeffentlicheHand = formatter.parseNumber(newProzentanteilOeffentlicheHand)
                }
            },

        },
        anzahlLieferanten: {
            get() {
                let result = null
                if (this.geschaeftstaetigkeit) {
                    result = formatter.formatNumber(this.geschaeftstaetigkeit.anzahlLieferanten)
                }
                return result
            },
            set(newAnzahlLieferanten) {
                if (this.geschaeftstaetigkeit) {
                    this.geschaeftstaetigkeit.anzahlLieferanten = formatter.parseNumber(newAnzahlLieferanten)
                }
            }
        },
        eingetragenerGegenstand: {
            get() {
                let result = null
                if (this.geschaeftstaetigkeit) {
                    result = formatter.formatString(this.geschaeftstaetigkeit.eingetragenerGegenstand)
                }
                return result
            },
            set(newEingetragenerGegenstand) {
                if (this.geschaeftstaetigkeit) {
                    this.geschaeftstaetigkeit.eingetragenerGegenstand = formatter.parseString(newEingetragenerGegenstand)
                }
            }
        }
    },
    methods: {
        activeFormStep(step) {
            return this.menuConfig.activeStep === step
        },
        activeStep(step) {
            let result = null
            if (this.menuConfig.activeStep === step) {
                result = 'ubo-step--active'
            }
            return result
        },
        open() {
            this.restoreFormData()
            this.$emit("edit-step", 3)
        },
        close() {
            this.$emit('select-step', 3)
        },
        restoreFormData() {
            try {
                this.rechtsform = JSON.parse(JSON.stringify(this.$store.state.userModule.unternehmensdaten.rechtsform))
                this.geschaeftstaetigkeit = JSON.parse(JSON.stringify(this.$store.state.userModule.unternehmensdaten.geschaeftstaetigkeit))
            } catch (e) {}
        },
        updateFormData() {
            this.$store.commit('userModule/ON_CHANGE_LEGAL_FORM_INFORMATION', this.rechtsform)
            this.$store.commit('userModule/ON_CHANGE_BUSINESS_ACTIVITY', this.geschaeftstaetigkeit)
            this.$emit('save-step')
            this.$emit("select-step", 3)
        }
    },
    inject: {
        editEventBus: "editEventBus",
        formatter: "formatter"
    },
    created: function () {
        this.editEventBus.$on('has-errors-step-3', (hasErrors) => {
            this.stepHasErrors = hasErrors
        })
        this.restoreFormData()
    }
}
</script>

<!-- Formularkomponenten -->
<style scoped lang="less">

.radio-label--ubo {
    line-height: 30px;
    padding-left: .25rem;
    vertical-align: top;
    color: #4c4c4c;
}

.has-changed {
    color: #8acac1 !important;
}

.crefo-input {
    padding-top: 12px;
    padding-bottom: 12px;

    .input-group {
        .error-msg {
            min-height: 30px;
            visibility: hidden;
            display: inline-block !important;
            font-size: 12px !important;
        }

        &.has-error {

            & > input[type="checkbox"] + label:before {
                border-color: #e05469;
            }

            label {
                color: #e05469;
            }

            .error-msg {
                visibility: visible;
            }
        }
    }

    textarea {
        min-height: 200px !important;
    }
}

.ubo-form-chapter {
    display: none;
    position: relative;
    border-bottom: 1px solid #edece5;

    &:last-child {
        padding-bottom: 30px !important;

        @media only screen and (min-width: 1200px) {
            padding-bottom: 40px !important;
            border-bottom: 0 none transparent;
        }
    }

    .h2 {
        padding-right: 140px;
    }

    .ubo-form-edit {
        position: absolute;
        top: 33px;
        right: 17px;
        width: 130px;
        height: 20px;
        font-weight: 600;
        text-align: right;

        @media only screen and (max-width: 1199px) {
            top: 23px;
        }
        @media only screen and (max-width: 767px) {
            top: 18px;
        }

        a {
            text-transform: uppercase;

            i {
                position: relative;
                top: 5px;
            }
        }
    }

    .ubo-form-display {
        align-items: flex-end;

        &:last-child {
            margin-bottom: 30px;
        }

        .ubo-form-display-label, .ubo-form-display-data {
            padding-top: 30px;
            padding-bottom: 15px;
            border-bottom: 1px solid #edece5;

            @media only screen and (max-width: 991px) {
                border-bottom: 0 none transparent;
            }
        }

        .ubo-form-display-label {
            padding-right: 15px;

            @media only screen and (max-width: 991px) {
                padding-bottom: 5px;
            }
        }

        .ubo-form-display-data {
            font-weight: 600;

            .crefo-brand-icon {
                color: #b5b5b5;
                font-size: 12px;
            }

            @media only screen and (max-width: 991px) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    .ubo-form-mode-edit {
        .ubo-input-wrapper-label {
            margin-bottom: -2px;

            strong {
                font-size: 20px;
                color: #004884;
            }
        }

        .crefo-input {
            margin-bottom: 0;

            .input-group {
                margin-bottom: 10px;

                input {
                    &::placeholder {
                        color: #b5b5b5;
                    }
                }
            }

        }
    }
}

.ubo-form-step {
    &--active {
        .ubo-form-chapter {
            display: block;
        }
    }
}
</style>
