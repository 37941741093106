import * as api from '../services/index.js'
import store from '../store'
import HelperUser from '@dm/helper-user'
import HelperCrefotrust from '@dm/helper-crefotrust'
/**
 * Mögliche Edgecases:
 *
 * User NICHT eingeloggt
 *              -> Firmensuche
 *                      -> Absprung Registrierung
 * User IST eingeloggt
 *      Crefonummer NICHT bekannt, KEINE VB-Rolle
 *              -> Firmensuche
 *                      -> Absprung Verifizierung
 *      Crefonummer NICHT bekannt, EINE oder MEHRERE VB-Rollen
 *              - Sollte hoffentlich nicht möglich sein?
 *      Crefonummer IST bekannt, KEINE VB-Rolle
 *              -> Absprung Verifizierung
 *      Crefonummer IST bekannt, EINE oder MEHRERE VB-Rollen
 *              - Crefonummer stimmt mit einer der VB-Rollen überein
 *                      -> (Auswahl andere Firma aus VB-Rollen)
 *                      -> Absprung Ubo-Formular
 *              - Crefonummer stimmt nicht mit einer der VB-Rollen überein
 *                      -> (Auswahl andere Firma aus VB-Rollen)
 *                      -> Absprung Verifizierung
 *
 * Ob Crefonummer unverified (Registrierter User) oder verified (Mitgliedsnutzer) sollte für UBO keine Rolle spielen.
 */

/**
 * @typedef UserState
 * @type {object}
 * @property {boolean} isLoggedIn
 * @property {?string} ausgewaehlteCrefonummer
 * @property {?string} zugeordneteCrefonummer
 * @property {string[]} vertretungsberechtigteCrefonummern
 * @property {string[]} verfuegbareCrefonummern
 * @property {string[]} erlaubteRouten
 * @property {?string} naechsterSchritt
 */

/**
 * @return UserState
 */
function getDefaultUserStateObject () {
    return {
        isLoggedIn: false,                          // Aktueller User ist eingeloggt?
        ausgewaehlteCrefonummer: null,              // Aktuell ausgewählte Crefonummer für UBO
        zugeordneteCrefonummer: null,               // Crefonummer des Users
        vertretungsberechtigteCrefonummern: [],     // Crefonummern für die der User bereits VB ist
        unternehmensagentCrefonummern: [],          // Crefonummern für die der User bereits UA ist
        verfuegbareCrefonummern: [],                // Auswahlmöglichkeit bestehend aus vertretungsberechtigte Crefonummern und Crefonummer des Users (evtl. noch nicht VB),
        erlaubteRouten: [],                         // Routen die der User aktuell aufrufen darf
        naechsterSchritt: null                      // Nächste sinnvolle Route in der User-Journey
    }
}

/**
 * Check state of current user.
 *
 * @param {object} currentRoute
 * @param {Number} maxWaitingTime Maximum time in ms to wait for authetication check.
 * @param {boolean} redirectInstant Route parameter for aktivierung-vertretungsberechtigt, if that is the next step
 * @return {UserState}
 */
export const checkUserState = async (currentRoute, maxWaitingTime = 0, redirectInstant = false) => {
    const userState = getDefaultUserStateObject()
    maxWaitingTime = getLoginWaitTimeout(maxWaitingTime)

    if (await isUserLoggedIn(maxWaitingTime)) {
        userState.isLoggedIn = true
        if (
            currentRoute.params?.requestedTo === 'verifikation-pruefseite-success' ||
            currentRoute.params?.requestedTo === 'pin-pruefseite-success'
        ) {
           let crefonummer = await getCrefonummerForCurrentUser(currentRoute)
           try {
               await HelperCrefotrust.checkIsVerifiedOrAgent(crefonummer)
           } catch (error) {
               console.log('Crefotrust not verified')
           }
        }
        userState.vertretungsberechtigteCrefonummern = HelperUser.getAllCrefonummernOfRoleVertretungsberechtigung()
        userState.unternehmensagentCrefonummern = HelperUser.getAllCrefonummernOfRoleUnternehmensagent()
        userState.verfuegbareCrefonummern.push(...userState.vertretungsberechtigteCrefonummern)
        userState.verfuegbareCrefonummern.push(...userState.unternehmensagentCrefonummern)
        userState.zugeordneteCrefonummer = await getCrefonummerForCurrentUser(currentRoute)
        if (userState.zugeordneteCrefonummer !== null && userState.verfuegbareCrefonummern.includes(userState.zugeordneteCrefonummer) === false) {
            // An den Anfang der Liste hinzufügen
            userState.verfuegbareCrefonummern.unshift(userState.zugeordneteCrefonummer)
        }
        return getDeterminedUserState(userState, currentRoute, redirectInstant)
    } else {
        return getDeterminedUserState(userState, currentRoute, redirectInstant)
    }
}

/**
 * Should be called after crefonummer or VB status has changed to allow other routes.
 *
 * @param {?string} [newCrefonummer]
 */
export const getUpdatedUserState = (newCrefonummer) => {
    const userState = getDefaultUserStateObject()

    userState.isLoggedIn = window && window.keycloak && window.keycloak.token && window.keycloak.tokenParsed ? true : false
    userState.vertretungsberechtigteCrefonummern = HelperUser.getAllCrefonummernOfRoleVertretungsberechtigung()
    userState.unternehmensagentCrefonummern = HelperUser.getAllCrefonummernOfRoleUnternehmensagent()
    userState.verfuegbareCrefonummern.push(...userState.vertretungsberechtigteCrefonummern)
    userState.verfuegbareCrefonummern.push(...userState.unternehmensagentCrefonummern)
    if (typeof newCrefonummer === 'string' && newCrefonummer.length) {
        userState.zugeordneteCrefonummer = newCrefonummer
    } else if (typeof store.state.userModule.userState === 'object' && store.state.userModule.userState !== null) {
        userState.zugeordneteCrefonummer = store.state.userModule.userState.zugeordneteCrefonummer
    }
    if (userState.zugeordneteCrefonummer !== null && userState.verfuegbareCrefonummern.includes(userState.zugeordneteCrefonummer) === false) {
        // An den Anfang der Liste hinzufügen
        userState.verfuegbareCrefonummern.unshift(userState.zugeordneteCrefonummer)
    }
    return getDeterminedUserState(userState)
}

/**
 * Main worker function to decide what will be the user state.
 *
 * @param {UserState} userState
 * @param {?object} currentRoute
 * @param {boolean} redirectInstant Route parameter for aktivierung-vertretungsberechtigt, if that is the next step
 * @return {UserState}
 */
function getDeterminedUserState (userState, currentRoute = null, redirectInstant = false) {
    if (userState.isLoggedIn === false) {
        /* User NICHT eingeloggt
         *      -> Firmensuche
         *          -> Absprung Registrierung
         */

        userState.erlaubteRouten.push('landingpage')
        userState.naechsterSchritt = 'landingpage'
        userState.naechsterSchritt = {
            name: 'landingpage',
            query: getAllQueryParametersForRedirect(currentRoute)
        }
    } else {
        // User IST eingeloggt
        if (typeof userState.zugeordneteCrefonummer === 'string') {
            store.commit('userModule/ON_CHANGE_CREFONUMBER', userState.zugeordneteCrefonummer, { root: true })
        }
        if (userState.zugeordneteCrefonummer === null &&
            userState.vertretungsberechtigteCrefonummern.length === 0 &&
            userState.unternehmensagentCrefonummern.length === 0) {
            /* Crefonummer NICHT bekannt, KEINE VB-Rolle
             *      -> Firmensuche
             *          -> Absprung Verifizierung
             */

            userState.erlaubteRouten.push('verifikation', 'verifikation-pruefseite-error')
            userState.naechsterSchritt = {
                name: 'verifikation',
                query: getAllQueryParametersForRedirect(currentRoute)
            }
        } else if (userState.zugeordneteCrefonummer === null && (userState.vertretungsberechtigteCrefonummern.length > 0 || userState.unternehmensagentCrefonummern.length > 0)) {
            // Crefonummer NICHT bekannt, EINE oder MEHRERE VB-Rollen

            userState.erlaubteRouten.push('verifikation', 'verifikation-pruefseite-error')
            userState.naechsterSchritt = {
                name: 'verifikation',
                query: getAllQueryParametersForRedirect(currentRoute)
            }
        } else if (userState.zugeordneteCrefonummer !== null &&
            userState.vertretungsberechtigteCrefonummern.length === 0 &&
            userState.unternehmensagentCrefonummern.length === 0
        ) {
            /* Crefonummer IST bekannt, KEINE VB-Rolle
             *      -> Absprung Verifizierung
             */

            userState.ausgewaehlteCrefonummer = userState.zugeordneteCrefonummer
            userState.erlaubteRouten.push('verifikation', 'verifikation-pruefseite-error')
            userState.naechsterSchritt = {
                name: 'verifikation',
                query: getAllQueryParametersForRedirect(currentRoute)
            }
        } else {
            // Crefonummer IST bekannt, EINE oder MEHRERE VB-Rollen

            if (
                userState.vertretungsberechtigteCrefonummern.includes(userState.zugeordneteCrefonummer) ||
                userState.unternehmensagentCrefonummern.includes(userState.zugeordneteCrefonummer)
            ) {
                /* Crefonummer stimmt mit einer der VB-Rollen überein
                 *      -> (Auswahl andere Firma aus VB-Rollen)
                 *      -> Absprung Ubo-Formular
                 */

                userState.ausgewaehlteCrefonummer = userState.zugeordneteCrefonummer
                userState.erlaubteRouten.push('aktivierung-vertretungsberechtigt', 'verifikation-pruefseite-success', 'pin-pruefseite-success')
                userState.naechsterSchritt = {
                    name: 'aktivierung-vertretungsberechtigt',
                    query: getAllQueryParametersForRedirect(
                        currentRoute,
                        { crefonummer: userState.ausgewaehlteCrefonummer }
                    ),
                    params: {
                        redirectInstant
                    }
                }
            } else {
                /* Crefonummer stimmt NICHT mit einer der VB-Rollen überein
                 *      -> (Auswahl andere Firma aus VB-Rollen)
                 *      -> Absprung Verifizierung
                 */

                userState.ausgewaehlteCrefonummer = userState.zugeordneteCrefonummer
                userState.erlaubteRouten.push('verifikation', 'verifikation-pruefseite-error')
                userState.naechsterSchritt = {
                    name: 'verifikation',
                    query: getAllQueryParametersForRedirect(currentRoute)
                }
            }
        }
    }

    if (currentRoute && currentRoute.params && currentRoute.params.requestedTo
            && userState.erlaubteRouten.includes(currentRoute.params.requestedTo)) {
        userState.naechsterSchritt.name = currentRoute.params.requestedTo
    }

    return userState
}

function getAllQueryParametersForRedirect (currentRoute, queryParameters = {}) {
    if (typeof currentRoute === 'object' && currentRoute !== null) {
        return { ...queryParameters, ...currentRoute.query }
    }
    return queryParameters
}

function getLoginWaitTimeout (defaultTimeout) {
    if (
        window.location
        && window.location.hash
        && window.location.hash.indexOf('#state=') !== -1
        && window.location.hash.indexOf('&code=') !== -1
        && window.location.hash.indexOf('&session_state=') !== -1
    ) {
        return defaultTimeout * 10
    } else {
        return defaultTimeout
    }
}

/**
 * Check if user is logged in.
 *
 * @param {Number} maxWaitingTime
 * @return {Promise<boolean>}
 */
function isUserLoggedIn (maxWaitingTime) {
    let waitTimeCumulated = 0

    function checkLoginState () {
        return window && window.keycloak && window.keycloak.token
            && window.keycloak.tokenParsed ? true : false
    }

    function checkLoginStateDelayed (resolve, timeout) {
        if (checkLoginState()) {
            resolve(true)
        } else if (window.keycloakStatusChecked === true || waitTimeCumulated > maxWaitingTime) {
            resolve(false)
        } else {
            timeout = Math.max(Math.min(timeout, maxWaitingTime - waitTimeCumulated), 1)
            setTimeout(() => {
                waitTimeCumulated += timeout
                checkLoginStateDelayed(resolve, timeout * 2)
            }, timeout)
        }
    }

    return new Promise(resolve => {
        checkLoginStateDelayed(resolve, 50)
    })
}

/**
 * Get Crefonummer for current user.
 *
 * @return {Promise<null|string>}
 */
async function getCrefonummerForCurrentUser (currentRoute) {
    let response

    if (window && window.keycloak && window.keycloak.tokenParsed) {
        if (typeof currentRoute === 'object' && currentRoute !== null) {
            if (currentRoute.query && typeof currentRoute.query.prt === 'string' && currentRoute.query.prt.length) {
                try {
                        // Call /registrierung-actions/bestaetige-registrierung
                    response = await api.doConfirmRegistration(currentRoute.query.prt)

                    if (response && response.data && typeof response.data.crefonummer === 'string'
                            && response.data.crefonummer.length) {
                        return response.data.crefonummer
                    }
                } catch (error) {
                    response = null
                }
            }
        }
    }

    try {
        // Fallback to /members
        response = await api.getMitglied()

        if (response && response.data && typeof response.data.businessId === 'string' && response.data.businessId.length) {
            return response.data.businessId
        }
    } catch (error) {
        response = null
    }

    try {
        // Fallback to /api/user-management/persons/me
        response = await api.getUser()

        if (response && response.data && typeof response.data.unverifiedCrefonummer === 'string'
            && response.data.unverifiedCrefonummer.length) {
            return response.data.unverifiedCrefonummer
        }
    } catch (error) {
        response = null
    }

    if (window && window.keycloak && window.keycloak.tokenParsed
        && typeof window.keycloak.tokenParsed.cr_id === 'string') {
        try {
            // Call /registrierte-nutzer/{crid}/crefonummer
            response = await api.getCrefonummerByCrid(window.keycloak.tokenParsed.cr_id)

            if (response && response.data && typeof response.data.crefonummer === 'string'
                && response.data.crefonummer.length) {
                return response.data.crefonummer
            }
        } catch (error) {
            response = null
        }
    }

    return null
}
