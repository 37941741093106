let mock
try {
    const axios = require('axios')
    const MockAdapter = require('axios-mock-adapter')
    mock = new MockAdapter(axios, { onNoMatch: 'passthrough' })
} catch (e) {}

mock.onGet(/^\/api\/kurzprofile\/.+/).reply(200, {
    firmenname: 'Wilken UG',
    crefonummer: '4110173035',
    auskunftsId: null,
    adresse: {
        land: 'Deutschland',
        plz: '58119',
        ort: 'Hagen',
        ortsteil: null,
        strasse: 'Mozartstr.',
        hausnummer: '12',
        hausnummerZusatz: null
    },
    firmenstatus: 'Übernommen / verschmolzen',
    telefonnummer: null,
    internetAdresse: null,
    email: null,
    handelsregisternummer: '112233',
    gewerbetaetigkeit: null
});
