<template>
    <div
        v-if="visible"
        class="crefo-ui-modal"
        :class="modalIconClass"
        :data-qa="dataQaName"
        ref="modal"
    >
        <div class="modal-content">
            <div class="modal-header">
                <i
                    v-if="icon"
                    :class="headerIconClass"
                ></i>
                <h2>{{ title }}</h2>
                <span
                    data-qa="modal-close-button"
                    class="modal-close"
                    @click.prevent="close"
                ></span>
            </div>
            <div class="modal-body">
                <slot></slot>
                <crefo-ring-loading
                    class="mb-small"
                    :wrap-in-row="true"
                    :style="{visibility: showSpinner ? 'visible' : 'hidden'}"
                />
                <div class="info-button-group pt-small">
                    <button
                        v-if="confirmLabel !== ''"
                        class="btn btn-default btn-min"
                        :class="{ 'btn-disabled': confirmButtonDisabled}"
                        :disabled="confirmButtonDisabled"
                        @click.prevent="submit"
                        data-qa="confirm-button"
                    >
                        {{ confirmLabel }}
                    </button>
                    <button
                        v-if="cancelLabel !== ''"
                        class="btn btn-default btn-min"
                        @click.prevent="close"
                        data-qa="cancel-button"
                    >
                        {{ cancelLabel }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * Shows a modal dialog.
 *
 * Example usage:
 *     <modal-dialog
 *         icon="error"
 *         :title="$t('Error.Text')"
 *         :confirmLabel="$t('OK.Label')"
 *         :confirm-callback="myFunc"
 *         v-bind:visible="showModal"
 *     >
 *         <p>Es liegen {{ numberErrors }} Fehler vor</p>
 *     </modal-dialog>
 *
 *     methods: {
 *         myFunc: (event) {
 *             alert('OK was pressed')
 *         }
 *     }
 */
import CrefoRingLoading from '@dm/crefo-ring-loading'
export default {
    name: 'ModalDialog',
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        icon: {
            type: String,
            required: false,
            default: '',
            validator: value => {
                return ['', 'success', 'error', 'info', 'warning'].includes(value)
            }
        },
        dataQa: {
            type: String,
            required: false,
            default: ''
        },
        confirmLabel: {
            type: String,
            required: false,
            default: ''
        },
        cancelLabel: {
            type: String,
            required: false,
            default: ''
        },
        confirmCallback: {
            type: Function,
            required: false
        },
        cancelCallback: {
            type: Function,
            required: false
        },
        visible: {
            type: Boolean,
            required: false,
            default: true
        },
        confirmButtonDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        showSpinner: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: { CrefoRingLoading },
    computed: {
        modalIconClass() {
            return this.icon.length ? `modal-statusicon modal-${this.icon}` : ''
        },
        dataQaName() {
            return this.dataQa ? `crefo-ui-modal--${this.dataQa}` : 'crefo-ui-modal'
        },
        headerIconClass() {
            if (this.icon === 'success') {
                return 'crefo-ui-icon icon-circle-success'
            } else if (this.icon === 'error') {
                return 'crefo-ui-icon icon-circle-error'
            } else if (this.icon === 'info') {
                return 'crefo-ui-icon icon-circle-info'
            } else if (this.icon === 'warning') {
                return 'crefo-ui-icon icon-circle-warning'
            }
        }
    },
    methods: {
        close() {
            if (this.visible) {
                if (typeof this.cancelCallback === 'function') {
                    this.cancelCallback()
                }
            }
        },
        submit() {
            if (this.visible && !this.confirmButtonDisabled) {
                if (typeof this.confirmCallback === 'function') {
                    this.confirmCallback()
                }
            }
        }
    },
}
</script>


<style scoped lang="less">
.info-button-group {
    [data-qa="cancel-button"] {
        position: absolute;
        right: 30px;
    }
}
.modal-content {
    max-width: 600px;
    text-align: left;
}
</style>
