import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { dispatchEvent } from '../helper/polyfill'
import IndexView from '../views/index'
import UboLandingpageView from '../views/ubo-landingpage'
import UboVerifikationView from '../views/ubo-verifikation'
import UboAktivierungVertretungsberechtigtView from '../views/ubo-aktivierung-vertretungsberechtigt'
import UboEingabeformularView from '../views/ubo-eingabeformular'
import UboBestaetigungView from '../views/ubo-bestaetigung'

Vue.use(VueRouter)

const BASE_PATH_MICROFRONTEND_CONTAINER = '/portal'
const PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP = 'ubo'

const getLoginWaitTimeout = defaultTimeout => {
    if (
        window.location
        && window.location.hash
        && window.location.hash.indexOf('#state=') !== -1
        && window.location.hash.indexOf('&code=') !== -1
        && window.location.hash.indexOf('&session_state=') !== -1
    ) {
        return defaultTimeout * 5
    } else {
        return defaultTimeout
    }
}

const isAuthenticated = (to, from, next, timeout = 100, timeElapsed = 0) => {
    const loginWaitTimeout = getLoginWaitTimeout(15000)

    if (store.state.userModule.authenticated) {
        next()
    } else if (window.keycloak && window.keycloak.token) {
        store.commit('userModule/ON_VALID_TOKEN_EXISTS', window.keycloak, { root: true })
        next()
    } else if (window.keycloakStatusChecked === true || timeElapsed >= loginWaitTimeout) {
        dispatchEvent('redirectToLogin')
        setTimeout(() => {
            next(false)
        }, 3000)
    } else {
        timeout = Math.max(Math.min(timeout, loginWaitTimeout - timeElapsed), 1)
        setTimeout(() => {
            isAuthenticated(to, from, next, timeout * 2, timeElapsed + timeout)
        }, timeout)
    }
}

const isAllowedRoute = (to , from, next) => {
    if (store.state.userModule.isUserStateDetermined && store.state.userModule.userState.erlaubteRouten.includes(to.name)) {
            // User state is set and route is allowed
        console.log('Route Guard: Route to ' + to.name + ' is allowed')
        next()
    } else if (to.name === 'index') {
            // Index is always allowed
        console.log('Route Guard: Route to ' + to.name + ' is always allowed')
        next()
    } else {
        // Redirect with all query params to index, to get user state
        console.log('Route Guard: Route to ' + to.name + ' is not allowed')
        next({ name: 'index', params: { requestedTo: to.name, }, query: to.query })
    }
}

const isActivated = (to, from, next) => {
    if (store.state.userModule.activated === true) {
        console.log('Route Guard: Is activated')
        next()
    } else {
        console.log('Route Guard: Not activated')
        next({ name: 'index', params: { redirectInstant: true } })
    }
}

const routes = [
    {
            // Startseite mit automatischer Weiterleitung
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}`,
        name: 'index',
        component: IndexView,
        props: (route) => ({
            redirectInstant: typeof route.params.redirectInstant === 'boolean' ? route.params.redirectInstant : false
        })
    },
    {
            // Allgemeine Landingpage für nicht eingeloggte User
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/unternehmensdaten-pflegen`,
        name: 'landingpage',
        component: UboLandingpageView,
        props: (route) => ({
            preselectedCrefonummer: typeof route.query.crefonummer === 'string' ? route.query.crefonummer : ''
        }),
        beforeEnter: isAllowedRoute
    },
    {
            // Crefotrust-Verifikation für registrierte/eingeloggte User mit bekannter Crefonummer
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/verifikation`,
        name: 'verifikation',
        component: UboVerifikationView,
        props: (route) => ({
            prt: typeof route.query.prt === 'string' ? route.query.prt : '',
            mockJwt: typeof route.query.jwt === 'string' ? route.query.jwt : '',
            preselectedCrefonummer: typeof route.query.crefonummer === 'string' ? route.query.crefonummer : ''
        }),
        beforeEnter: (to, from, next) => {
            isAuthenticated(to, from, () => {
                isAllowedRoute(to, from, next)
            })
        }
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/verifikation/verifiziert/`,
        name: 'verifikation-pruefseite-success',
        component: UboAktivierungVertretungsberechtigtView,
        props: (route) => ({
            verified: true,
            redirectInstant: true
        }),
        beforeEnter: (to, from, next) => {
            isAuthenticated(to, from, () => {
                isAllowedRoute(to, from, next)
            })
        }
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/verifikation/pin/`,
        name: 'pin-pruefseite-success',
        component: UboAktivierungVertretungsberechtigtView,
        props: (route) => ({
            redirectInstant: true
        }),
        beforeEnter: (to, from, next) => {
            isAuthenticated(to, from, () => {
                isAllowedRoute(to, from, next)
            })
        }
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/verifikation/vb-verifikation-fehler/`,
        name: 'verifikation-pruefseite-error',
        component: UboVerifikationView,
        props: (route) => ({
            verified: false,
        }),
        beforeEnter: (to, from, next) => {
            isAuthenticated(to, from, () => {
                isAllowedRoute(to, from, next)
            })
        }
    },
    {
            // Absprung in Ubo-Formular in digitalem Prozess für registrierte/eingeloggte und vertretungsberechtigte User mit bekannter Crefonummer
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/jetzt-loslegen`,
        name: 'aktivierung-vertretungsberechtigt',
        component: UboAktivierungVertretungsberechtigtView,
        props: (route) => ({
            crefonummer: typeof route.query.crefonummer === 'string' ? route.query.crefonummer : '',
            redirectInstant: typeof route.params.redirectInstant === 'boolean' ? route.params.redirectInstant : false
        }),
        beforeEnter: (to, from, next) => {
            isAuthenticated(to, from, () => {
                isAllowedRoute(to, from, next)
            })
        }
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/eingabeformular`,
        name: 'eingabeformular',
        component: UboEingabeformularView,
        beforeEnter: isActivated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/bestaetigung`,
        name: 'bestaetigung',
        component: UboBestaetigungView,
        beforeEnter: isActivated
    }
]

const router = new VueRouter({
    mode: 'history',
    base: '/', // this is crucial in order to rewrite links to the single-spa container!
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        if (!to.hash) {
            window.scroll(0, 0)
        }
    }
})

export default router
