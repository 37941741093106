<template>
    <div class="crefo-input crefo-ui-select crefo-ui-select--ubo">
        <ValidationProvider :name=validationProviderName :rules=rules :vid=vid
                            v-slot="{ invalid, errors, dirty, changed }">

        <div class="input-group">
                <label>{{ $t(label) }}</label>
                <div class="select-group">
                    <div class="input-wrapper">
                        <slot></slot>
                    </div>
                </div>
        </div>
        </ValidationProvider>
    </div>
</template>
<script>

/*
 * firmenidentifikation
 * --------------------
 *
 * land
 *
 * telefonnummer-land
 * mobilnummer-land
 * faxnummer-land
 *
 * inhaber
 * -------
 *
 * inhaber-land
 *
 * ansprechpartner
 * -------------
 *
 * ansprechpartner-anrede
 *
 */
import {ValidationProvider, extend} from 'vee-validate'


export default {
    components: {ValidationProvider},
    name: 'UboSelect',
    props: {
        field: null
    },
    computed: {
        label() {

            if (this.field === 'land') {
                return 'UboEingabeformularView.Label.Land'
            } else if (this.field === 'inhaber-land') {
                return 'UboEingabeformularView.Label.InhaberLand'
            } else if (this.field === 'ansprechpartner-anrede') {
                return 'UboEingabeformularView.Label.AnsprechpartnerAnrede'
            } else if (this.field === 'telefonnummer-land') {
                return 'UboEingabeformularView.Label.TelefonLaendervorwahl'
            } else if (this.field === 'mobilnummer-land') {
                return 'UboEingabeformularView.Label.MobilLaendervorwahl'
            } else if (this.field === 'faxnummer-land') {
                return 'UboEingabeformularView.Label.FaxLaendervorwahl'
            } else if (this.field === '') {
                return ''
            } else {
                return ''
            }
        },
        rules() {
            if (this.field === '') {
                return "required"
            } else {
                return null
            }
        },
        validationProviderName() {
            if (this.field === '') {
                return ""
            } else {
                return null
            }
        },
        vid() {
            return this.field
        },
    }
}
</script>

<style scoped lang="less">

.crefo-ui-select--ubo {
    margin-top: 12px;
}

.crefo-input {
    margin-bottom: 0;

    .input-group {
        margin-bottom: 10px;

        input {
            &::placeholder {
                color: #b5b5b5;
            }
        }
    }

}

</style>
