<template>
    <div
        class="crefo-ui-alert"
        :class="type"
    >
        <div :data-qa="dataQa">
            <slot>
                <div v-html="errorMessage"></div>
            </slot>
        </div>
        <div
            v-if="correlationId"
            class="pt-small text-small"
            data-qa="error-message-correlation-id"
        >
            {{ $t('Error.CorrelationId.Prefix') }}
            {{ correlationId }}
        </div>
    </div>
</template>

<script>
/**
 * Shows an error message based on given ajaxError Object and optional custom error message.
 *
 * Usage Examples:
 *
 *   Shows default error message based on status code and correlationId.
 *   <crefo-error-message :ajax-error="ajaxError" />
 *
 *   Shows a custom warning message with correlationId
 *   <crefo-error-message
 *     :ajax-error="ajaxError"
 *     type="warning"
 *   >
 *     Bitte kontatieren sie den <a href="mailto:vertrieb@creditreform.de">Vertrieb</a>
 *   </crefo-error-message>
 *
 *   Shows a custom error message without correlationId (because ajaxError is missing)
 *   <crefo-error-message>
 *     Allgemeiner Fehler
 *   </crefo-error-message>
 */
export default {
    name: 'CrefoErrorMessage',
    props: {
        ajaxError: {
            type: Object,
            required: false,
            default: () => {
            }
        },
        type: {
            type: String,
            required: false,
            default: 'error',
            validator: value => {
                return ['success', 'error', 'info', 'warning'].includes(value)
            }
        },
        dataQa: {
            type: String,
            required: false,
            default: 'error-message'
        }
    },
    computed: {
        errorMessage() {
            if (this.ajaxError && this.ajaxError.defaultErrorMessage) {
                return this.ajaxError.defaultErrorMessage
            }

            return ''
        },
        correlationId() {
            if (this.ajaxError && this.ajaxError.correlationId) {
                return this.ajaxError.correlationId
            }

            return ''
        }
    }
}
</script>
