<template>
    <div>
        <kopf
            :scrollToStart="firstStep"
            :getProgressCoordinates="getProgressCoordinates"
            :vorname="user.givenName"
            :nachname="user.surname"
            :anteilGesamt="fuellgrad.anteilGesamt"
        />
        <ValidationObserver v-slot="{ invalid, validate, errors }">
            <div class="bg-brand bg-brand-light-grey big-box-spacing mb-large">
                <div class="container">
                    <div class="col-12">
                        <div class="h1">Interview</div>
                    </div>
                    <absenden
                        :is-invalid="invalid || sendingUboIsBlocked"
                        :top="false"
                    >
                        <p>Bitte prüfen und vervollständigen Sie alle Daten zu Ihrem Unternehmen, indem Sie die
                            einzelnen Bereiche öffnen, Ihre Daten anpassen und die Änderungen speichern.</p>
                        <p><strong>Wichtig:</strong> Die Daten zu Ihrem Unternehmen - inklusive der von Ihnen
                            vorgenommenen Änderungen - werden erst an uns übermittelt, wenn Sie zum Abschluss den
                            Button <strong>„Daten senden”</strong> klicken, der sich oberhalb und unterhalb der
                            Befragung befindet.</p>
                    </absenden>
                    <div class="row ubo-form-wrapper mb">
                        <div
                            class="col-12 bg-white box-shadow-xy"
                            ref="ubo-menue"
                        >
                            <div class="row">
                                <div class="col-12 col-xl-4 d-none d-xl-block ubo-steps">
                                    <menue
                                        :menuConfig="menuConfig"
                                        :inhabergefuehrt="inhabergefuehrt"
                                        :fuellgrad="fuellgrad"
                                        :errors="errors"
                                        @select-step="onSelectStep"
                                        @has-errors-step-1="hasErrors => stepsWithErrors['1'] = hasErrors"
                                        @has-errors-step-2="hasErrors => stepsWithErrors['2'] = hasErrors"
                                        @has-errors-step-3="hasErrors => stepsWithErrors['3'] = hasErrors"
                                        @has-errors-step-4="hasErrors => stepsWithErrors['4'] = hasErrors"
                                        @has-errors-step-5="hasErrors => stepsWithErrors['5'] = hasErrors"
                                        @has-errors-step-6="hasErrors => stepsWithErrors['6'] = hasErrors"
                                        @has-errors-step-7="hasErrors => stepsWithErrors['7'] = hasErrors"
                                    />
                                </div>
                                <div class="col-12 col-xl-8">
                                    <firmenidentifikation
                                        v-if="isStepActive(1)"
                                        ref="step_1"
                                        :errors="errors"
                                        :menuConfig="menuConfig"
                                        :fuellgrad="fuellgrad"
                                        :inhabergefuehrt="inhabergefuehrt"
                                        @select-step="onSelectStep"
                                        @edit-step="onEditActiveStep"
                                        @save-step="onSaveActiveStep"
                                        @validate="validate"
                                    />
                                    <firmenidentifikation-head
                                        v-else
                                        :fuellgrad="fuellgrad"
                                        @select-step="onSelectStep"
                                    />
                                    <inhaber
                                        v-if="isStepActive(2)"
                                        ref="step_2"
                                        :errors="errors"
                                        :menuConfig="menuConfig"
                                        :fuellgrad="fuellgrad"
                                        :inhabergefuehrt="inhabergefuehrt"
                                        @select-step="onSelectStep"
                                        @edit-step="onEditActiveStep"
                                        @save-step="onSaveActiveStep"
                                    />
                                    <inhaber-head
                                        v-else
                                        :fuellgrad="fuellgrad"
                                        :inhabergefuehrt="inhabergefuehrt"
                                        @select-step="onSelectStep"
                                    />
                                    <rechtsform-geschaeftstaetigkeit
                                        v-if="isStepActive(3)"
                                        ref="step_3"
                                        :errors="errors"
                                        :menuConfig="menuConfig"
                                        :fuellgrad="fuellgrad"
                                        @select-step="onSelectStep"
                                        @edit-step="onEditActiveStep"
                                        @save-step="onSaveActiveStep"
                                    />
                                    <rechtsform-geschaeftstaetigkeit-head
                                        v-else
                                        :fuellgrad="fuellgrad"
                                        :inhabergefuehrt="inhabergefuehrt"
                                        @select-step="onSelectStep"
                                    />
                                    <umsatzangaben
                                        v-if="isStepActive(4)"
                                        ref="step_4"
                                        :errors="errors"
                                        :menuConfig="menuConfig"
                                        :fuellgrad="fuellgrad"
                                        :crefonummer="crefonummer"
                                        :inhabergefuehrt="inhabergefuehrt"
                                        @select-step="onSelectStep"
                                        @edit-step="onEditActiveStep"
                                        @save-step="onSaveActiveStep"
                                    />
                                    <umsatzangaben-head
                                        v-else
                                        :fuellgrad="fuellgrad"
                                        @select-step="onSelectStep"
                                    />
                                    <unterlagen
                                        v-if="isStepActive(5)"
                                        ref="step_5"
                                        :errors="errors"
                                        :menuConfig="menuConfig"
                                        :fuellgrad="fuellgrad"
                                        @select-step="onSelectStep"
                                        @edit-step="onEditActiveStep"
                                        @save-step="onSaveActiveStep"
                                    />
                                    <unterlagen-head
                                        v-else
                                        :fuellgrad="fuellgrad"
                                        @select-step="onSelectStep"
                                    />
                                    <sonstige-unternehmensdaten
                                        v-if="isStepActive(6)"
                                        ref="step_6"
                                        :errors="errors"
                                        :menuConfig="menuConfig"
                                        :fuellgrad="fuellgrad"
                                        :inhabergefuehrt="inhabergefuehrt"
                                        @select-step="onSelectStep"
                                        @edit-step="onEditActiveStep"
                                        @save-step="onSaveActiveStep"
                                    />
                                    <sonstige-unternehmensdaten-head
                                        v-else
                                        :fuellgrad="fuellgrad"
                                        @select-step="onSelectStep"
                                    />
                                    <ansprechpartner-anmerkungen
                                        v-if="isStepActive(7)"
                                        ref="step_7"
                                        :errors="errors"
                                        :menuConfig="menuConfig"
                                        :fuellgrad="fuellgrad"
                                        @select-step="onSelectStep"
                                        @edit-step="onEditActiveStep"
                                        @save-step="onSaveActiveStep"
                                    />
                                    <ansprechpartner-anmerkungen-head
                                        v-else
                                        :fuellgrad="fuellgrad"
                                        @select-step="onSelectStep"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <absenden
                        :is-invalid="invalid || sendingUboIsBlocked"
                        :top="true"
                    >
                        <p>Bitte prüfen und vervollständigen Sie alle Daten zu Ihrem Unternehmen, indem Sie die
                            einzelnen Bereiche öffnen, Ihre Daten anpassen und die Änderungen speichern.</p>
                        <p><strong>Wichtig:</strong> Die Daten zu Ihrem Unternehmen - inklusive der von Ihnen
                            vorgenommenen Änderungen - werden erst an uns übermittelt, wenn Sie zum Abschluss den
                            Button <strong>„Daten senden”</strong> klicken, der sich oberhalb und unterhalb der
                            Befragung befindet.</p>
                    </absenden>
                </div>
            </div>
        </ValidationObserver>
        <aenderungen-verwerfen
            :confirm-callback="onCloseEditModeConfirm"
            :cancel-callback="onCloseEditModeReject"
            :visible="showCloseEditModeModal"
        />
        <warnung-ungesicherte-aenderungen
            :show-modal="warningUnsavedDataVisible"
            v-on:ignore-unsaved-changes="warningUnsavedDataListener"
        />
        <fuss/>
    </div>
</template>

<script>
import Kopf from '../components/eingabeformular/kopf'
import Fuss from '../components/eingabeformular/fuss'
import Absenden from '../components/eingabeformular/absenden'
import Menue from '../components/eingabeformular/menue'
import Firmenidentifikation from '../components/eingabeformular/firmenidentifikation'
import FirmenidentifikationHead from '../components/eingabeformular/mobile/firmenidentifikation-head'
import Inhaber from '../components/eingabeformular/inhaber'
import InhaberHead from '../components/eingabeformular/mobile/inhaber-head'
import RechtsformGeschaeftstaetigkeit from '../components/eingabeformular/rechtsform-geschaeftstaetigkeit'
import RechtsformGeschaeftstaetigkeitHead from '../components/eingabeformular/mobile/rechtsform-geschaeftstaetigkeit-head'
import Umsatzangaben from '../components/eingabeformular/umsatzangaben'
import UmsatzangabenHead from '../components/eingabeformular/mobile/umsatzangaben-head'
import Unterlagen from '../components/eingabeformular/unterlagen'
import UnterlagenHead from '../components/eingabeformular/mobile/unterlagen-head'
import AnsprechpartnerAnmerkungen from '../components/eingabeformular/ansprechpartner-anmerkungen'
import AnsprechpartnerAnmerkungenHead from '../components/eingabeformular/mobile/ansprechpartner-anmerkungen-head'
import SonstigeUnternehmensdaten from '../components/eingabeformular/sonstige-unternehmensdaten'
import SonstigeUnternehmensdatenHead from '../components/eingabeformular/mobile/sonstige-unternehmensdaten-head'
import AenderungenVerwerfen from '../components/eingabeformular/aenderungenverwerfen'
import WarnungUngesicherteAenderungen from '../components/eingabeformular/warnung-ungesicherte-aenderungen'
import { ValidationObserver } from 'vee-validate'
import { mapState } from 'vuex'
import { fuellgradBuilder } from '@/helper/mapper'
import Vue from 'vue'
export default {
    name: 'UboEingabeformularView',
    components: {
        Kopf,
        Fuss,
        Absenden,
        Menue,
        Firmenidentifikation,
        FirmenidentifikationHead,
        Inhaber,
        InhaberHead,
        RechtsformGeschaeftstaetigkeit,
        RechtsformGeschaeftstaetigkeitHead,
        Umsatzangaben,
        UmsatzangabenHead,
        Unterlagen,
        UnterlagenHead,
        SonstigeUnternehmensdaten,
        SonstigeUnternehmensdatenHead,
        AnsprechpartnerAnmerkungen,
        AnsprechpartnerAnmerkungenHead,
        AenderungenVerwerfen,
        WarnungUngesicherteAenderungen,
        ValidationObserver
    },
    data() {
        return {
            menuConfig: {
                activeStep: 1,
                nextStep: null,
                edit: false
            },
            stepsWithErrors: {
                1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false
            },
            localBus: new Vue(),
            showCloseEditModeModal: false,
            warningUnsavedDataVisible: false,
            warningResolve: null, // Callback Promise für Warnung beachten
            warningReject: null // Callback Promise für Warnung ignorieren
        }
    },
    computed: {
        ...mapState('userModule', ['user', 'dokumente', 'dokumenteAnmerkungen', 'unternehmensdaten', 'crefonummer']),
        isStepActive() {
            return step =>
                this.menuConfig.activeStep === step ||
                this.$store.state.userModule.unternehmensdatenPreValidated === false ||
                this.stepsWithErrors[step]
        },
        fuellgrad: {
            get() {
                return fuellgradBuilder(this.unternehmensdaten, this.dokumente, this.dokumenteAnmerkungen)
            }
        },
        inhabergefuehrt() {
            if (this.unternehmensdaten && this.unternehmensdaten.rechtsform) {
                return this.unternehmensdaten.rechtsform.inhabergefuehrt === true
            }
            return false
        },
        sendingUboIsBlocked: function () {
            return this.menuConfig && this.menuConfig.edit
        }
    },
    methods: {
        onSelectStep(nextStep) {
            if (!this.showCloseEditModeModal) {
                if (this.menuConfig.edit) {
                    this.menuConfig.nextStep = nextStep
                    this.showCloseEditModeModal = true
                } else {
                    this.menuConfig.edit = false
                    this.menuConfig.activeStep = nextStep
                    this.menuConfig.nextStep = null
                    this.scrollToStart()
                    this.$store.commit('userModule/SET_REQUIRE_PRE_VALIDATING', false)
                }
            }
        },
        onEditActiveStep() {
            this.menuConfig.edit = true
            this.scrollToStart()
            this.$store.commit('userModule/SET_REQUIRE_PRE_VALIDATING', false)
        },
        onSaveActiveStep() {
            this.menuConfig.edit = false
            this.scrollToStart()
        },
        onCloseEditModeConfirm() {
            this.$refs[`step_${this.menuConfig.activeStep}`].restoreFormData()
            this.menuConfig.edit = false
            this.showCloseEditModeModal = false
            this.menuConfig.activeStep = this.menuConfig.nextStep
            this.menuConfig.nextStep = null
            this.scrollToStart()
        },
        onCloseEditModeReject() {
            this.menuConfig.nextStep = null
            this.showCloseEditModeModal = false
        },
        firstStep() {
            this.menuConfig.activeStep = 1
            this.scrollToStart()
        },
        scrollToStart() {
            const headers = document.getElementsByClassName("header-nav");
            let headerHeight = 0
            if (headers && headers.length) {
                headerHeight = headers[0].clientHeight
            }
            const ele = this.$refs["ubo-menue"]
            if (ele) {
                let top = ele.offsetTop - headerHeight
                window.scrollTo({top, left: 0, behavior: 'smooth'})
            }
        },
        polarToCartesian(angleInDegrees, centerX, centerY, radius) {
            const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0
            return {
                x: centerX + (radius * Math.cos(angleInRadians)),
                y: centerY + (radius * Math.sin(angleInRadians))
            }
        },
        getProgressCoordinates(progress = 0, boxSize = 73, radius = 68) {
            const angleInDegrees = progress >= 100 ? 359.9 : progress * 3.6
            const start = this.polarToCartesian(0, boxSize, boxSize, radius)
            const end = this.polarToCartesian(angleInDegrees, boxSize, boxSize, radius)
            const longway = progress > 50 ? 1 : 0
            return `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${longway} 1 ${end.x} ${end.y}`
        },
        warningUnsavedDataListener(ignore) {
            this.warningUnsavedDataVisible = false
            if (ignore && typeof this.warningReject === 'function') {
                this.warningReject()
            } else if (typeof this.warningResolve === 'function') {
                this.warningResolve()
            }
        },
        beforeRoutingEventListener({ detail: { oldUrl, newUrl, cancelNavigation } }) {
            if (oldUrl && oldUrl.indexOf('/portal/ubo/eingabeformular') !== -1) {
                if (this.$store.getters['userModule/isUnternehmensdatenUnsaved']) {
                    cancelNavigation()
                    this.warningUnsavedDataVisible = true
                    new Promise((resolve, reject) => {
                        this.warningResolve = resolve
                        this.warningReject = reject
                    }).then(() => {
                        // Stay here
                    }).catch(() => {
                        window.location.href = newUrl
                    })
                }
            }
        },
        unloadEventListener(event) {
            if (event) {
                if (this.$router && this.$router.currentRoute && this.$router.currentRoute.name === 'eingabeformular') {
                    if (this.$store.getters['userModule/isUnternehmensdatenUnsaved']) {
                        event.preventDefault()
                        event.returnValue = 'Sie haben ungesicherte Änderungen. Wenn Sie das Formular nun verlassen, gehen diese Änderungen verloren. Ihre Daten werden erst durch Klick auf "Daten senden" übermittelt.'
                        return false
                    }
                }
            }
            return true
        }
    },
    provide: function () {
        return {
            editEventBus: this.localBus,
            formatter: {
                formatPercent(value) {
                    let result = null
                    if (value !== null) {
                        result = `${value} %`
                    }
                    return result
                },
                formatRechtsform(value) {
                    let result = null
                    if (value !== null) {
                        if (value === true) {
                            result = "gewerblich registriert"
                        } else {
                            result = "freiberuflich tätig"
                        }
                    }
                    return result
                },
                formatBoolean(value) {
                    let result = null
                    if (value !== null) {
                        if (Boolean(value) === true) {
                            result = 'ja'
                        }
                        if (Boolean(value) === false) {
                            result = 'nein'
                        }
                    }
                    return result
                },
                formatFlaeche(value) {
                    let result = null
                    if (value !== null) {
                        result = `${value} m²`
                    }
                    return result
                },
                formatBetrag(value) {
                    let result = null
                    if (value !== null) {
                        result = `${value} EUR`
                    }
                    return result
                }
            }
        }
    },
    created: function () {
        addEventListener('beforeunload', this.unloadEventListener, { capture: true })
        addEventListener('single-spa:before-routing-event', this.beforeRoutingEventListener)
    },
    beforeDestroy() {
        removeEventListener('beforeunload', this.unloadEventListener, { capture: true })
        removeEventListener('single-spa:before-routing-event', this.beforeRoutingEventListener)
    }
}
</script>

<!-- Formularseite -->
<style lang="less">
    .scs-ubo-portal-container {
        .crefo-ui-tooltip {
            position: absolute;
            right: 0;
            padding-top: 15px;

            .inline-wrapper {
                .crefo-tooltip {
                    top: 15px;
                    right: 0;
                    line-height: 1.2;
                    width: 180px;
                    text-align: left;
                }
            }
        }

        .ubo-intro-header {
            & > div {
                margin-top: 40px;
                min-height: 440px;
                background-image: url('../assets/img/unternehmensbefragung.png');
                background-repeat: no-repeat;
                background-position: top right;
                background-size: contain;

                @media only screen and (max-width: 1199px) {
                    min-height: 280px;
                }

                @media only screen and (max-width: 767px) {
                    min-height: 180px;
                }
            }
        }

        .ubo-intro-text {
            .btn-purple {
                float: right;
                background-color: #a763a4;
                border-radius: 9999px !important;

                &:hover {
                    color: #ffffff;
                    background-color: #894d87;
                }
            }

            & > div:nth-child(2) {
                -ms-flex: 0 0 50%;
                max-width: 50%;

                .col:first-child {
                    width: 176px;
                    max-width: 176px;
                    flex-grow: unset;
                }

                .ubo-intro-text-progress-counter {
                    position: relative;
                    width: 146px;
                    height: 146px;
                    font-size: 160%;
                    text-align: center;

                    strong {
                        display: inline-block;
                        padding-top: 60%;
                    }

                    i {
                        position: absolute;
                        top: 18%;
                        left: 50%;
                        width: 50px;
                        height: 50px;
                        margin-left: -25px;
                        font-size: 47px;
                    }

                    svg {
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }

                .ubo-intro-text-progress-box {
                    position: relative;
                    padding-left: 40px !important;

                    i {
                        position: absolute;
                        left: 10px;
                        top: 12px;
                        font-size: 21px;

                        &:before {
                            text-decoration: underline;
                        }
                    }
                }

                @media only screen and (max-width: 1199px) {
                    margin-top: 30px;
                }

                @media only screen and (max-width: 991px) {
                    -ms-flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        .ubo-ssl {
            position: relative;
            padding-top: 7px;

            p {
                margin-left: 23px;
                margin-bottom: 0;
                line-height: 20px;

                :first-child {
                    text-transform: uppercase;
                }
            }

            i {
                position: absolute;
                left: 0;
                top: 13px;
                width: 29px;
                height: 29px;
                font-size: 16px;
                font-weight: lighter;
                background-color: #b5b5b5;
                border-radius: 50%;
                opacity: 0.91;

                &:before {
                    position: relative;
                    left: 7px;
                    top: 6px;
                }
            }

            @media only screen and (max-width: 549px) {
                display: none;
            }

            & + .col-auto {
                button {
                    float: right;
                }

                @media only screen and (max-width: 549px) {
                    width: 100%;
                }
            }
        }

        .ubo-form-wrapper {
            margin-left: 0;
            margin-right: 0;

            .ubo-steps {
                border-right: 1px solid #edece5;
                padding-left: 0;
                padding-right: 0;

                ul {
                    margin: 0;
                    padding: 0;
                    list-style-position: inside;
                    list-style-type: none;
                }
            }

            .ubo-step {
                position: relative;
                cursor: pointer;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                height: 80px;
                border-bottom: 1px solid #edece5;

                .ubo-step-number {
                    position: absolute;
                    left: 20px;
                    top: 50%;
                    margin-top: -13px;
                    width: 26px;
                    height: 26px;
                    border-radius: 50%;
                    color: #ffffff;
                    background-color: #009ee2;
                    font-weight: bold;

                    span {
                        display: inline-block;
                        width: 100%;
                        height: 26px;
                        line-height: 25px;
                        text-align: center;
                    }
                }

                .ubo-step-name {
                    display: flex;
                    padding-left: 30px;
                    padding-right: 65px;
                    height: 100%;
                    align-items: center;
                    color: #009ee2;
                    font-weight: 700;
                    line-height: 1.4;
                }

                .ubo-step-progress {
                    position: absolute;
                    right: 27px;
                    top: 50%;
                    margin-top: -13px;
                    width: 60px;
                    height: 26px;

                    span {
                        display: inline-block;
                        float: right;
                        padding: 3px 12px;
                        color: #7f7f7f;
                        background-color: #edece5;
                        border-radius: 9999px;

                        &:after {
                            content: " %";
                        }
                    }

                    &--complete {
                        span {
                            color: #ffffff;
                            background-color: #8acac1;
                        }
                    }
                }

                &:hover {
                    background-color: rgba(0, 158, 226, 0.15);
                }

                &--active, &--active:hover {
                    background-color: #009ee2;

                    .ubo-step-number {
                        color: #009ee2;
                        background-color: #ffffff;
                    }

                    .ubo-step-name {
                        color: #ffffff;
                    }

                    .ubo-step-progress {
                        span {
                            color: #ffffff;
                            background-color: rgba(237, 236, 229, 0.3);
                        }

                        &--complete {
                            span {
                                background-color: rgba(138, 202, 193, 0.3);
                            }
                        }
                    }
                }
            }

            .ubo-form-step {
                .ubo-step {
                    .ubo-step-number {
                        left: 20px;

                        @media only screen and (max-width: 767px) {
                            left: 15px;
                        }
                    }

                    .ubo-step-name {
                        padding-left: 40px;
                        padding-right: 100px;
                    }

                    .ubo-step-progress {
                        right: 50px;
                    }

                    i {
                        position: absolute;
                        margin-top: -12px;
                        top: 50%;
                        right: 17px;

                        &:before {
                            color: #009ee2;
                            content: "\e909";
                        }
                    }

                    &--active, &--active:hover {
                        i {
                            &:before {
                                color: #ffffff;
                                content: "\e90c";
                            }
                        }
                    }
                }

                &.has-errors {
                    .ubo-step {
                        .ubo-step-number {
                            background-color: #e05469 !important;
                        }
                        .ubo-step-name {
                            color: #e05469 !important;
                        }
                    }
                }

                &.ubo-form-step--active.has-errors {
                    .ubo-step {
                        background-color: #e05469 !important;
                        .ubo-step-number {
                            background-color: #ffffff !important;
                        }
                        .ubo-step-name {
                            color: #ffffff !important;
                        }
                    }
                }
            }
        }

        .ubo-form-chapter {
            .ubo-form-display {
                .has-errors, &.has-errors {
                    color: #e05469;
                }
            }
        }
    }
</style>
