var countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/de.json'))
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'))

const GeneralUtilityHelper = (() => {
    const defaultAllowedCountries = ['DE']
    const defaultAllowedCountriesForVerification = ['DE']

    function allowedCountries() {
        let allowed = []

        if (process && process.env && process.env.VARS) {
            const configuredCountries = process.env.VARS.ALLOWED_COUNTRIES
            if (
                typeof configuredCountries === 'string'
                && configuredCountries.length
                && configuredCountries.indexOf('ALLOWED_COUNTRIES') === -1
            ) {
                configuredCountries.trim().split(',').forEach(country => {
                    allowed.push(country.trim().toUpperCase())
                })
            }
        }

        return allowed.length ? allowed : defaultAllowedCountries
    }

    function allowedCountriesForVerification() {
        let allowed = []

        if (process && process.env && process.env.VARS) {
            const configuredCountries = process.env.VARS.ALLOWED_COUNTRIES_FOR_VERIFICATION
            if (
                typeof configuredCountries === 'string'
                && configuredCountries.length
                && configuredCountries.indexOf('ALLOWED_COUNTRIES_FOR_VERIFICATION') === -1
            ) {
                configuredCountries.trim().split(',').forEach(country => {
                    allowed.push(country.trim().toUpperCase())
                })
            }
        }

        return allowed.length ? allowed : defaultAllowedCountriesForVerification
    }

    function allowedCountriesByName(lang) {
        const allowed = []

        allowedCountries().forEach(country => {
            const name = countries.getName(country, lang.toLowerCase())
            if (name) {
                allowed.push(name)
            } else {
                allowed.push(country)
            }
        })

        return allowed
    }

    return {
        getAllowedCountries: () => {
            return allowedCountries()
        },
        getAllowedCountriesByName: (lang = 'en') => {
            return allowedCountriesByName(lang)
        },
        isCountryAllowed: (country) => {
            return allowedCountries().includes(country.toUpperCase())
                || allowedCountries().includes('ALL')
        },
        getAllowedCountriesForVerification: () => {
            return allowedCountriesForVerification()
        },
        isCountryAllowedForVerification: (country) => {
            return allowedCountriesForVerification().includes(country.toUpperCase())
                || allowedCountriesForVerification().includes('ALL')
        }
    }
})()

export default GeneralUtilityHelper
