<template>
    <div class="crefo-input">
        <ValidationProvider :name=validationProviderName :rules=rules :vid=vid
                            v-slot="{ invalid, errors, dirty, changed, pristine }">
            <div v-if="isCheckbox" class="input-group" :class="{ 'has-error': ((!checkDirty||dirty)&&invalid), 'is-invalid': invalid && pristine }">
                <label v-if="label" :class="{'has-changed':changed&&!((!checkDirty||dirty)&&invalid)}">
                    {{ $t(label) }}
                </label>
                <slot></slot>
            </div>
            <div v-else-if="isTextarea" class="input-group" :class="{ 'has-error': ((!checkDirty||dirty)&&invalid), 'is-valid': isValid, 'is-invalid': invalid && pristine }">
                <label :class="{'has-changed':changed&&!((!checkDirty||dirty)&&invalid)}">{{ $t(label) }}</label>
                <div class="input-wrapper">
                    <slot></slot>
                </div>
                <span class="error-msg" :data-qa="dataQa">{{ errors[0] }}</span>
            </div>
            <div v-else-if="isDatepicker" class="input-group" :class="{ 'has-error':((!checkDirty||dirty)&&invalid), 'is-invalid': invalid && pristine }">
                <label :class="{'has-changed':changed&&!((!checkDirty||dirty)&&invalid)}">{{ $t(label) }}</label>
                <div>
                    <slot></slot>
                </div>
                <span class="error-msg" :data-qa="dataQa">{{ errors[0] }}</span>
            </div>
            <div v-else class="input-group" :class="{'has-error':((!checkDirty||dirty)&&invalid), 'is-valid': isValid, 'is-invalid': invalid && pristine }">
                <label :class="{'has-changed':changed&&!((!checkDirty||dirty)&&invalid)}">
                    {{ $t(label) }}
                </label>
                <div class="input-wrapper" :class="{'is-empty':isEmpty}">
                    <slot></slot>
                </div>
                <span class="error-msg" :data-qa="dataQa">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>
    </div>
</template>
<script>

/**
 *
 * Shows changes and errors of ubo input fields.
 * Supported fields:
 *
 *  company identification
 *  ----------------------
 *  firmierung
 *  strasse
 *  houseNumber
 *  hausnummerZusatz
 *  plz
 *  ort
 *  telefonnummer-durchwahl
 *  telefonnummer-nummer
 *  telefonnummer-vorwahl
 *  mobilnummer-vorwahl
 *  mobilnummer-nummer
 *  fax-vorwahl
 *  fax-nummer
 *  fax-durchwahl
 *  emailAdresse
 *  internetAdresse
 *  steuernummer
 *  umsatzsteuerIdentnummer
 *
 *  inhaber
 *  -----
 *  inhaber-vorname
 *  inhaber-nachname
 *  inhaber-geburtsname
 *  inhaber-geburtsdatum
 *  inhaber-land
 *  inhaber-plz
 *  inhaber-ort
 *  inhaber-ortsteil
 *  inhaber-strasse
 *  inhaber-hausnummer
 *  inhaber-hausnummerZusatz
 *
 *  geschaeftstaetigkeit
 *  ----------------
 *
 *  geschaeftstaetigkeit-eingetragenerGegenstand
 *  geschaeftstaetigkeit-anzahlKunden
 *  geschaeftstaetigkeit-prozentanteilPrivatkunden
 *  geschaeftstaetigkeit-prozentanteilOeffentlicheHand
 *  geschaeftstaetigkeit-anzahlLieferanten
 *
 *  umsatz
 *  --------
 *
 *  umsatz-jahr
 *  umsatz-umsatzerwartung
 *  umsatz-betrag
 *  umsatz-betragVorjahr
 *
 *  mitarbeiter
 *  -----------
 *
 *  mitarbeiter-jahr
 *  mitarbeiter-anzahlGesamt
 *  mitarbeiter-anzahlGesamtVorjahr
 *  mitarbeiter-anzahlInhaber
 *  mitarbeiter-anzahlAngestellte
 *  mitarbeiter-anzahlGewerblicheMitarbeiter
 *  mitarbeiter-anzahlAuszubildende
 *  mitarbeiter-anzahlTeilzeitkräfte
 *  mitarbeiter-anzahlAushilfen
 *  mitarbeiter-anzahlFreieMitarbeiter
 *
 *  ansprechpartner
 *  ---------------
 *
 *  ansprechpartner-name
 *  ansprechpartner-funktion
 *  ansprechpartner-email
 *  ansprechpartner-telefon
 *  ansprechpartner-fax
 *  ansprechpartner-mobil
 *
 *  anmerkungen
 *
 *  immobilien
 *  ----------
 *
 *  immobilien-betriebsraeumeGemietet
 *  immobilien-betriebsraeumeFlaeche
 *  immobilien-betriebsraeumeMiete
 *
 *  importExport
 *  ------------
 *
 *  importExport-importAnteil
 *  importExport-importLand
 *  importExport-exportAnteil
 *  importExport-exportLand
 *
 *  banken
 *  ------
 *
 *  banken-iban
 *
 *  term-of-service
 *
 */


import '@/helper/standard-rules'
import {ValidationProvider, extend} from 'vee-validate'

export default {
    components: {ValidationProvider},
    name: 'UboInput',
    props: {
        field: null,
        index: null,
        isValid: false,
        isEmpty: false,
        checkDirty: false,
        dataQa: {
            type: String,
            required: false,
            default: 'error-message'
        },
        hasDokumente: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        label() {
            if (this.field === 'firmierung') {
                return 'UboEingabeformularView.Label.Firmierung'
            } else if (this.field === 'strasse') {
                return 'UboEingabeformularView.Label.Strasse'
            } else if (this.field === 'hausnummer') {
                return 'UboEingabeformularView.Label.Hausnummer'
            } else if (this.field === 'hausnummerZusatz') {
                return 'UboEingabeformularView.Label.HausnummerZusatz'
            } else if (this.field === 'plz') {
                return 'UboEingabeformularView.Label.Plz'
            } else if (this.field === 'ort') {
                return 'UboEingabeformularView.Label.Ort'
            } else if (this.field === 'land') {
                return 'UboEingabeformularView.Label.Land'
            } else if (this.field === 'telefonnummer-vorwahl') {
                return 'UboEingabeformularView.Label.TelefonnummerVorwahl'
            } else if (this.field === 'telefonnummer-durchwahl') {
                return 'UboEingabeformularView.Label.TelefonnummerDurchwahl'
            } else if (this.field === 'telefonnummer-nummer') {
                return 'UboEingabeformularView.Label.TelefonnummerNummer'
            } else if (this.field === 'mobilnummer-vorwahl') {
                return 'UboEingabeformularView.Label.MobilVorwahl'
            } else if (this.field === 'mobilnummer-nummer') {
                return 'UboEingabeformularView.Label.MobilNummer'
            } else if (this.field === 'fax-vorwahl') {
                return 'UboEingabeformularView.Label.FaxVorwahl'
            } else if (this.field === 'fax-nummer') {
                return 'UboEingabeformularView.Label.FaxNummer'
            } else if (this.field === 'fax-durchwahl') {
                return 'UboEingabeformularView.Label.FaxDurchwahl'
            } else if (this.field === 'emailAdresse') {
                return 'UboEingabeformularView.Label.EmailAdresse'
            } else if (this.field === 'internetAdresse') {
                return 'UboEingabeformularView.Label.InternetAdresse'
            } else if (this.field === 'steuernummer') {
                return 'UboEingabeformularView.Label.Steuernummer'
            } else if (this.field === 'umsatzsteuerIdentnummer') {
                return 'UboEingabeformularView.Label.UmsatzsteuerIdentnummer'
            } else if (this.field === 'inhaber-vorname') {
                return 'UboEingabeformularView.Label.InhaberVorname'
            } else if (this.field === 'inhaber-nachname') {
                return 'UboEingabeformularView.Label.InhaberNachname'
            } else if (this.field === 'inhaber-geburtsname') {
                return 'UboEingabeformularView.Label.InhaberGeburtsname'
            } else if (this.field === 'inhaber-geburtsdatum') {
                return 'UboEingabeformularView.Label.InhaberGeburtsdatum'
            } else if (this.field === 'inhaber-land') {
                return 'UboEingabeformularView.Label.InhaberLand'
            } else if (this.field === 'inhaber-plz') {
                return 'UboEingabeformularView.Label.InhaberPlz'
            } else if (this.field === 'inhaber-ort') {
                return 'UboEingabeformularView.Label.InhaberOrt'
            } else if (this.field === 'inhaber-ortsteil') {
                return 'UboEingabeformularView.Label.InhaberOrtsteil'
            } else if (this.field === 'inhaber-strasse') {
                return 'UboEingabeformularView.Label.InhaberStrasse'
            } else if (this.field === 'inhaber-hausnummer') {
                return 'UboEingabeformularView.Label.InhaberHausnummer'
            } else if (this.field === 'inhaber-hausnummerZusatz') {
                return 'UboEingabeformularView.Label.InhaberHausnummerZusatz'
            } else if (this.field === 'geschaeftstaetigkeit-eingetragenerGegenstand') {
                return 'UboEingabeformularView.Label.GeschaeftstaetigkeitVertriebsweg'
            } else if (this.field === 'geschaeftstaetigkeit-anzahlKunden') {
                return 'UboEingabeformularView.Label.GeschaeftstaetigkeitAnzahlKunden'
            } else if (this.field === 'geschaeftstaetigkeit-prozentanteilPrivatkunden') {
                return 'UboEingabeformularView.Label.GeschaeftstaetigkeitProzentanteilPrivatkunden'
            } else if (this.field === 'geschaeftstaetigkeit-prozentanteilOeffentlicheHand') {
                return 'UboEingabeformularView.Label.GeschaeftstaetigkeitProzentanteilOeffentlicheHand'
            } else if (this.field === 'geschaeftstaetigkeit-anzahlLieferanten') {
                return 'UboEingabeformularView.Label.GeschaeftstaetigkeitAnzahlLieferanten'
            } else if (this.field === 'mitarbeiter-jahr') {
                return 'UboEingabeformularView.Label.MitarbeiterJahr'
            } else if (this.field === 'mitarbeiter-anzahlGesamt') {
                return 'UboEingabeformularView.Label.MitarbeiterAnzahlGesamt'
            } else if (this.field === 'mitarbeiter-anzahlGesamtVorjahr') {
                return 'UboEingabeformularView.Label.MitarbeiterAnzahlGesamtVorjahr'
            } else if (this.field === 'mitarbeiter-anzahlInhaber') {
                return 'UboEingabeformularView.Label.MitarbeiterAnzahlInhaber'
            } else if (this.field === 'mitarbeiter-anzahlAngestellte') {
                return 'UboEingabeformularView.Label.MitarbeiterAnzahlAngestellte'
            } else if (this.field === 'mitarbeiter-anzahlGewerblicheMitarbeiter') {
                return 'UboEingabeformularView.Label.MitarbeiterAnzahlGewerblicheMitarbeiter'
            } else if (this.field === 'mitarbeiter-anzahlAuszubildende') {
                return 'UboEingabeformularView.Label.MitarbeiterAnzahlAuszubildende'
            } else if (this.field === 'mitarbeiter-anzahlTeilzeitkräfte') {
                return 'UboEingabeformularView.Label.MitarbeiterAnzahlTeilzeitkräfte'
            } else if (this.field === 'mitarbeiter-anzahlAushilfen') {
                return 'UboEingabeformularView.Label.MitarbeiterAnzahlAushilfen'
            } else if (this.field === 'mitarbeiter-anzahlFreieMitarbeiter') {
                return 'UboEingabeformularView.Label.MitarbeiterAnzahlFreieMitarbeiter'
            } else if (this.field === 'ansprechpartner-anrede') {
                return 'UboEingabeformularView.Label.'
            } else if (this.field === 'ansprechpartner-name') {
                return 'UboEingabeformularView.Label.AnsprechpartnerName'
            } else if (this.field === 'ansprechpartner-funktion') {
                return 'UboEingabeformularView.Label.AnsprechpartnerFunktion'
            } else if (this.field === 'ansprechpartner-email') {
                return 'UboEingabeformularView.Label.AnsprechpartnerEmail'
            } else if (this.field === 'ansprechpartner-telefon') {
                return 'UboEingabeformularView.Label.AnsprechpartnerTelefon'
            } else if (this.field === 'ansprechpartner-fax') {
                return 'UboEingabeformularView.Label.AnsprechpartnerFax'
            } else if (this.field === 'ansprechpartner-mobil') {
                return 'UboEingabeformularView.Label.AnsprechpartnerMobil'
            } else if (this.field === 'anmerkungen') {
                return 'UboEingabeformularView.Label.Anmerkungen'
            } else if (this.field === 'immobilien-betriebsraeumeGemietet') {
                return 'UboEingabeformularView.Label.ImmobilienBetriebsraeumeGemietet'
            } else if (this.field === 'immobilien-betriebsraeumeFlaeche') {
                return 'UboEingabeformularView.Label.ImmobilienBetriebsraeumeFlaeche'
            } else if (this.field === 'immobilien-betriebsraeumeMiete') {
                return 'UboEingabeformularView.Label.ImmobilienBetriebsraeumeMiete'
            } else if (this.field === 'importExport-importAnteil') {
                return 'UboEingabeformularView.Label.ImportExportImportAnteil'
            } else if (this.field === 'importExport-exportAnteil') {
                return 'UboEingabeformularView.Label.ImportExportExportAnteil'
            } else if (this.field === '') {
                return 'UboEingabeformularView.Label.'
            } else if (this.field === '') {
                return 'UboEingabeformularView.Label.'
            } else if (this.field === 'umsatz-jahr') {
                return 'UboEingabeformularView.Label.UmsatzJahr'
            } else if (this.field === 'umsatz-betrag') {
                return 'UboEingabeformularView.Label.Betrag'
            } else if (this.field === 'umsatz-betragVorjahr') {
                return 'UboEingabeformularView.Label.BetragVorjahr'
            } else if (this.field === 'umsatz-umsatzerwartung') {
                return 'UboEingabeformularView.Label.UmsatzUmsatzerwartung'
            } else if (this.field === 'dokumente-anmerkungen') {
                return 'UboEingabeformularView.Label.DokumenteAnmerkungen'
            } else if (this.field === 'feedback') {
                return 'ConfirmationView.Label.Feedback'
            } else {
                return null
            }
        },
        rules() {
            if (this.field === 'firmierung') {
                return "max:300"
            } else if (this.field === 'strasse') {
                return "max:46"
            } else if (this.field === 'hausnummer') {
                return "max:8"
            } else if (this.field === 'hausnummerZusatz') {
                return "max:10"
            } else if (this.field === 'plz') {
                return {zip_code: '@land', max: 10}
            } else if (this.field === 'ort') {
                return "max:40"
            } else if (this.field === 'telefonnummer-vorwahl') {
                return {
                    two_required_fields: {
                        field_1: '@telefonnummer-nummer',
                        field_2: '@telefonnummer-durchwahl'
                    }, max: 18, numeric: ''
                }
            } else if (this.field === 'telefonnummer-nummer') {
                return {
                    two_required_fields: {
                        field_1: '@telefonnummer-vorwahl',
                        field_2: '@telefonnummer-durchwahl'
                    }, max: 18, numeric: ''
                }
            } else if (this.field === 'telefonnummer-durchwahl') {
                return "max:10|numeric"
            } else if (this.field === 'mobilnummer-vorwahl') {
                return {
                    required_if: 'mobilnummer-nummer', max: 18, numeric: ''
                }
            } else if (this.field === 'mobilnummer-nummer') {
                return {
                    required_if: 'mobilnummer-vorwahl', max: 18, numeric: ''
                }
            } else if (this.field === 'fax-vorwahl') {
                return {
                    two_required_fields: {
                        field_1: '@fax-nummer',
                        field_2: '@fax-durchwahl'
                    }, max: 18, numeric: ''
                }
            } else if (this.field === 'fax-nummer') {
                return {
                    two_required_fields: {
                        field_1: '@fax-vorwahl',
                        field_2: '@fax-durchwahl'
                    }, max: 18, numeric: ''
                }
            } else if (this.field === 'fax-durchwahl') {
                return "max:10|numeric"
            } else if (this.field === 'emailAdresse') {
                return {regex: /.+@.+\..{2,}/, max: 255,}
            } else if (this.field === 'internetAdresse') {
                return {regex: /.+\..{2,}/, max: 255}
            } else if (this.field === 'steuernummer') {
                return "max:40"
            } else if (this.field === 'umsatzsteuerIdentnummer') {
                return "max:40"
            } else if (this.field === 'inhaber-vorname') {
                return "max:30"
            } else if (this.field === 'inhaber-nachname') {
                return "max:30"
            } else if (this.field === 'inhaber-geburtsname') {
                return "max:30"
            } else if (this.field === 'inhaber-plz') {
                return {zip_code: '@inhaber-land', max: 10}
            } else if (this.field === 'inhaber-ort') {
                return "max:40"
            } else if (this.field === 'inhaber-ortsteil') {
                return "max:40"
            } else if (this.field === 'inhaber-strasse') {
                return "max:46"
            } else if (this.field === 'inhaber-hausnummer') {
                return "max:8"
            } else if (this.field === 'inhaber-hausnummerZusatz') {
                return "max:10"
            } else if (this.field === 'geschaeftstaetigkeit-eingetragenerGegenstand') {
                return "max:4000"
            } else if (this.field === 'geschaeftstaetigkeit-anzahlKunden') {
                return "max_value:99999999"
            } else if (this.field === 'geschaeftstaetigkeit-prozentanteilPrivatkunden') {
                return "max_percent"
            } else if (this.field === 'geschaeftstaetigkeit-prozentanteilOeffentlicheHand') {
                return "max_percent"
            } else if (this.field === 'geschaeftstaetigkeit-anzahlLieferanten') {
                return "max_value:99999999"
            } else if (this.field === 'mitarbeiter-jahr') {
                return "max_year|min_year"
            } else if (this.field === 'mitarbeiter-anzahlGesamt') {
                return "max_value:999999"
            } else if (this.field === 'mitarbeiter-anzahlGesamtVorjahr') {
                return "max_value:999999"
            } else if (this.field === 'mitarbeiter-anzahlInhaber') {
                return "max_value:99999999999"
            } else if (this.field === 'mitarbeiter-anzahlAngestellte') {
                return "max_value:99999999999"
            } else if (this.field === 'mitarbeiter-anzahlGewerblicheMitarbeiter') {
                return "max_value:99999999999"
            } else if (this.field === 'mitarbeiter-anzahlAuszubildende') {
                return "max_value:99999999999"
            } else if (this.field === 'mitarbeiter-anzahlTeilzeitkräfte') {
                return "max_value:99999999999"
            } else if (this.field === 'mitarbeiter-anzahlAushilfen') {
                return "max_value:99999999999"
            } else if (this.field === 'mitarbeiter-anzahlFreieMitarbeiter') {
                return "max_value:99999999999"
            } else if (this.field === 'ansprechpartner-anrede') {
                return "oneOf:MRS_MR,MRS,MR"
            } else if (this.field === 'ansprechpartner-name') {
                return "max:300"
            } else if (this.field === 'ansprechpartner-funktion') {
                return "max:100"
            } else if (this.field === 'ansprechpartner-email') {
                return {regex: /@/, max: 100}
            } else if (this.field === 'ansprechpartner-telefon') {
                return "max:100|numeric"
            } else if (this.field === 'ansprechpartner-fax') {
                return "max:100|numeric"
            } else if (this.field === 'ansprechpartner-mobil') {
                return "max:100|numeric"
            } else if (this.field === 'anmerkungen') {
                return "max:4000"
            } else if (this.field === '') {
                return ""
            } else if (this.field === 'immobilien-betriebsraeumeFlaeche') {
                return "max_amount:9.999.999.999,99"
            } else if (this.field === 'immobilien-betriebsraeumeMiete') {
                return "max_amount:9.999.999.999,99"
            } else if (this.field === 'importExport-importAnteil') {
                return "max_percent"
            } else if (this.field === 'importExport-importLand') {
                return "required|max:50"
            } else if (this.field === 'importExport-exportAnteil') {
                return "max_percent"
            } else if (this.field === 'importExport-exportLand') {
                return "required|max:50"
                /*
                } else if (this.field === '') {
                    return ""
               */
            } else if (this.field === 'banken-iban') {
                return "required|iban"
            } else if (this.field === 'umsatz-jahr') {
                return "max_year|min_year"
            } else if (this.field === 'umsatz-betrag' || this.field === 'umsatz-betragVorjahr') {
                return "max_sales_amount"
            } else if (this.field === 'term-of-service') {
                return "term_of_service"
            } else if (this.field === 'dokumente-anmerkungen') {
                return "max:500|dokumente_anmerkungen_allowed:" + (this.hasDokumente ? 'true' : 'false')
            } else {
                return null
            }
        },
        validationProviderName() {
            if (this.field === 'feedback') {
                return "Die Nachricht"
                /*
                            Der Name verhindert den 'invalid' Mechanismus, wahrscheinlich nur, wenn der Name mehrfach verwendet wird
                */
            } else {
                return null
            }
        },
        vid() {
            let result
            if(this.index){
                result = "".concat(this.field, this.index)
            }else{
                result = "".concat(this.field)
            }
            return result
        },
        isCheckbox() {
            return ['umsatz-umsatzerwartung', 'term-of-service', 'consent-investigation', 'immobilien-betriebsraeumeGemietet'].includes(this.field)
        },
        isTextarea() {
            return ['geschaeftstaetigkeit-eingetragenerGegenstand', 'anmerkungen'].includes(this.field)
        },
        isDatepicker() {
            return ['inhaber-geburtsdatum'].includes(this.field)
        },

    }
}
</script>

<style scoped lang="less">

.crefo-input {
    padding-top: 12px;
    padding-bottom: 12px;

    .has-changed {
        color: #8acac1 !important;
    }

    .input-group {

        .input-wrapper {

            i {
                top: 90% !important;
                display: inline-block !important;
                position: absolute !important;
                font-size: 15px !important;
                font-family: "Open Sans", Arial;
            }
        }

        .is-empty {
            border-color: #009ee2;
        }

        .error-msg {
            min-height: 30px;
            visibility: hidden;
            display: inline-block !important;
            font-size: 12px !important;
        }

        &.has-error, &.is-invalid {

            .input-wrapper {
                color: #e05469;
                border-color: #e05469;
            }

            & > input[type="checkbox"] + label:before {
                border-color: #e05469;
            }

            label {
                color: #e05469;
            }

            .error-msg {
                visibility: visible;
            }
        }
    }

    &--disabled {
        .input-wrapper {
            border-color: #b5b5b5;
        }
    }
}

.input-amount {
    input {
        padding-right: 60px !important;
    }

    i {
        display: inline-block !important;
        position: absolute !important;
        font-size: 15px !important;
        font-family: "Open Sans", Arial;
    }
}
</style>
