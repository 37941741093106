<template>
    <div
        v-if="redirectInstant && !ajaxError"
        class="container"
    >
        <crefo-ring-loading
            class="mt"
            :wrap-in-row="true"
        />
    </div>
    <div v-else>
        <div class="container">
            <ubo-landingpage-intro-text />
        </div>
        <div class="mb">
            <ubo-suggested-kurzprofil
                :crefonummer="selectedCrefonummer"
                :show-header="false"
            >
                <div class="row mb">
                    <div class="col-12 col-md-10">
                        <crefonummer-select
                            :selected-crefonummer="selectedCrefonummer"
                            :crefonummern="selectableCrefonummern"
                            @selected-crefonummer="updateCrefonummer"
                        />
                    </div>
                </div>
            </ubo-suggested-kurzprofil>
            <div class="bg-brand bg-brand-light-grey do-request-access">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-10 mb">
                            <p>
                                <strong>Jetzt in Ihre Unternehmensbefragung einsteigen</strong><br>
                                Hier gelangen Sie direkt in die Unternehmensbefragung für das oben ausgewählte Unternehmen:
                            </p>
                            <button
                                class="btn btn-default"
                                :class="{ 'btn-disabled': unternehmensdatenFetching }"
                                :disabled="unternehmensdatenFetching"
                                data-qa="ubo-start-as-vb"
                                @click.prevent="onStartAsVertretungsberechtigt"
                            >
                                Jetzt loslegen!
                            </button>
                            <crefo-error-message
                                v-if="ajaxError"
                                :ajax-error="ajaxError"
                                class="mt"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <ubo-landingpage-vorteile-text />
        </div>
    </div>
</template>

<script>
    import UboLandingpageIntroText from '../components/landingpage/intro-text'
    import UboSuggestedKurzprofil from '../components/kurzprofil/suggested-kurzprofil'
    import UboLandingpageVorteileText from '../components/landingpage/vorteile-text'
    import CrefoErrorMessage from '@dm/crefo-error-message'
    import CrefoRingLoading from '../components/helper/crefo-ring-loading'
    import CrefonummerSelect from '../components/commons/crefonummer-select'
    import { mapState } from 'vuex'
    export default {
        name: 'UboAktivierungVertretungsberechtigtView',
        components: {
            UboLandingpageIntroText,
            UboSuggestedKurzprofil,
            UboLandingpageVorteileText,
            CrefonummerSelect,
            CrefoErrorMessage,
            CrefoRingLoading
        },
        props: {
            crefonummer: {
                type: String,
                required: false,
                default: ''
            },
            verified: {
                type: Boolean,
                required: false,
                default: null
            },
            redirectInstant: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data () {
            return {
                selectedCrefonummer: this.crefonummer || '',
                selectableCrefonummern: this.$store.state.userModule.userState ? [
                    ...this.$store.state.userModule.userState.vertretungsberechtigteCrefonummern,
                    ...this.$store.state.userModule.userState.unternehmensagentCrefonummern
                ] : []
            }
        },
        computed: {
            ...mapState('userModule', [ 'unternehmensdatenFetching', 'ajaxError' ])
        },
        methods: {
            updateCrefonummer (newCrefonummer) {
                this.selectedCrefonummer = newCrefonummer
                this.$store.commit('userModule/ON_CHANGE_CREFONUMBER', newCrefonummer)
            },
            async onStartAsVertretungsberechtigt () {
                if (this.unternehmensdatenFetching) {
                    return
                }
                let payload = {
                    crefoNumber: null
                }
                if (this.$route.name === 'verifikation-pruefseite-success' && this.redirectInstant) {
                    payload.crefoNumber = this.$store.state.userModule.crefonummer
                } else {
                    this.$store.commit('userModule/ON_CHANGE_CREFONUMBER', this.selectedCrefonummer)
                    payload.crefoNumber = this.selectedCrefonummer
                }

                await this.$store.dispatch('userModule/unternehmensdaten', payload)
                if (this.ajaxError === null) {
                    this.$router.push({ name: 'eingabeformular'})
                }
            }
        },
        mounted () {
            if (typeof this.crefonummer !== 'string' || this.crefonummer.length === 0) {
                if (typeof this.$store.state.userModule.crefonummer === 'string') {
                    this.selectedCrefonummer = this.$store.state.userModule.crefonummer
                }
            }
        },
        created () {
            if (this.redirectInstant) {
                this.onStartAsVertretungsberechtigt()
            }
        }
    }
</script>

<style scoped lang="less">
    .do-request-access {
        margin-top: -15px;
        padding: 0 30px 30px 30px;

        @media only screen and (min-width: 768px) {
            margin-top: -20px;
            padding: 0 40px 40px 40px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: -30px;
            padding: 0 60px 60px 60px;
        }
    }
</style>
