<template>
    <div>
        <modal-dialog
            title="Änderungen verwerfen"
            :confirmLabel="$t('UboEingabeformularView.ModalDialog.Reset.Label')"
            :cancelLabel="$t('UboEingabeformularView.ModalDialog.Cancel.Label')"
            :confirm-callback="confirmCallback"
            :cancel-callback="cancelCallback"
            :visible="visible"
        >
            <div class="row">
                <div class="col-12">
                    <p>Mit dem Schließen ohne Speichern gehen alle eingetragenen Daten verloren.</p>
                </div>
            </div>
        </modal-dialog>
    </div>
</template>

<script>

import ModalDialog from "@/components/helper/modal-dialog";

/**
 *
 */

export default {
    name: 'AenderungenVerwerfen',
    components: {ModalDialog},
    props: {
        confirmCallback: {
            type: Function,
            required: false
        },
        cancelCallback: {
            type: Function,
            required: false
        },
        visible: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    methods: {

    }
}
</script>
