<template>
    <div class="row ubo-form-step" :class="{ 'ubo-form-step--active': activeFormStep(7), 'has-errors': stepHasErrors }">
        <div class="col-12 d-xl-none ubo-step box-spacing" :class="activeStep(7)">
            <div class="ubo-step-number">
                <span>7</span>
            </div>
            <div class="ubo-step-name" data-qa="menue-mobil-ansprechpartner" @click.prevent="close">Ansprech­partner</div>
            <div class="ubo-step-progress">
                <span class="text-small">{{ fuellgrad.anteilAnsprechpartner }}</span>
            </div>
            <i class="crefo-ui-icon" aria-hidden="true"></i>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="h1">Ansprech­partner, An­mer­kun­gen</div>
            <p>
                Für den Fall konkreter Rückfragen unsererseits vervollständigen Sie bitte die Angaben zu Ihrem Namen und
                den Kontaktmöglichkeiten.
            </p>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="ubo-form-mode-view" v-if="!isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-bearbeiten-ansprechpartner" @click.prevent="open">
                        Bearbeiten
                        <i class="crefo-ui-icon icon-edit-pen" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Unser Ansprech­partner für Ihr Unternehmen</div>
                <p>
                    Wen dürfen wir in Zukunft für die Aktualisierung der Unternehmensdaten ansprechen?
                </p>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('ansprechpartner-anrede') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">
                        {{ $t("UboEingabeformularView.Label.AnsprechpartnerAnrede") }}
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ this.anredeName }}
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('ansprechpartner-name') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">
                        {{ $t("UboEingabeformularView.Label.AnsprechpartnerName") }}
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ this.name }}
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('ansprechpartner-funktion') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">
                        {{ $t("UboEingabeformularView.Label.AnsprechpartnerFunktion") }}
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ this.funktion }}
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('ansprechpartner-telefon') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">
                        {{ $t("UboEingabeformularView.Label.AnsprechpartnerTelefon") }}
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ this.telefon }}
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('ansprechpartner-mobil') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">
                        {{ $t("UboEingabeformularView.Label.AnsprechpartnerMobil") }}
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ this.mobil }}
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('ansprechpartner-fax') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">
                        {{ $t("UboEingabeformularView.Label.AnsprechpartnerFax") }}
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ this.fax }}
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('ansprechpartner-email') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">
                        {{ $t("UboEingabeformularView.Label.AnsprechpartnerEmail") }}
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ this.email }}
                    </div>
                </div>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-schliessen-ansprechpartner" @click.prevent="close">
                        Schliessen
                        <i class="crefo-ui-icon icon-close" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Unser Ansprech­partner für Ihr Unternehmen</div>
                <p>
                    Wen dürfen wir in Zukunft für die Aktualisierung der Unternehmensdaten ansprechen?
                </p>
                <div class="row">
                    <div class="col-12">
                        <ubo-select field="ansprechpartner-anrede">
                            <select v-model="anrede" data-qa="ubo-form-ansprechpartner-anrede">
                                <option v-for="s in anreden" :value="s[0]">
                                    {{ s[1] }}
                                </option>
                            </select>
                        </ubo-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <input-field rules="max:300"
                                     type="text"
                                     field="ansprechpartner-name"
                                     :label="$t('UboEingabeformularView.Label.AnsprechpartnerName')"
                                     v-model="name"/>

                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field rules="max:100"
                                     type="text"
                                     field="ansprechpartner-funktion"
                                     :label="$t('UboEingabeformularView.Label.AnsprechpartnerFunktion')"
                                     v-model="funktion"/>

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <input-field rules="max:100|numeric"
                                     type="text"
                                     field="ansprechpartner-telefon"
                                     :label="$t('UboEingabeformularView.Label.AnsprechpartnerTelefon')"
                                     v-model="telefon"/>

                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field rules="max:100|numeric"
                                     type="text"
                                     field="ansprechpartner-mobil"
                                     :label="$t('UboEingabeformularView.Label.AnsprechpartnerMobil')"
                                     v-model="mobil"/>

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <input-field rules="max:100|numeric"
                                     type="text"
                                     field="ansprechpartner-fax"
                                     :label="$t('UboEingabeformularView.Label.AnsprechpartnerFax')"
                                     v-model="fax"/>

                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field :rules="{regex: /@/, max: 100, required: true}"
                                     type="text"
                                     field="ansprechpartner-email"
                                     :label="$t('UboEingabeformularView.Label.AnsprechpartnerEmail')"
                                     v-model="email"/>

                    </div>
                </div>
                <div class="row mb-small">
                    <div class="col-12">
                        <p v-show="email===null">
                            Wenn Sie die E-Mail-Adresse ergänzen, können wir Ihnen kostenlos die <a :href="href"
                                                                                                    target="_blank">Selbstauskunft</a>
                            bequem und schnell per E-Mail zukommen lassen.
                        </p>
                        <p v-show="email!==null">
                            Bitte prüfen Sie die E-Mail-Adresse, dann lassen wir Ihnen die <a :href="href"
                                                                                              target="_blank">Selbstauskunft</a>
                            kostenlos per E-Mail zukommen.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb">
                        <button data-qa="ubo-uebernehmen-ansprechpartner" class="btn btn-default btn-min btn-small" :disabled="stepHasErrors"
                                @click.prevent="updateFormData">Änderungen übernehmen
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="ubo-form-mode-view" v-if="!isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-bearbeiten-anmerkungen" @click.prevent="open">
                        Bearbeiten
                        <i class="crefo-ui-icon icon-edit-pen" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">An­mer­kun­gen</div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('anmerkungen') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Weitere Anmerkungen und Angaben zu Ihrem Unternehmen</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.anmerkungen }}</div>
                </div>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-schliessen-anmerkungen" @click.prevent="close">
                        Schliessen
                        <i class="crefo-ui-icon icon-close" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">An­mer­kun­gen</div>
                <div class="row">
                    <div class="col-12">
                        <ubo-input
                            field="anmerkungen"
                            data-qa="ubo-form-anmerkungen-notiz-message"
                        >
                            <textarea v-model="anmerkungen"
                                      data-qa="ubo-form-anmerkungen-notiz"
                                      data-no-crefo-ui="true">
                            </textarea>
                        </ubo-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb">
                        <button data-qa="ubo-uebernehmen-anmerkungen" class="btn btn-default btn-min btn-small" :disabled="stepHasErrors"
                                @click.prevent="updateFormData">Änderungen übernehmen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as formatter from '@/helper/formatter'
import * as keys from '@/helper/keys'
import InputDate from '@/components/helper/input-date'
import UboInput from '@/components/commons/ubo-input'
import UboSelect from '@/components/commons/ubo-select'
import InputField from '@/components/commons/input-field'
export default {
    name: 'AnsprechpartnerAnmerkungen',
    components: { InputDate, UboInput, UboSelect, InputField },
    props: {
        menuConfig: {},
        errors: {},
        fuellgrad: {}
    },
    data() {
        return {
            ansprechpartner: {},
            anmerkungenValue: null,
            stepHasErrors: false
        }
    },
    computed: {
        renderEditMode() {
            return (this.menuConfig.edit && this.menuConfig.activeStep === 7)
                || this.$store.state.userModule.unternehmensdatenPreValidated === false
                || this.stepHasErrors
        },
        isEditModeActive() {
            return this.menuConfig.edit && this.menuConfig.activeStep === 7
        },
        hasErrors: function () {
            return fieldname => this.errors && this.errors[fieldname] instanceof Array && this.errors[fieldname].length
        },
        anreden: () => {
            return keys.salutations()
        },
        anredeName: {
            get() {
                let result = ''
                if (this.ansprechpartner) {
                    result = keys.salutationFor(this.ansprechpartner.anrede)
                }
                return result
            }
        },
        anrede: {
            get() {
                let result = ''
                if (this.ansprechpartner) {
                    result = this.ansprechpartner.anrede
                }
                return result
            },
            set(newSalutation) {
                if (this.ansprechpartner) {
                    this.ansprechpartner.anrede = newSalutation
                }
            }
        },
        name: {
            get() {
                let result = ''
                if (this.ansprechpartner) {
                    result = formatter.formatString(this.ansprechpartner.name)
                }
                return result
            },
            set(newName) {
                this.ansprechpartner.name = formatter.parseString(newName)
            }
        },
        funktion: {
            get() {
                let result = ''
                if (this.ansprechpartner) {
                    result = formatter.formatString(this.ansprechpartner.funktion)
                }
                return result
            },
            set(newFunktion) {
                this.ansprechpartner.funktion = formatter.parseString(newFunktion)
            }
        },
        email: {
            get() {
                let result = ''
                if (this.ansprechpartner) {
                    result = formatter.formatString(this.ansprechpartner.email)
                }
                return result
            },
            set(newEMail) {
                this.ansprechpartner.email = formatter.parseString(newEMail)
            }
        },
        telefon: {
            get() {
                let result = ''
                if (this.ansprechpartner) {
                    result = formatter.formatString(this.ansprechpartner.telefon)
                }
                return result
            },
            set(newPhone) {
                this.ansprechpartner.telefon = formatter.parseString(newPhone)
            }
        },
        fax: {
            get() {
                let result = ''
                if (this.ansprechpartner) {
                    result = formatter.formatString(this.ansprechpartner.fax)
                }
                return result
            },
            set(newFax) {
                this.ansprechpartner.fax = formatter.parseString(newFax)
            }
        },
        mobil: {
            get() {
                let result = ''
                if (this.ansprechpartner) {
                    result = formatter.formatString(this.ansprechpartner.mobil)
                }
                return result
            },
            set(newMobile) {
                this.ansprechpartner.mobil = formatter.parseString(newMobile)
            }
        },
        anmerkungen: {
            get() {
                let result = ''
                result = formatter.formatString(this.anmerkungenValue)
                return result
            },
            set(newAnmerkungen) {
                this.anmerkungenValue = formatter.parseString(newAnmerkungen)
            }
        },
        href() {
            return "https://www.creditreform.de/loesungen/bonitaet-risikobewertung/bonitaet-unternehmen/selbstauskunft"
        }
    },
    methods: {
        activeFormStep(step) {
            return this.menuConfig.activeStep === step
        },
        activeStep(step) {
            let result = null
            if (this.menuConfig.activeStep === step) {
                result = 'ubo-step--active'
            }
            return result
        },
        open() {
            this.restoreFormData()
            this.$emit("edit-step", 7)
        },
        close() {
            this.$emit('select-step', 7)
        },
        restoreFormData() {
            try {
                this.ansprechpartner = JSON.parse(JSON.stringify(this.$store.state.userModule.unternehmensdaten.ansprechpartner))
                this.anmerkungenValue = JSON.parse(JSON.stringify(this.$store.state.userModule.unternehmensdaten.anmerkungen))
            } catch (e) {}
        },
        updateFormData() {
            this.$store.commit('userModule/ON_CHANGE_CONTACT_PERSON', this.ansprechpartner)
            this.$store.commit('userModule/ON_CHANGE_NOTES', this.anmerkungenValue)
            this.$emit('save-step')
            this.$emit("select-step", 7)
        }
    },
    inject: {
        editEventBus: "editEventBus"
    },
    created: function () {
        this.editEventBus.$on('has-errors-step-7', (hasErrors) => {
            this.stepHasErrors = hasErrors
        })
        this.restoreFormData()
    }
}
</script>

<!-- Formularkomponenten -->
<style scoped lang="less">

.crefo-input {
    padding-top: 12px;
    padding-bottom: 12px;

    .input-group {
        .error-msg {
            min-height: 30px;
            visibility: hidden;
            display: inline-block !important;
            font-size: 12px !important;
        }

        &.has-error {

            & > input[type="checkbox"] + label:before {
                border-color: #e05469;
            }

            label {
                color: #e05469;
            }

            .error-msg {
                visibility: visible;
            }
        }
    }
    textarea {
        min-height: 200px !important;
    }
}


.ubo-form-chapter {
    display: none;
    position: relative;
    border-bottom: 1px solid #edece5;

    &:last-child {
        padding-bottom: 30px !important;

        @media only screen and (min-width: 1200px) {
            padding-bottom: 40px !important;
            border-bottom: 0 none transparent;
        }
    }

    .h2 {
        padding-right: 140px;
    }

    .ubo-form-edit {
        position: absolute;
        top: 33px;
        right: 17px;
        width: 130px;
        height: 20px;
        font-weight: 600;
        text-align: right;

        @media only screen and (max-width: 1199px) {
            top: 23px;
        }
        @media only screen and (max-width: 767px) {
            top: 18px;
        }

        a {
            text-transform: uppercase;

            i {
                position: relative;
                top: 5px;
            }
        }
    }

    .ubo-form-display {
        align-items: flex-end;

        &:last-child {
            margin-bottom: 30px;
        }

        .ubo-form-display-label, .ubo-form-display-data {
            padding-top: 30px;
            padding-bottom: 15px;
            border-bottom: 1px solid #edece5;

            @media only screen and (max-width: 991px) {
                border-bottom: 0 none transparent;
            }
        }

        .ubo-form-display-label {
            padding-right: 15px;

            @media only screen and (max-width: 991px) {
                padding-bottom: 5px;
            }
        }

        .ubo-form-display-data {
            font-weight: 600;

            .crefo-brand-icon {
                color: #b5b5b5;
                font-size: 12px;
            }

            @media only screen and (max-width: 991px) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    .ubo-form-mode-edit {
        .ubo-input-wrapper-label {
            margin-bottom: -2px;

            strong {
                font-size: 20px;
                color: #004884;
            }
        }

        .crefo-input {
            margin-bottom: 0;

            .input-group {
                margin-bottom: 10px;

                input {
                    &::placeholder {
                        color: #b5b5b5;
                    }
                }
            }
        }
    }
}


.ubo-form-step {
    &--active {
        .ubo-form-chapter {
            display: block;
        }
    }
}
</style>
