<template>
    <div>
        <div class="box-spacing">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1>{{ $t("ConfirmationView.Label.Header1") }}</h1>
                        <p>{{ $t("ConfirmationView.Label.Paragraph1") }}</p>
                        <p>{{ $t("ConfirmationView.Label.Paragraph2") }}</p>
                    </div>
                </div>
            </div>
        </div>
        <feedback :crefonummer="crefonummer" />
        <div class="box-spacing">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1>{{ $t("ConfirmationView.Label.Header2") }}</h1>
                        <p>{{ $t("ConfirmationView.Label.Paragraph3") }}</p>
                        <p>{{ $t("ConfirmationView.Label.Paragraph4") }}</p>
                        <p v-html="$t('ConfirmationView.Label.Paragraph5')"/>
                        <p>{{ $t("ConfirmationView.Label.Paragraph6") }} <span v-html="mehrInfos"></span></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-brand bg-brand-light-grey box-spacing">
            <div class="container">
                <tripple-teaser />
            </div>
        </div>
        <div class="box-spacing">
            <div class="container">
                <erfolgsfaktoren page="UboBestaetigungView" />
            </div>
        </div>
    </div>
</template>

<script>
    /**
     *  A companies participant fills out the 'ubo form' on this page.
     */
    import { mapState } from 'vuex'
    import Feedback from '../components/bestaetigung/feedback'
    import Erfolgsfaktoren from '../components/commons/erfolgsfaktoren'
    import TrippleTeaser from '../components/commons/tripple-teaser'

    export default {
        components: { Erfolgsfaktoren, TrippleTeaser, Feedback },
        name: 'UboBestaetigungView',
        data() {
            return {
                mehrInfos: ''
            }
        },
        computed: {
            ...mapState('userModule', ['crefonummer', 'ajaxError'])
        },
        created() {
            this.mehrInfos = '<a href="' + this.$t('URL.MehrInfos') + '" role="button" data-qa="mehr-infos" ><span>' + this.$t('ConfirmationView.Label.MehrInfos') + '</span></a>'
        }
    }
</script>

