<template>
    <div
        class="row ubo-form-step"
        :class="{ 'has-errors': stepHasErrors }"
    >
        <div class="col-12 d-xl-none ubo-step box-spacing">
            <div class="ubo-step-number">
                <span>4</span>
            </div>
            <div
                class="ubo-step-name"
                data-qa="menue-mobil-umsatz-ma"
                @click.prevent="close"
            >
                Umsatz und Mitarbeiter
            </div>
            <div class="ubo-step-progress">
                <span class="text-small">{{ fuellgrad.anteilUmsatzangaben }}</span>
            </div>
            <i class="crefo-ui-icon" aria-hidden="true"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UmsatzangabenHead',
    props: {
        fuellgrad: {}
    },
    data() {
        return {
            stepHasErrors: false
        }
    },
    methods: {
        close() {
            this.$emit('select-step', 4)
        }
    },
    inject: {
        editEventBus: "editEventBus"
    },
    created: function () {
        this.editEventBus.$on('has-errors-step-4', (hasErrors) => {
            this.stepHasErrors = hasErrors
        })
    }
}
</script>
