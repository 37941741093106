import store from '../store'
import HelperWebtrekk from '@dm/helper-webtrekk'

export function trackAction (name = 'action', parameters = {}) {
    if (parameters && !parameters[12]) {
        const crefonummer = store.state.userModule.crefonummer
        parameters[12] = typeof crefonummer === 'string' && crefonummer.length ? crefonummer : '-'
    }

    HelperWebtrekk.trackAction(name, parameters)
}
