import i18n from '../i18n'

const countryList = require('i18n-iso-countries')
countryList.registerLocale(require('i18n-iso-countries/langs/de.json'))

const countryTelData = require('country-telephone-data')

/**
 *
 * keys and values for international area codes
 * @return {Array}
 *
 */
/*
Object {
   "dialCode": "93",
   "format": "+..-..-...-....",
   "iso2": "af",
   "name": "Afghanistan (‫افغانستان‬‎)",
   "priority": 0,
  }
 */
export const internationalAreaCodes = () => {
    const list = countryTelData.allCountries
        .map(countryArray => mapCountryTelData(countryArray))
        .sort((a, b) => {
            if (a.value < b.value) {
                return -1
            } else if (a.value > b.value) {
                return 1
            } else {
                return 0
            }
        })
        .filter((item, index, array) => index === 0 || item.value !== array[index - 1].value)

    list.unshift({ key: '', value: '' },{ key: 'DE', value: '+49' }, { key: 'AT', value: '+43' }, { key: 'LU', value: '+352' })

    return list
}

/**
 *
 * international area code for country key
 * @return {String}
 *
 */

export const internationalAreaCodeFor = (key) => {
    let result = ''
    if(key){
        const country = countryTelData.allCountries.find(country=>country.iso2 === key.toLowerCase())
        if(country){
            result = "+".concat(country.dialCode)
        }
    }
    return result
}

const mapCountryTelData = (country) => ({key: country.iso2.toUpperCase(), value: "+".concat(country.dialCode)})

/**
 *
 * keys and values for countries
 * @return {Array}
 *
 */

export const countries = (lang = 'de') => {
    const list = countryList.getNames(lang.toLowerCase(), { select: 'official' })
    return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
}

/**
 *
 * value for country key
 * @return {String}
 *
 */

export const landNameFor = (key, lang = 'de') => {
    return countryList.getName(key, lang.toLowerCase(), { select: "official" })
}



/**
 *
 * value for internationalAreaCode
 * @return {String}
 *
 */
export const countryCodeFor = (internationalAreaCode) => {
    let result = null
    if(internationalAreaCode && !internationalAreaCode.startsWith("+"))
    {
        internationalAreaCode = "+".concat(internationalAreaCode.replace(/^0+/, ''))
    }
    internationalAreaCodeMap.forEach((value, key) => {
        if (value === internationalAreaCode)
            result = key
    });
    return result
}

/**
 *
 * keys and values for salutations
 * @return {Array}
 *
 */
export const salutations = () => {
    const map = new Map()
    map.set("FRAU_HERR", i18n.t("UboEingabeformularView.Key.AnsprechpartnerSalutationFrauHerr"))
    map.set("FRAU", i18n.t("UboEingabeformularView.Key.AnsprechpartnerSalutationFrau"))
    map.set("HERR", i18n.t("UboEingabeformularView.Key.AnsprechpartnerSalutationHerr"))
    return Array.from(map)
}

export const salutationFor = (key) => {
    let result
    if (key === "FRAU_HERR") {
        result = i18n.t("UboEingabeformularView.Key.AnsprechpartnerSalutationFrauHerr")
    } else if (key === "FRAU") {
        result = i18n.t("UboEingabeformularView.Key.AnsprechpartnerSalutationFrau")
    } else if (key === "HERR") {
        result = i18n.t("UboEingabeformularView.Key.AnsprechpartnerSalutationHerr")
    }
    return result
}


