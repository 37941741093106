<template>
    <div class="row pt" v-if="href!==null">
        <div class="col-12">
            <div class="crefo-ui-alert info">
                <p><strong>Expertentipp:</strong></p>
                <p>Nutzen Sie die Potenziale einer optimalen
                    Bonitätsbeurteilung! Beim Vorliegen eines vollständigen Jahresabschlusses (Bilanz, Gewinn- und
                    Verlustrechnung, Anhang) oder einer aktuellen betriebswirtschaftlichen Auswertung
                    (BWA) kann eine deutlich eingehendere Beurteilung Ihres Unternehmens erfolgen.</p>
                <br/>
                <a :href=href
                   class="text-white text-underlined"
                   data-qa="ubo-form-umsatz-infobox-mailto-link">
                    Jetzt per E-Mail an Creditreform vor Ort senden
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Expertentipp',
        props: {
            href: null
        }
    }
</script>
