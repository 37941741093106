<template>
    <div class="crefo-input">
        <ValidationProvider :name=field :rules=rules :vid=field
                            v-slot="{ invalid, errors, dirty, changed, pristine }">

            <div v-if="isCheckbox" class="input-group" :class="{ 'has-error': ((!checkDirty||dirty)&&invalid), 'is-invalid': invalid && pristine }">
                <label v-if="label" :class="{'has-changed':changed&&!((!checkDirty||dirty)&&invalid)}">
                    {{ label }}
                </label>
                <input type="checkbox"
                       v-model="model"
                       :data-qa="dataQaInput"
                       data-no-crefo-ui="true">
                <label>{{ $t('UboEingabeformularView.Expected') }}</label>
            </div>
            <div v-else class="input-group" :class="{ 'has-error': ((!checkDirty||dirty)&&invalid), 'is-invalid': invalid && pristine }">
                <label :class="{'has-changed':changed&&!((!checkDirty||dirty)&&invalid)}">
                    {{ label }}
                </label>
                <div class="input-wrapper">
                    <span v-if="tooltip" class="crefo-ui-tooltip">
                        <span class="inline-wrapper">
                            <i class="crefo-ui-icon icon-circle-info" aria-hidden="true"></i>
                            <span class="crefo-tooltip">
                                {{ tooltip }}
                            </span>
                        </span>
                    </span>
                    <input v-if=lazy
                           :type="type"
                           :placeholder="placeholder"
                           :readonly="readonly"
                           v-model.lazy="model"
                           :data-qa="dataQaInput"
                           data-no-crefo-ui="true">
                    <input v-else
                           :type="type"
                           :placeholder="placeholder"
                           :readonly="readonly"
                           v-model="model"
                           :data-qa="dataQaInput"
                           data-no-crefo-ui="true">
                    <i v-if="unit">{{ unit }}</i>
                </div>
                <span v-if="!isCheckbox" class="error-msg" :data-qa="dataQaErrorMsg">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>
    </div>
</template>

<script>

import '@/helper/standard-rules'
import {ValidationProvider} from 'vee-validate'

export default {
    components: {ValidationProvider},
    name: "InputField",
    props: {
        field: {
            type: String,
            required: true
        },
        rules: {
            type: [Object, String]
        },
        label: {
            type: String
        },
        tooltip: {
            type: String
        },
        unit: {
            type: String
        },
        type: {
            type: String,
            required: true
        },
        placeholder: {
            type: String
        },
        lazy: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        checkDirty: {
            type: Boolean,
            default: true
        },
        // muss hinzugefügt werden, und wird mit v-model übergeben
        value: {
            type: null
        }
    },
/*
    data() {
        return {
            innerValue: ''
        }
    },
*/
    computed: {
        dataQaInput() {
            return this.field
        },
        dataQaErrorMsg() {
            return this.field + "-message"
        },
        model: {
            get() {
                return this.value;
            },
            set(newVal) {
                this.$emit('input', newVal);
            },
        },
        isCheckbox() {
            if (this.type === 'checkbox') {
                return true
            } else {
                return false
            }
        },

    },
    /*
        watch: {
            innerValue(newVal) {
                if (this.type === 'checkbox' || this.type === 'radio') {
                    this.$emit('input', newVal);
                } else {
                    this.$emit('input', newVal);
                }

            },
            value(newVal) {
                this.innerValue = newVal;
            }
        },
        created() {
            if (this.value) {
                this.innerValue = this.value;
                console.log("value: "+this.innerValue)
            }
        }
    */
}
</script>

<style scoped lang="less">

.crefo-input {
    padding-top: 12px;
    padding-bottom: 12px;

    .has-changed {
        color: #8acac1 !important;
    }

    .input-group {

        .input-wrapper {


            i {
                display: inline-block !important;
            }

            .inline-wrapper {


                i {
                    top: 90% !important;
                }
            }
        }

        .error-msg {
            min-height: 30px;
            visibility: hidden;
            display: inline-block !important;
            font-size: 12px !important;
        }

        &.has-error, &.is-invalid {

            .input-wrapper {
                color: #e05469;
                border-color: #e05469;
            }

            & > input[type="checkbox"] + label:before {
                border-color: #e05469;
            }

            label {
                color: #e05469;
            }

            .error-msg {
                visibility: visible;
            }
        }
    }
}

.input-amount_TODO {
    input {
        padding-right: 60px !important;
    }

    i {
        display: inline-block !important;
        position: absolute !important;
        font-size: 15px !important;
        font-family: "Open Sans", Arial;
    }
}

</style>
