<template>
    <div class="row ubo-form-step" :class="{ 'ubo-form-step--active': activeFormStep(2), 'has-errors': stepHasErrors }">
        <div class="col-12 d-xl-none ubo-step box-spacing" :class="activeStep(2)">
            <div class="ubo-step-number">
                <span>2</span>
            </div>
            <div class="ubo-step-name" data-qa="menue-mobil-inhaber" @click.prevent="close"><span v-if="inhabergefuehrt">Inhaber</span><span v-else>Ent­scheider</span></div>
            <div class="ubo-step-progress">
                <span class="text-small">{{ fuellgrad.anteilInhaber }}</span>
            </div>
            <i class="crefo-ui-icon" aria-hidden="true"></i>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="h1"><span v-if="inhabergefuehrt">Inhaber</span><span v-else>Ent­scheider</span></div>
            <p>
                Geben Sie nun Ihre persönlichen Daten an.
            </p>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="ubo-form-mode-view" v-if="!isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-bearbeiten-persoenliche-daten" @click.prevent="open">
                        Bearbeiten
                        <i class="crefo-ui-icon icon-edit-pen" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Per­sön­liche Da­ten</div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('inhaber-vorname') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Vorname</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ this.vorname }}
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('inhaber-nachname') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Nachname</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.nachname }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('inhaber-geburtsname') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Geburtsname</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ this.geburtsname }}
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('inhaber-geburtsdatum') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Geburtsdatum</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.geburtsdatum }}</div>
                </div>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-schliessen-persoenliche-daten" @click.prevent="close">
                        Schliessen
                        <i class="crefo-ui-icon icon-close" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Per­sön­liche Da­ten</div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <input-field rules="max:30"
                                     type="text"
                                     field="inhaber-vorname"
                                     :label="$t('UboEingabeformularView.Label.InhaberVorname')"
                                     v-model="vorname" />

                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field rules="max:30"
                                     type="text"
                                     field="inhaber-nachname"
                                     :label="$t('UboEingabeformularView.Label.InhaberNachname')"
                                     v-model="nachname" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <input-field rules="max:30"
                                     type="text"
                                     field="inhaber-geburtsname"
                                     :label="$t('UboEingabeformularView.Label.InhaberGeburtsname')"
                                     v-model="geburtsname" />

                    </div>
                    <div class="col-12 col-lg-6">
                        <ubo-input
                            field="inhaber-geburtsdatum"
                            data-qa="ubo-form-inhaber-geburtsdatum-message"
                        >
                            <input-date
                                type="date"
                                v-model.lazy="geburtsdatum"
                                :default-value="fullYearFromToday"
                                :disabled-date="notAllowedDate"
                                :placeholder="geburtsdatum"
                                value-type="format"
                                data-qa="ubo-form-inhaber-geburtsdatum"
                                data-no-crefo-ui="true"
                                @input-error="dateInputError"
                            ></input-date>
                        </ubo-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb">
                        <button data-qa="ubo-uebernehmen-persoenliche-daten" class="btn btn-default btn-min btn-small" :disabled="stepHasErrors"
                                @click.prevent="updateFormData">Änderungen übernehmen
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="ubo-form-mode-view" v-if="!isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-bearbeiten-privatanschrift" @click.prevent="open">
                        Bearbeiten
                        <i class="crefo-ui-icon icon-edit-pen" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Privat­anschrift</div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('inhaber-strasse') || hasErrors('inhaber-hausnummer') || hasErrors('inhaber-hausnummerZusatz') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Straße, Haus-Nr.</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.strasseHausnummer }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('inhaber-plz') || hasErrors('inhaber-ort') || hasErrors('inhaber-ortsteil') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">PLZ, Ort</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.plzOrt }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('inhaber-land') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Land</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.landName }}</div>
                </div>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-schliessen-privatanschrift" @click.prevent="close">
                        Schliessen
                        <i class="crefo-ui-icon icon-close" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Privat­anschrift</div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <ubo-select field="inhaber-land">
                            <select v-model="land">
                                <option v-for="l in laender" :value="l.value">
                                    {{ l.label }}
                                </option>
                            </select>
                        </ubo-select>
                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field :rules="{zip_code: '@inhaber-land', max: 10}"
                                     type="text"
                                     field="inhaber-plz"
                                     :label="$t('UboEingabeformularView.Label.InhaberPlz')"
                                     v-model="plz" />

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <input-field rules="max:40"
                                     type="text"
                                     field="inhaber-ort"
                                     :label="$t('UboEingabeformularView.Label.InhaberOrt')"
                                     v-model="ort" />

                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field rules="max:40"
                                     type="text"
                                     field="inhaber-ortsteil"
                                     :label="$t('UboEingabeformularView.Label.InhaberOrtsteil')"
                                     v-model="ortsteil" />

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <input-field rules="max:46"
                                     type="text"
                                     field="inhaber-strasse"
                                     :label="$t('UboEingabeformularView.Label.InhaberStrasse')"
                                     v-model="strasse" />

                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field rules="max:8"
                                     type="text"
                                     field="inhaber-hausnummer"
                                     :label="$t('UboEingabeformularView.Label.InhaberHausnummer')"
                                     v-model="hausnummer" />

                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field rules="max:10"
                                     type="text"
                                     field="inhaber-hausnummerZusatz"
                                     :label="$t('UboEingabeformularView.Label.HausnummerZusatz')"
                                     v-model="hausnummerZusatz" />

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb">
                        <button data-qa="ubo-uebernehmen-privatanschrift" class="btn btn-default btn-min btn-small" :disabled="stepHasErrors"
                                @click.prevent="updateFormData">Änderungen übernehmen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as formatter from '@/helper/formatter'
import * as keys from '@/helper/keys'
import InputDate from '@/components/helper/input-date'
import UboInput from '@/components/commons/ubo-input'
import UboSelect from '@/components/commons/ubo-select'
import InputField from '@/components/commons/input-field'
export default {
    name: 'Inhaber',
    components: { InputDate, UboInput, UboSelect, InputField },
    props: {
        menuConfig: {},
        errors: {},
        inhabergefuehrt: false,
        fuellgrad: {}
    },
    data() {
        return {
            inhaber: {},
            stepHasErrors: false
        }
    },
    computed: {
        renderEditMode() {
            return (this.menuConfig.edit && this.menuConfig.activeStep === 2)
                || this.$store.state.userModule.unternehmensdatenPreValidated === false
                || this.stepHasErrors
        },
        isEditModeActive() {
            return this.menuConfig.edit && this.menuConfig.activeStep === 2
        },
        hasErrors: function () {
            return fieldname => this.errors && this.errors[fieldname] instanceof Array && this.errors[fieldname].length
        },
        laender() {
            return keys.countries(this.$store.state.userModule.language)
        },
        // compound fields
        strasseHausnummer: {
            get() {
                let result = ''
                if (this.inhaber && this.inhaber.adresse) {
                    if (this.inhaber.adresse.strasse) {
                        result = this.inhaber.adresse.strasse
                    }
                    if (this.inhaber.adresse.hausnummer) {
                        result += ' '
                        result += this.inhaber.adresse.hausnummer
                    }
                    if (this.inhaber.adresse.hausnummerZusatz) {
                        result += this.inhaber.adresse.hausnummerZusatz
                    }
                }
                return result
            }
        },
        plzOrt: {
            get() {
                let result = ''
                if (this.inhaber && this.inhaber.adresse) {
                    if (this.inhaber.adresse.plz) {
                        result = this.inhaber.adresse.plz
                    }
                    if (this.inhaber.adresse.ort) {
                        result += ' '
                        result += this.inhaber.adresse.ort
                    }
                    if (this.inhaber.adresse.district) {
                        result += ', '
                        result += this.inhaber.adresse.district
                    }
                }
                return result
            }
        },
        /**
         *  inhaber-land
         */
        nachname: {
            get() {
                let result = null
                if (this.inhaber) {
                    result = formatter.formatString(this.inhaber.nachname)
                }
                return result
            },
            set(newNachname) {
                if (this.inhaber) {
                    this.inhaber.nachname = formatter.parseString(newNachname)
                }
            }
        },
        vorname: {
            get() {
                let result = null
                if (this.inhaber) {
                    result = formatter.formatString(this.inhaber.vorname)
                }
                return result
            },
            set(newSurname) {
                if (this.inhaber) {
                    this.inhaber.vorname = formatter.parseString(newSurname)
                }
            }
        },
        geburtsname: {
            get() {
                let result = null
                if (this.inhaber) {
                    result = formatter.formatString(this.inhaber.geburtsname)
                }
                return result
            },
            set(newGeburtsname) {
                if (this.inhaber) {
                    this.inhaber.geburtsname = formatter.parseString(newGeburtsname)
                }
            }
        },
        geburtsdatum: {
            get() {
                let result = null
                if (this.inhaber && this.inhaber.geburtsdatum) {
                    result = formatter.formatDate(this.inhaber.geburtsdatum)
                }
                return result
            },
            set(newGeburtsdatum) {
                if (this.inhaber) {
                    this.inhaber.geburtsdatum = formatter.parseDate(newGeburtsdatum)
                }
            }
        },
        landName: {
            get() {
                let result = null
                if (this.inhaber && this.inhaber.adresse) {
                    result = keys.landNameFor(this.inhaber.adresse.land, this.$store.state.userModule.language)
                }
                return result
            }
        },
        land: {
            get() {
                let result = null
                if (this.inhaber && this.inhaber.adresse) {
                    result = this.inhaber.adresse.land
                }
                return result
            },
            set(newCountry) {
                if (this.inhaber && this.inhaber.adresse) {
                    this.inhaber.adresse.land = newCountry
                }
            }
        },
        plz: {
            get() {
                let result = null
                if (this.inhaber && this.inhaber.adresse) {
                    result = formatter.formatString(this.inhaber.adresse.plz)
                }
                return result
            },
            set(newPlz) {
                if (this.inhaber && this.inhaber.adresse) {
                    this.inhaber.adresse.plz = formatter.parseString(newPlz)
                }
            }
        },
        ort: {
            get() {
                let result = null
                if (this.inhaber && this.inhaber.adresse) {
                    result = formatter.formatString(this.inhaber.adresse.ort)
                }
                return result
            },
            set(newOrt) {
                if (this.inhaber && this.inhaber.adresse) {
                    this.inhaber.adresse.ort = formatter.parseString(newOrt)
                }
            }
        },
        ortsteil: {
            get() {
                let result = null
                if (this.inhaber && this.inhaber.adresse) {
                    result = formatter.formatString(this.inhaber.adresse.ortsteil)
                }
                return result
            },
            set(newOrt) {
                if (this.inhaber && this.inhaber.adresse) {
                    this.inhaber.adresse.ortsteil = formatter.parseString(newOrt)
                }
            }
        },
        strasse: {
            get() {
                let result = null
                if (this.inhaber && this.inhaber.adresse) {
                    result = formatter.formatString(this.inhaber.adresse.strasse)
                }
                return result
            },
            set(newStrasse) {
                if (this.inhaber && this.inhaber.adresse) {
                    this.inhaber.adresse.strasse = formatter.parseString(newStrasse)
                }
            }
        },
        hausnummer: {
            get() {
                let result = null
                if (this.inhaber && this.inhaber.adresse) {
                    result = result = formatter.formatNumber(this.inhaber.adresse.hausnummer)
                }
                return result
            },
            set(newHausnummer) {
                if (this.inhaber && this.inhaber.adresse) {
                    this.inhaber.adresse.hausnummer = formatter.parseNumber(newHausnummer)
                }
            }
        },
        hausnummerZusatz: {
            get() {
                let result = null
                if (this.inhaber && this.inhaber.adresse) {
                    result = formatter.formatString(this.inhaber.adresse.hausnummerZusatz)

                }
                return result
            },
            set(newHausnummerZusatz) {
                if (this.inhaber && this.inhaber.adresse) {
                    this.inhaber.adresse.hausnummerZusatz = formatter.parseString(newHausnummerZusatz)
                }
            }
        },
    },
    methods: {
        activeFormStep(step) {
            return this.menuConfig.activeStep === step
        },
        activeStep(step) {
            let result = null
            if (this.menuConfig.activeStep === step) {
                result = 'ubo-step--active'
            }
            return result
        },
        open() {
            this.restoreFormData()
            this.$emit("edit-step", 2)
        },
        close() {
            this.$emit('select-step', 2)
        },
        restoreFormData() {
            try {
                this.inhaber = JSON.parse(JSON.stringify(this.$store.state.userModule.unternehmensdaten.inhaber))
            } catch (e) {}
        },
        updateFormData() {
            this.$store.commit('userModule/ON_CHANGE_OWNER', this.inhaber)
            this.$emit('save-step')
            this.$emit("select-step", 2)
        },
        notAllowedDate(date) {
            return Date.now() - date.getTime() < 0
        },
        // TODO
        fullYearFromToday() {
            let result = new Date()
            result.setFullYear(result.getFullYear() - 18)
            return result
        },
        dateInputError(text) {
            console.log(`Fehler ${text}`)
        }
    },
    inject: {
        editEventBus: "editEventBus"
    },
    created: function () {
        this.editEventBus.$on('has-errors-step-2', (hasErrors) => {
            this.stepHasErrors = hasErrors
        })
        this.restoreFormData()
    }
}
</script>

<!-- Formularkomponenten -->
<style scoped lang="less">

.ubo-form-chapter {
    display: none;
    position: relative;
    border-bottom: 1px solid #edece5;

    &:last-child {
        padding-bottom: 30px !important;

        @media only screen and (min-width: 1200px) {
            padding-bottom: 40px !important;
            border-bottom: 0 none transparent;
        }
    }

    .h2 {
        padding-right: 140px;
    }

    .ubo-form-edit {
        position: absolute;
        top: 33px;
        right: 17px;
        width: 130px;
        height: 20px;
        font-weight: 600;
        text-align: right;

        @media only screen and (max-width: 1199px) {
            top: 23px;
        }
        @media only screen and (max-width: 767px) {
            top: 18px;
        }

        a {
            text-transform: uppercase;

            i {
                position: relative;
                top: 5px;
            }
        }
    }

    .ubo-form-display {
        align-items: flex-end;

        &:last-child {
            margin-bottom: 30px;
        }

        .ubo-form-display-label, .ubo-form-display-data {
            padding-top: 30px;
            padding-bottom: 15px;
            border-bottom: 1px solid #edece5;

            @media only screen and (max-width: 991px) {
                border-bottom: 0 none transparent;
            }
        }

        .ubo-form-display-label {
            padding-right: 15px;

            @media only screen and (max-width: 991px) {
                padding-bottom: 5px;
            }
        }

        .ubo-form-display-data {
            font-weight: 600;

            .crefo-brand-icon {
                color: #b5b5b5;
                font-size: 12px;
            }

            @media only screen and (max-width: 991px) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    .ubo-form-mode-edit {
        .ubo-input-wrapper-label {
            margin-bottom: -2px;

            strong {
                font-size: 20px;
                color: #004884;
            }
        }

        .crefo-input {
            margin-bottom: 0;

            .input-group {
                margin-bottom: 10px;

                input {
                    &::placeholder {
                        color: #b5b5b5;
                    }
                }
            }

        }
    }
}

.ubo-form-step {
    &--active {
        .ubo-form-chapter {
            display: block;
        }
    }
}
</style>
