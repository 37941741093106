<template>
    <div>
        <div class="container">
            <ubo-landingpage-intro-text />
            <ubo-landingpage-suche-unternehmen
                v-if="showUnternehmensauswahl"
                @company-selected="onCompanySelected"
            />
        </div>
        <div class="mb">
            <ubo-suggested-kurzprofil
                v-if="crefonummer || showUnternehmensauswahl === false"
                :crefonummer="crefonummer"
                :show-header="false"
                v-on:crefo-kurzprofil-fetching-success="onKurzprofilAvailable"
                v-on:crefo-kurzprofil-fetching-error="onKurzprofilNoData"
                v-on:crefo-kurzprofil-fetching="onKurzprofilFetching"
            />
            <div
                v-if="crefonummer"
                class="bg-brand bg-brand-light-grey do-request-access"
            >
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-10">
                            <template v-if="isUboAvailable">
                                <template v-if="isAlreadyVerified">
                                    <p>
                                        <strong>Jetzt in Ihre Unternehmensbefragung einsteigen</strong><br>
                                        Hier gelangen Sie direkt in die Unternehmensbefragung für das oben ausgewählte Unternehmen:
                                    </p>
                                    <button
                                        class="btn btn-default"
                                        :class="{ 'btn-disabled': verifySubmitting }"
                                        :disabled="verifySubmitting"
                                        data-qa="ubo-start-as-vb"
                                        @click.prevent="onVerifyForUbo"
                                    >
                                        Jetzt loslegen!
                                    </button>
                                    <crefo-error-message
                                        v-if="assignCrefonummerAjaxError"
                                        :ajax-error="assignCrefonummerAjaxError"
                                        error-prefix="ZuweisungCrefonummer"
                                        class="mt"
                                    />
                                </template>
                                <template v-else>
                                    <p>
                                        <strong>Jetzt in Ihre Unternehmensbefragung einsteigen</strong><br>
                                        Zum Schutz Ihres Unternehmens müssen Sie sich als vertretungsberechtigt verifizieren:
                                    </p>
                                    <button
                                        class="btn btn-default"
                                        :class="{ 'btn-disabled': verifySubmitting }"
                                        :disabled="verifySubmitting"
                                        data-qa="verify-option-digital"
                                        @click.prevent="onVerifyForUbo"
                                    >
                                        Jetzt für Unternehmensbefragung verifizieren
                                    </button>
                                    <div
                                        v-if="verifySubmitting || verifyErrorCode"
                                        class="crefo-ui-alert mt ubo--verification-text-links"
                                        :class="{ info: verifySubmitting, error: verifyErrorCode }"
                                    >
                                        <p data-qa="verify-status-message" v-html="verifyStatusMessage"></p>
                                    </div>
                                    <div
                                        v-if="showVerifyFailure"
                                        class="row mt"
                                    >
                                        <div class="col-12">
                                            <crefo-error-message>
                                                <p class="mb-0">
                                                    Die CrefoTrust-Verifikation konnte nicht erfolgreich abgeschlossen werden.<br>
                                                    Sie können die Verifikation jederzeit erneut versuchen durchzuführen. Bei Problemen steht
                                                    Ihnen unser Support gerne zur Verfügung.
                                                </p>
                                            </crefo-error-message>
                                        </div>
                                    </div>
                                    <crefo-error-message
                                        v-if="assignCrefonummerAjaxError"
                                        :ajax-error="assignCrefonummerAjaxError"
                                        error-prefix="ZuweisungCrefonummer"
                                        class="mt"
                                    />
                                </template>
                            </template>
                            <template v-if="kurzprofilLoaded && !isUboAvailable">
                                <div
                                    class="crefo-ui-alert info"
                                    data-qa="verify-option-not-available"
                                >
                                    Die Unternehmensbefragung ist nur für Unternehmen mit Sitz in {{ allowedCountriesText }} verfügbar. Für eine
                                    Aktualisierung Ihrer Unternehmensdaten mit Sitz außerhalb kontaktieren Sie uns bitte.
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <ubo-landingpage-unternehmen-nicht-gefunden v-if="showUnternehmensauswahl" />
            <ubo-landingpage-drei-schritte-text :active-step="showUnternehmensauswahl ? 1 : 2" />
            <ubo-landingpage-vorteile-text />
        </div>
    </div>
</template>

<script>
    import CrefoErrorMessage from '@dm/crefo-error-message'
    import UboLandingpageIntroText from '../components/landingpage/intro-text'
    import UboLandingpageSucheUnternehmen from '../components/landingpage/suche-unternehmen'
    import UboSuggestedKurzprofil from '../components/kurzprofil/suggested-kurzprofil'
    import UboLandingpageUnternehmenNichtGefunden from '../components/landingpage/unternehmen-nicht-gefunden'
    import UboLandingpageDreiSchritteText from '../components/landingpage/drei-schritte-text'
    import UboLandingpageVorteileText from '../components/landingpage/vorteile-text'
    import HelperCrefotrust from '@dm/helper-crefotrust'
    import HelperUser from '@dm/helper-user'
    import GeneralUtilityHelper from '@/helper/general'
    import { getUpdatedUserState } from '../helper/user-state'
    import * as api from "@/services";
    export default {
        name: 'UboVerifikationView',
        components: {
            CrefoErrorMessage,
            UboLandingpageIntroText,
            UboLandingpageSucheUnternehmen,
            UboSuggestedKurzprofil,
            UboLandingpageUnternehmenNichtGefunden,
            UboLandingpageDreiSchritteText,
            UboLandingpageVorteileText
        },
        props: {
            prt: {
                type: String,
                required: false,
                default: ''
            },
            verified: {
                type: Boolean,
                required: false,
                default: null
            },
            preselectedCrefonummer: {
                type: String,
                required: false,
                default: ''
            }
        },
        data () {
            return {
                crefonummer: '',
                kurzprofilData: null,
                kurzprofilLoaded: false,
                verifySubmitting: false,
                verifyErrorCode: null,
                verifyAjaxError: null,
                verifyRedirected: false,
                assignCrefonummerAjaxError: null
            }
        },
        computed: {
            isUboAvailable () {
                return this.kurzprofilData
                    && this.kurzprofilData.adresse
                    && GeneralUtilityHelper.isCountryAllowed(this.kurzprofilData.adresse.land)
            },
            isUboVerificationAllowed () {
                return GeneralUtilityHelper.isCountryAllowedForVerification(this.kurzprofilData.adresse.land)
            },
            allowedCountriesText () {
                let allowedCountriesText = GeneralUtilityHelper.getAllowedCountriesByName(this.$store.state.userModule.language).join(', ')
                const lastComma = allowedCountriesText.lastIndexOf(', ')
                if (lastComma > 0) {
                    return allowedCountriesText.substring(0, lastComma) + ' und ' + allowedCountriesText.substring(lastComma + 2)
                } else {
                    return allowedCountriesText
                }
            },
            verifyStatusMessage () {
                if (this.verifyErrorCode) {
                    let key = `Verifikation.Error.CrefoTrustInit`
                    return this.$i18n.t(key, [this.verifyErrorCode])
                }
                return this.$i18n.t('Verifikation.VerificationInProgress')
            },
            showUnternehmensauswahl () {
                if (this.$store.state.userModule.userState
                        && this.$store.state.userModule.userState.zugeordneteCrefonummer) {
                    return false
                } else if (HelperUser.isRegisteredUser()) {
                    return true
                }
                return false
            },
            showVerifyFailure () {
                return (this.verified === false && this.verifySubmitting !== true && !this.verifyErrorCode)
            },
            isAlreadyVerified () {
                return (HelperUser.hasRoleVertretungsberechtigung(this.crefonummer) || HelperUser.hasRoleUnternehmensagent(this.crefonummer))
            }
        },
        methods: {
            onCompanySelected (crefonummer) {
                this.crefonummer = crefonummer
            },
            onKurzprofilAvailable (kurzprofilData) {
                this.kurzprofilData = kurzprofilData
                this.kurzprofilLoaded = true
            },
            onKurzprofilNoData () {
                this.kurzprofilData = null
                this.kurzprofilLoaded = true
            },
            onKurzprofilFetching () {
                this.kurzprofilData = null
                this.kurzprofilLoaded = false
            },
            async onVerifyForUbo () {
                if (this.verifySubmitting) {
                    return
                }
                this.verifySubmitting = true
                this.verifyErrorCode = null
                this.verifyAjaxError = null

                if (this.showUnternehmensauswahl) {
                    try {
                        await this.assignCrefonummerToCurrentUser()
                        this.assignCrefonummerAjaxError = null
                        if (this.isAlreadyVerified) {
                            this.$router.push({ name: 'aktivierung-vertretungsberechtigt', query: { crefonummer: this.crefonummer }, params: { redirectInstant: true }})
                            return
                        }
                    } catch (error) {
                        this.assignCrefonummerAjaxError = error
                        this.verifySubmitting = false
                        return
                    }
                }

                HelperCrefotrust.verify({
                    clientId: 'ubo',
                    crefonummer: this.crefonummer,
                    crefonummerUnchangeable: true,
                    packageName: '@portal-microfrontend/ubo-portal'
                })
                    .then(result => {
                        console.log('Redirecting to CrefoTrust')
                    })
                    .catch(reason => {
                        this.verifyErrorCode = reason.errorCode
                        this.verifyAjaxError = reason.ajaxError
                        this.verifySubmitting = false
                        window.scroll(0, 0)
                    })
            },
            assignCrefonummerToCurrentUser () {
                return new Promise(async (resolve, reject) => {
                    let prt = null
                    try {
                        const response = await api.getPostRegistrationToken({
                            origin: window.location.origin + window.location.pathname,
                            crefonummer: this.crefonummer
                        })
                        if (response && response.data && typeof response.data['post-registration-token'] === 'string') {
                            prt = response.data['post-registration-token']
                        }
                    } catch (error) {
                        reject(error)
                    }

                    if (prt) {
                        try {
                            const response = await api.doConfirmRegistration(prt)
                            if (response && response.data && typeof response.data.crefonummer === 'string'
                                && response.data.crefonummer.length) {
                                this.$store.commit('userModule/ON_SET_USER_STATE', getUpdatedUserState(response.data.crefonummer))
                                resolve(response.data.crefonummer)
                            }
                        } catch (error) {
                            reject(error)
                        }
                    }
                })
            }
        },
        created() {
            if (this.$store.state.userModule.userState
                  && this.$store.state.userModule.userState.zugeordneteCrefonummer) {
                this.crefonummer = this.$store.state.userModule.userState.zugeordneteCrefonummer
            } else if (this.preselectedCrefonummer && HelperUser.isRegisteredUser()) {
                this.crefonummer = this.preselectedCrefonummer
            }
        }
    }
</script>

<style scoped lang="less">
    .do-request-access {
        margin-top: -15px;
        padding: 0 30px 30px 30px;

        @media only screen and (min-width: 768px) {
            margin-top: -30px;
            padding: 0 40px 40px 40px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: -40px;
            padding: 0 60px 60px 60px;
        }
    }

    .mt-small {
        margin-top: 7.5px;

        @media only screen and (min-width: 768px) {
            margin-top: 10px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 15px;
        }
    }

    .btn-purple {
        background-color: #a763a4;

        &:hover {
            background-color: #894d87;
        }
    }

    .crefo-ui-alert {
        &.info, &.error {
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
</style>

<style lang="less">
    .ubo--verification-text-links {
        a {
            color: #ffffff;
            text-decoration: underline;

            &:hover {
                color: #ffffff;
                text-decoration: none;
            }
        }
    }
</style>
