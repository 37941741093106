<template>
    <div
        class="row ubo-form-step"
        :class="{ 'has-errors': stepHasErrors }"
    >
        <div class="col-12 d-xl-none ubo-step box-spacing">
            <div class="ubo-step-number">
                <span>2</span>
            </div>
            <div
                class="ubo-step-name"
                data-qa="menue-mobil-inhaber"
                @click.prevent="close"
            >
                <span v-if="inhabergefuehrt">Inhaber</span><span v-else>Ent­scheider</span>
            </div>
            <div class="ubo-step-progress">
                <span class="text-small">{{ fuellgrad.anteilInhaber }}</span>
            </div>
            <i class="crefo-ui-icon" aria-hidden="true"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InhaberHead',
    props: {
        inhabergefuehrt: false,
        fuellgrad: {}
    },
    data() {
        return {
            stepHasErrors: false
        }
    },
    methods: {
        close() {
            this.$emit('select-step', 2)
        }
    },
    inject: {
        editEventBus: "editEventBus"
    },
    created: function () {
        this.editEventBus.$on('has-errors-step-2', (hasErrors) => {
            this.stepHasErrors = hasErrors
        })
    }
}
</script>
