<template>
    <div class="row mb-large">
        <div class="col-12 col-xl-10 three_steps">
            <h2>In 3 Schritten zu aktuellen Daten:</h2>
            <p :class="{ active: activeStep === 1 }">
                <i class="crefo-brand-icon icon-search icon-color-blue" aria-hidden="true"></i>
                <strong>Ihr Unternehmen auswählen.</strong><br>
                Nutzen Sie die Suche, um Ihr Unternehmen bei uns in der Datenbank zu finden. Wählen Sie das
                korrekte Unternehmen aus den Treffern aus.
            </p>
            <p :class="{ active: activeStep === 2 }">
                <i class="crefo-brand-icon icon-user-circle icon-color-blue" aria-hidden="true"></i>
                <strong>Verifikation als Vertretungsberechtigter.</strong><br>
                Die Sicherheit Ihrer Daten hat höchste Priorität. Verifizieren Sie sich als
                Vertretungsberechtigter für Ihr Unternehmen und somit als berechtigt für den Zugriff auf die
                Informationen rund um Ihr Unternehmen.<br v-if="$slots.default">
            </p>
            <p :class="{ active: activeStep === 3 }">
                <i class="crefo-brand-icon icon-file-text icon-color-blue" aria-hidden="true"></i>
                <strong>Ihre Unternehmensdaten.</strong><br>
                Prüfen Sie die vorhandenen Daten über Ihr Unternehmen und vervollständigen Sie diese.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UboLandingpageDreiSchritteText',
        props: {
            activeStep: {
                type: Number,
                required: false,
                default: 0
            }
        }
    }
</script>

<style scoped lang="less">
    .three_steps {
        p {
            position: relative;
            padding-left: 53px;

            &.active {
                background-color: rgba(0, 158, 226, 0.1);
            }

            i {
                position: absolute;
                left: 0;
                top: 5px;
                font-size: 38px;
            }
        }
    }
</style>
