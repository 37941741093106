<template>
    <div class="row ubo-form-step" :class="{ 'ubo-form-step--active': activeFormStep(4), 'has-errors': stepHasErrors }">
        <div class="col-12 d-xl-none ubo-step box-spacing" :class="activeStep(4)">
            <div class="ubo-step-number">
                <span>4</span>
            </div>
            <div class="ubo-step-name" data-qa="menue-mobil-umsatz-ma" @click.prevent="close">Umsatz und Mitarbeiter
            </div>
            <div class="ubo-step-progress">
                <span class="text-small">{{ fuellgrad.anteilUmsatzangaben }}</span>
            </div>
            <i class="crefo-ui-icon" aria-hidden="true"></i>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="h1">Umsatz und Mitarbeiter</div>
            <p>
                Fast geschafft: Nun folgen Angaben zu Umsatz und Mitarbeitern.
            </p>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="ubo-form-mode-view" v-if="!isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-bearbeiten-umsatz" @click.prevent="open">
                        Bearbeiten
                        <i class="crefo-ui-icon icon-edit-pen" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Umsatz</div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('umsatz-jahr') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Umsatzangabe Jahr</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ this.umsatzJahr }}
                        <i class="crefo-brand-icon" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('umsatz-umsatzerwartung') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Umsatzerwartung</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ formatter.formatBoolean(this.umsatzerwartung) }}
                        <i class="crefo-brand-icon" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('umsatz-betrag') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Nettoumsatzerlöse</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ formatter.formatBetrag(this.betrag) }}
                        <i class="crefo-brand-icon" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('umsatz-betragVorjahr') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Nettoumsatzerlöse Vorjahr</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ formatter.formatBetrag(this.betragVorjahr) }}
                        <i class="crefo-brand-icon" aria-hidden="true"></i>
                    </div>
                </div>
                <expertentipp :href="href"></expertentipp>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-schliessen-umsatz" @click.prevent="close">
                        Schliessen
                        <i class="crefo-ui-icon icon-close" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Umsatz</div>
                <p>
                    Die vorbelegten Daten zu Umsatz und Mitarbeitern entsprechen den letzten verifizierten Zahlen,
                    welche uns vorliegen.
                    <strong>Bitte korrigieren oder aktualisieren Sie diese Daten mit Zahlen aus dem
                    <u>laufenden Jahr</u> oder <u>dem Vorjahr</u>.</strong>
                </p>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <input-field rules="max_year|min_year"
                                     type="text"
                                     field="umsatz-jahr"
                                     :label="$t('UboEingabeformularView.Label.UmsatzJahr')"
                                     v-model="umsatzJahr"/>

                    </div>
                    <div class="col-12 col-lg-6">
                        <ubo-input
                            field="umsatz-umsatzerwartung"
                            data-qa="ubo-form-umsatz-umsatzerwartung"
                        >
                            <input
                                id="expected"
                                type="checkbox"
                                v-model=umsatzerwartung
                                data-no-crefo-ui="true"
                                data-qa="umsatz-umsatzerwartung">
                            <label for="expected">{{ $t('UboEingabeformularView.Expected') }}</label>
                        </ubo-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <input-field rules="max_sales_amount"
                                     type="text"
                                     field="umsatz-betrag"
                                     :label="$t('UboEingabeformularView.Label.Betrag')"
                                     unit="EUR"
                                     lazy
                                     v-model=betrag
                                     is-valid="is-valid"/>

                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field rules="max_sales_amount"
                                     type="text"
                                     field="umsatz-betragVorjahr"
                                     :label="$t('UboEingabeformularView.Label.BetragVorjahr')"
                                     unit="EUR"
                                     lazy
                                     v-model=betragVorjahr />

                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button data-qa="ubo-uebernehmen-umsatz" class="btn btn-default btn-min btn-small" :disabled="stepHasErrors"
                                @click.prevent="updateFormData">Änderungen übernehmen
                        </button>
                    </div>
                </div>
                <expertentipp :href="href"></expertentipp>
            </div>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="ubo-form-mode-view" v-if="!isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-bearbeiten-mitarbeiter" @click.prevent="open">
                        Bearbeiten
                        <i class="crefo-ui-icon icon-edit-pen" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Mit­arbeiter</div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('mitarbeiter-jahr') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Mitarbeiterangabe Jahr</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.mitarbeiterJahr }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('mitarbeiter-anzahlGesamt') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Gesamtanzahl Mitarbeiter</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.anzahlGesamt }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('mitarbeiter-anzahlGesamtVorjahr') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Mitarbeiter Vorjahr</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.anzahlGesamtVorjahr }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('mitarbeiter-anzahlInhaber') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label"><span v-if="inhabergefuehrt">Inhaber</span><span
                        v-else>Entscheider</span></div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.anzahlInhaber }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('mitarbeiter-anzahlAngestellte') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Angestellte</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.anzahlAngestellte }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('mitarbeiter-anzahlGewerblicheMitarbeiter') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Gewerbliche Mitarbeiter</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.anzahlGewerblicheMitarbeiter }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('mitarbeiter-anzahlAuszubildende') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Auszubildende</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.anzahlAuszubildende }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('mitarbeiter-anzahlTeilzeitkräfte') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Teilzeitkräfte</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.anzahlTeilzeitkräfte }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('mitarbeiter-anzahlAushilfen') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Aushilfen</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.anzahlAushilfen }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('mitarbeiter-anzahlFreieMitarbeiter') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Freie Mitarbeiter</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.anzahlFreieMitarbeiter }}</div>
                </div>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-schliessen-mitarbeiter" @click.prevent="close">
                        Schliessen
                        <i class="crefo-ui-icon icon-close" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Mit­arbeiter</div>
                <div class="row">
                    <div class="col-12">
                        <input-field rules="max_year|min_year"
                                     type="text"
                                     field="mitarbeiter-jahr"
                                     :label="$t('UboEingabeformularView.Label.MitarbeiterJahr')"
                                     v-model=mitarbeiterJahr />

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <input-field rules="max_value:999999"
                                     type="text"
                                     field="mitarbeiter-anzahlGesamt"
                                     :label="$t('UboEingabeformularView.Label.MitarbeiterAnzahlGesamt')"
                                     :tooltip="$t('UboEingabeformularView.Tooltip.MitarbeiterEmployeesCurrent')"
                                     v-model=anzahlGesamt />

                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field rules="max_value:999999"
                                     type="text"
                                     field="mitarbeiter-anzahlGesamtVorjahr"
                                     :label="$t('UboEingabeformularView.Label.MitarbeiterAnzahlGesamtVorjahr')"
                                     v-model=anzahlGesamtVorjahr />

                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-3">
                        <h4 class="ubo-form-header-label"><span v-if="inhabergefuehrt">Inhaber</span><span v-else>Entscheider</span>
                        </h4>
                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field rules="max_value:99999999999"
                                     type="text"
                                     field="mitarbeiter-anzahlInhaber"
                                     :label="$t('UboEingabeformularView.Label.MitarbeiterAnzahlInhaber')"
                                     v-model=anzahlInhaber />

                    </div>
                    <div class="col-12 col-lg-3">
                        <h4 class="ubo-form-header-label">Angestellte</h4>
                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field rules="max_value:99999999999"
                                     type="text"
                                     field="mitarbeiter-anzahlAngestellte"
                                     :label="$t('UboEingabeformularView.Label.MitarbeiterAnzahlAngestellte')"
                                     v-model=anzahlAngestellte />

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <h4 class="ubo-form-header-label">Gewerbliche Mitarbeiter</h4>
                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field rules="max_value:99999999999"
                                     type="text"
                                     field="mitarbeiter-anzahlGewerblicheMitarbeiter"
                                     :label="$t('UboEingabeformularView.Label.MitarbeiterAnzahlGewerblicheMitarbeiter')"
                                     v-model=anzahlGewerblicheMitarbeiter />

                    </div>
                    <div class="col-12 col-lg-3">
                        <h4 class="ubo-form-header-label">Auszubildende</h4>
                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field rules="max_value:99999999999"
                                     type="text"
                                     field="mitarbeiter-anzahlAuszubildende"
                                     :label="$t('UboEingabeformularView.Label.MitarbeiterAnzahlAuszubildende')"
                                     v-model=anzahlAuszubildende />

                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-3">
                        <h4 class="ubo-form-header-label">Teilzeitkräfte</h4>
                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field rules="max_value:99999999999"
                                     type="text"
                                     field="mitarbeiter-anzahlTeilzeitkräfte"
                                     :label="$t('UboEingabeformularView.Label.MitarbeiterAnzahlTeilzeitkräfte')"
                                     v-model=anzahlTeilzeitkräfte />

                    </div>
                    <div class="col-12 col-lg-3">
                        <h4 class="ubo-form-header-label">Aushilfen</h4>
                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field rules="max_value:99999999999"
                                     type="text"
                                     field="mitarbeiter-anzahlAushilfen"
                                     :label="$t('UboEingabeformularView.Label.MitarbeiterAnzahlAushilfen')"
                                     v-model=anzahlAushilfen />

                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-3">
                        <h4 class="ubo-form-header-label">Freie Mitarbeiter</h4>
                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field rules="max_value:99999999999"
                                     type="text"
                                     field="mitarbeiter-anzahlFreieMitarbeiter"
                                     :label="$t('UboEingabeformularView.Label.MitarbeiterAnzahlFreieMitarbeiter')"
                                     v-model=anzahlFreieMitarbeiter />

                    </div>
                </div>

                <div class="row">
                    <div class="col-12 mb">
                        <button data-qa="ubo-uebernehmen-mitarbeiter" class="btn btn-default btn-min btn-small" :disabled="stepHasErrors"
                                @click.prevent="updateFormData">Änderungen übernehmen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as formatter from '@/helper/formatter'
import * as mapper from '@/helper/mapper'
import UboInput from '@/components/commons/ubo-input'
import InputField from '@/components/commons/input-field'
import Expertentipp from '@/components/eingabeformular/expertentipp'
export default {
    name: 'Umsatzangaben',
    components: { UboInput, InputField, Expertentipp },
    props: {
        menuConfig: {},
        errors: {},
        crefonummer: null,
        inhabergefuehrt: null,
        fuellgrad: {}
    },
    data() {
        return {
            umsatz: {},
            mitarbeiter: {},
            vcAnsprechpartner: null,
            stepHasErrors: false
        }
    },
    computed: {
        renderEditMode() {
            return (this.menuConfig.edit && this.menuConfig.activeStep === 4)
                || this.$store.state.userModule.unternehmensdatenPreValidated === false
                || this.stepHasErrors
        },
        isEditModeActive() {
            return this.menuConfig.edit && this.menuConfig.activeStep === 4
        },
        hasErrors: function () {
            return fieldname => this.errors && this.errors[fieldname] instanceof Array && this.errors[fieldname].length
        },
        mitarbeiterJahr: {
            get() {
                let result = null
                if (this.mitarbeiter) {
                    result = formatter.formatNumber(this.mitarbeiter.jahr)
                }
                return result
            },
            set(newJahr) {
                this.mitarbeiter.jahr = formatter.parseNumber(newJahr)
            }
        },
        anzahlGesamt: {
            get() {
                let result = null
                if (this.mitarbeiter) {
                    result = formatter.formatNumber(this.mitarbeiter.anzahlGesamt)
                }
                return result
            },
            set(newAnzahlGesamt) {
                this.mitarbeiter.anzahlGesamt = formatter.parseNumber(newAnzahlGesamt)
            }
        },
        anzahlGesamtVorjahr: {
            get() {
                let result = null
                if (this.mitarbeiter) {
                    result = formatter.formatNumber(this.mitarbeiter.anzahlGesamtVorjahr)
                }
                return result
            },
            set(newAnzahlGesamtVorjahr) {
                this.mitarbeiter.anzahlGesamtVorjahr = formatter.parseNumber(newAnzahlGesamtVorjahr)
            }
        },
        anzahlInhaber: {
            get() {
                return this.getEmployeeType("7")
            },
            set(newValue) {
                if (this.mitarbeiter && this.mitarbeiter.mitarbeitertyp) {
                    this.setEmployeeType("7", newValue)
                }
            }
        },
        anzahlAngestellte: {
            get() {
                return this.getEmployeeType("2")
            },
            set(newValue) {
                if (this.mitarbeiter && this.mitarbeiter.mitarbeitertyp) {
                    this.setEmployeeType("2", newValue)
                }
            }
        },
        anzahlGewerblicheMitarbeiter: {
            get() {
                return this.getEmployeeType("1")
            },
            set(newValue) {
                if (this.mitarbeiter && this.mitarbeiter.mitarbeitertyp) {
                    this.setEmployeeType("1", newValue)
                }
            }
        },
        anzahlAuszubildende: {
            get() {
                return this.getEmployeeType("3")
            },
            set(newValue) {
                if (this.mitarbeiter && this.mitarbeiter.mitarbeitertyp) {
                    this.setEmployeeType("3", newValue)
                }
            }
        },
        anzahlTeilzeitkräfte: {
            get() {
                return this.getEmployeeType("4")
            },
            set(newValue) {
                if (this.mitarbeiter && this.mitarbeiter.mitarbeitertyp) {
                    this.setEmployeeType("4", newValue)
                }
            }
        },
        anzahlAushilfen: {
            get() {
                return this.getEmployeeType("5")
            },
            set(newValue) {
                if (this.mitarbeiter && this.mitarbeiter.mitarbeitertyp) {
                    this.setEmployeeType("5", newValue)
                }
            }
        },
        anzahlFreieMitarbeiter: {
            get() {
                return this.getEmployeeType("6")
            },

            set(newValue) {
                if (this.mitarbeiter && this.mitarbeiter.mitarbeitertyp) {
                    this.setEmployeeType("6", newValue)
                }
            }
        },
        umsatzJahr: {
            get() {
                let result = null
                if (this.umsatz) {
                    result = formatter.formatNumber(this.umsatz.jahr)
                }
                return result
            },
            set(newJahr) {
                this.umsatz = this.umsatzBuilder(newJahr, this.betrag, this.betragVorjahr, this.umsatzerwartung)
            }
        },
        betrag: {
            get() {
                let result = null
                if (this.umsatz && this.umsatz.betrag) {
                    result = formatter.formatAmount(this.umsatz.betrag)
                }
                return result
            },
            set(newBetrag) {
                this.umsatz = this.umsatzBuilder(this.umsatzJahr, newBetrag, this.betragVorjahr, this.umsatzerwartung)
            }
        },
        betragVorjahr: {
            get() {
                let result = null
                if (this.umsatz && this.umsatz.betragVorjahr) {
                    result = formatter.formatAmount(this.umsatz.betragVorjahr)
                }
                return result
            },
            set(newBetragVorjahr) {
                this.umsatz = this.umsatzBuilder(this.umsatzJahr, this.betrag, newBetragVorjahr, this.umsatzerwartung)
            }
        },
        umsatzerwartung: {
            get() {
                let result = null
                if (this.umsatz) {
                    result = this.umsatz.umsatzerwartung
                }
                return result
            },
            set(newUmsatzerwartung) {
                let umsatz = this.umsatzBuilder(this.umsatzJahr, this.betrag, this.betragVorjahr, newUmsatzerwartung)
                this.umsatz = umsatz
            }
        },
        href() {
            let eMail = null
            if (this.vcAnsprechpartner && this.vcAnsprechpartner.email && this.crefonummer) {
                eMail = "mailto:" + this.vcAnsprechpartner.email + "?subject=Dokumente%20zur%20Unternehmensbefragung%20Online%20" + this.crefonummer
            }
            return eMail
        },
    },
    methods: {
        getEmployeeType(mitarbeiterart) {
            let result = null
            if (this.mitarbeiter && this.mitarbeiter.mitarbeitertyp) {
                if (Array.isArray(this.mitarbeiter.mitarbeitertyp)) {
                    let mitarbeitertyp = this.mitarbeiter.mitarbeitertyp.find(x => x.mitarbeiterart === mitarbeiterart)
                    if (mitarbeitertyp) {
                        result = formatter.formatString(mitarbeitertyp.anzahl)
                    }
                }
            }
            return result
        },
        setEmployeeType(mitarbeiterart, newValue) {
            if (Array.isArray(this.mitarbeiter.mitarbeitertyp)) {
                let anzahl = formatter.parseNumber(newValue)
                if (anzahl === null) {
                    let mitarbeiterartIndex = this.mitarbeiter.mitarbeitertyp.findIndex(x => x.mitarbeiterart === mitarbeiterart)
                    this.mitarbeiter.mitarbeitertyp.splice(mitarbeiterartIndex, 1)
                } else {
                    let mitarbeitertyp = this.mitarbeiter.mitarbeitertyp.find(x => x.mitarbeiterart === mitarbeiterart)
                    if (mitarbeitertyp) {
                        mitarbeitertyp.anzahl = anzahl
                    } else {
                        this.mitarbeiter.mitarbeitertyp.push({mitarbeiterart, anzahl})
                    }
                }
            }
        },
        activeFormStep(step) {
            return this.menuConfig.activeStep === step
        },
        activeStep(step) {
            let result = null
            if (this.menuConfig.activeStep === step) {
                result = 'ubo-step--active'
            }
            return result
        },
        open() {
            this.restoreFormData()
            this.$emit("edit-step", 4)
        },
        close() {
            this.$emit('select-step', 4)
        },
        restoreFormData() {
            try {
                this.vcAnsprechpartner = this.$store.state.userModule.unternehmensdaten.vcAnsprechpartner
                this.umsatz = JSON.parse(JSON.stringify(this.$store.state.userModule.unternehmensdaten.umsatz))
                this.mitarbeiter = JSON.parse(JSON.stringify(this.$store.state.userModule.unternehmensdaten.mitarbeiter))
                if (this.mitarbeiter) {
                    this.mitarbeiter.mitarbeitertyp = mapper.reduceEmployeeType(this.mitarbeiter.mitarbeitertyp)
                }
            } catch (e) {}
        },
        updateFormData() {
            this.$store.commit('userModule/ON_CHANGE_TURNOVER', this.umsatz)
            this.$store.commit('userModule/ON_CHANGE_BUSINESS_EMPLOYEES_DATA', this.mitarbeiter)
            this.$emit('save-step')
            this.$emit("select-step", 4)
        },
        umsatzBuilder(jahr, betrag, betragVorjahr, umsatzerwartung) {
            const umsatz = {
                jahr: formatter.parseNumber(jahr),
                betrag: formatter.parseAmount(betrag),
                betragVorjahr: formatter.parseAmount(betragVorjahr),
                umsatzerwartung
            }
            return umsatz
        }
    },
    inject: {
        editEventBus: "editEventBus",
        formatter: "formatter"
    },
    created: function () {
        this.editEventBus.$on('has-errors-step-4', (hasErrors) => {
            this.stepHasErrors = hasErrors
        })
        this.restoreFormData()
    }
}
</script>

<!-- Formularkomponenten -->
<style scoped lang="less">

.ubo-form-header-label {
    margin-top: 50px;
}

.ubo-form-chapter {
    display: none;
    position: relative;
    border-bottom: 1px solid #edece5;

    &:last-child {
        padding-bottom: 30px !important;

        @media only screen and (min-width: 1200px) {
            padding-bottom: 40px !important;
            border-bottom: 0 none transparent;
        }
    }

    .h2 {
        padding-right: 140px;
    }

    .ubo-form-edit {
        position: absolute;
        top: 33px;
        right: 17px;
        width: 130px;
        height: 20px;
        font-weight: 600;
        text-align: right;

        @media only screen and (max-width: 1199px) {
            top: 23px;
        }
        @media only screen and (max-width: 767px) {
            top: 18px;
        }

        a {
            text-transform: uppercase;

            i {
                position: relative;
                top: 5px;
            }
        }

    }

    .ubo-form-display {
        align-items: flex-end;

        &:last-child {
            margin-bottom: 30px;
        }

        .ubo-form-display-label, .ubo-form-display-data {
            padding-top: 30px;
            padding-bottom: 15px;
            border-bottom: 1px solid #edece5;

            @media only screen and (max-width: 991px) {
                border-bottom: 0 none transparent;
            }
        }

        .ubo-form-display-label {
            padding-right: 15px;

            @media only screen and (max-width: 991px) {
                padding-bottom: 5px;
            }
        }

        .ubo-form-display-data {
            font-weight: 600;

            .crefo-brand-icon {
                color: #b5b5b5;
                font-size: 12px;
            }

            @media only screen and (max-width: 991px) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    .ubo-form-mode-edit {
        .ubo-input-wrapper-label {
            margin-bottom: -2px;

            strong {
                font-size: 20px;
                color: #004884;
            }
        }

        .crefo-input {
            margin-bottom: 0;

            .input-group {
                margin-bottom: 10px;

                input {
                    &::placeholder {
                        color: #b5b5b5;
                    }
                }
            }

        }
    }
}

.ubo-form-step {
    &--active {
        .ubo-form-chapter {
            display: block;
        }
    }
}
</style>
