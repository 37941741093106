<template>
    <modal-dialog
        v-if="showModal"
        confirmLabel="Änderungen verwerfen"
        cancelLabel="Abbrechen"
        :confirm-callback="confirm"
        :cancel-callback="cancel"
    >
        <div class="row">
            <div class="col-12">
                <p>
                    <strong>Sie haben ungesicherte Änderungen!</strong>
                </p>
                <p>
                    Wenn Sie das Formular nun verlassen, gehen diese Änderungen verloren.<br>
                    Ihre Daten werden erst durch Klick auf <strong>"Daten senden"</strong> übermittelt.
                </p>
            </div>
        </div>
    </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/helper/modal-dialog'
export default {
    name: 'WarnungUngesicherteAenderungen',
    components: { ModalDialog },
    props: {
        showModal: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        confirm() {
            this.$store.commit('userModule/ON_SET_UNTERNEHMENSDATEN_CHANGED_IGNORE', true)
            this.$emit('ignore-unsaved-changes', true)
        },
        cancel() {
            this.$store.commit('userModule/ON_SET_UNTERNEHMENSDATEN_CHANGED_IGNORE', false)
            this.$emit('ignore-unsaved-changes', false)
        }
    }
}
</script>
