<template>
    <div class="row mb-large">
        <div class="col-12 col-lg-8">
            <h2>{{ $t('Landingpage.CompanyNotFound.Headline') }}</h2>
            <p>{{ $t('Landingpage.CompanyNotFound.Description.1') }}</p>
            <p>{{ $t('Landingpage.CompanyNotFound.Description.2') }}</p>
            <p>
                <a href="https://www.creditreform.de/creditreform/kontakt?preselect_subject=ubo">
                    <i
                        class="crefo-ui-icon icon-beratung"
                        style="position: relative; top: 5px;"
                        aria-hidden="true"
                    />
                    {{ $t('Landingpage.CompanyNotFound.ContactLink') }}
                </a>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UboLandingpageUnternehmenNichtGefunden',
    }
</script>
