<template>
    <div class="crefo-input pt">
        <div class="input-group">
            <div class="radio-group">
                <div class="input-wrapper">
                    <ValidationProvider
                        v-slot="{ changed }">
                        <div class="crefo-radio">
                            <div class="radio-btn">
                                <slot name="true"></slot>
                                <label>
                                    <span class="radio-box"><span class="radio-box-circle"></span></span>
                                    <span class="radio-label--ubo" :class="{'has-changed':changed&&value==='true'}"> {{labelTrue}} </span>
                                </label>
                            </div>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider
                        v-slot="{ changed }">
                        <div class="crefo-radio">
                            <div class="radio-btn">
                                <slot name="false"></slot>
                                <label>
                                    <span class="radio-box"><span class="radio-box-circle"></span></span>
                                    <span class="radio-label--ubo" :class="{'has-changed':changed&&value==='false'}"> {{ labelFalse }} </span>
                                </label>
                            </div>
                        </div>
                    </ValidationProvider>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {ValidationProvider} from 'vee-validate'

/**
 * rechtsform
 *
 * importExport-import
 * importExport-export
 */

export default {
    components: {ValidationProvider},
    name: 'UboRadio',
    props: {
        field: null,
        isValid: false,
        isEmpty: false,
        dataQa: {
            type: String,
            required: false,
            default: 'error-message'
        }
    },
    computed: {
        validationProviderName() {
            return null
        },
        labelTrue() {
            if (this.field === 'rechtsform') {
                return 'gewerblich registriert'
            }else if (this.field === 'importExport-export') {
                return 'Ja'
            }
        },
        labelFalse() {
            if (this.field === 'rechtsform') {
                return 'freiberuflich tätig'
            }else if (this.field === 'importExport-export') {
                return 'Nein'
            }
        },
        rules() {
            return null
        }
    }
}
</script>

<style scoped lang="less">


</style>
