import HelperWebtrekk from "@dm/helper-webtrekk";

/**
 * Should be called, when the keycloak token was initially set.
 *
 * @param {UserState} state
 * @param {Object} data
 * @param {?string} data.token
 * @param {Object} data.idTokenParsed
 * @param {?string} data.idTokenParsed.email
 * @param {string} data.idTokenParsed.given_name
 * @param {string} data.idTokenParsed.family_name
 * @param {Array<string>} data.idTokenParsed.cr_userrole
 * @param {?string} data.idTokenParsed.cr_id
 * @param {?string} data.idTokenParsed.cr_membernumber
 */
export function ON_VALID_TOKEN_EXISTS (state, data) {
    state.authenticated = true
    state.token = data.token
    state.user.email = data.idTokenParsed.email
    state.user.givenName = data.idTokenParsed.given_name
    state.user.surname = data.idTokenParsed.family_name
    state.user.user_roles = data.idTokenParsed.cr_userrole
    state.user.cr_id = data.idTokenParsed.cr_id
    state.user.cr_membernumber = data.idTokenParsed.cr_membernumber
}

/**
 * Should be called, when the keycloak token was updated.
 *
 * Currently there is no real difference to ON_VALID_TOKEN_EXISTS.
 * Its only there, if it is needed to differentiate between token updates and the initial login.
 *
 * @param {UserState} state
 * @param {Object} data
 * @param {?string} data.token
 * @param {Object} data.idTokenParsed
 * @param {?string} data.idTokenParsed.email
 * @param {string} data.idTokenParsed.given_name
 * @param {string} data.idTokenParsed.family_name
 * @param {Array<string>} data.idTokenParsed.cr_userrole
 * @param {?string} data.idTokenParsed.cr_membernumber
 */
export function ON_KEYCLOAK_TOKEN_UPDATE_SUCCESS (state, data) {
    state.authenticated = true
    state.token = data.token
    state.user.email = data.idTokenParsed.email
    state.user.givenName = data.idTokenParsed.given_name
    state.user.surname = data.idTokenParsed.family_name
    state.user.user_roles = data.idTokenParsed.cr_userrole
    state.user.cr_id = data.idTokenParsed.cr_id
    state.user.cr_membernumber = data.idTokenParsed.cr_membernumber
}

export function ON_SET_USER_STATE_DETERMINED (state, isDetermined) {
    state.isUserStateDetermined = isDetermined
}

export function ON_SET_USER_STATE (state, userState) {
    state.userState = userState
}

export function ON_SET_DATE_LOCALE (state, dateLocale) {
    state.dateLocale = dateLocale
}

export function ON_SET_LANGUAGE (state, language) {
    state.language = language
}

/**
 * Should be called, when unternehmensdaten is called.
 *
 * @param {UserState} state
 * @param {Object} data
 *
 */
export function ACTIVATE_UBO_FETCHING (state) {
    state.unternehmensdatenFetching = true
    state.activated = false
}

export function SET_REQUIRE_PRE_VALIDATING (state, requirePreValidation) {
    state.unternehmensdatenPreValidated = !requirePreValidation
}

/**
 * Should be called, when unternehmensdaten returns success.
 *
 * @param {UserState} state
 * @param {Object} response
 */
export function ACTIVATE_UBO_SUCCESS (state, response) {
    state.unternehmensdatenFetching = false
    if (response.headers) {
        if ("true" === response.headers.uboconsentrecherche) {
            state.consentRecherche = true
        }
        if ("false" === response.headers.uboconsentrecherche) {
            state.consentRecherche = false
        }
    }
    state.activated = true
    state.dokumenteAnmerkungen = null
    if (response.data) {
        state.dokumente = []
        if (response.data.kopfdaten) {
            state.unternehmensdaten.kopfdaten.clz = response.data.kopfdaten.clz
        }
        if (response.data.firmenidentifikation) {
            state.unternehmensdaten.firmenidentifikation.firmierung = response.data.firmenidentifikation.firmierung
            if (response.data.firmenidentifikation.adressdaten) {
                Object.assign(state.unternehmensdaten.firmenidentifikation.adressdaten, response.data.firmenidentifikation.adressdaten)
            }
            if (response.data.firmenidentifikation.telefonnummer) {
                state.unternehmensdaten.firmenidentifikation.telefonnummer = {}
                Object.assign(state.unternehmensdaten.firmenidentifikation.telefonnummer, response.data.firmenidentifikation.telefonnummer)
            }
            if (response.data.firmenidentifikation.mobilnummer) {
                state.unternehmensdaten.firmenidentifikation.mobilnummer = {}
                Object.assign(state.unternehmensdaten.firmenidentifikation.mobilnummer, response.data.firmenidentifikation.mobilnummer)
            }
            if (response.data.firmenidentifikation.faxnummer) {
                state.unternehmensdaten.firmenidentifikation.faxnummer = {}
                Object.assign(state.unternehmensdaten.firmenidentifikation.faxnummer, response.data.firmenidentifikation.faxnummer)
            }
            state.unternehmensdaten.firmenidentifikation.emailAdresse = response.data.firmenidentifikation.emailAdresse
            state.unternehmensdaten.firmenidentifikation.internetAdresse = response.data.firmenidentifikation.internetAdresse
            state.unternehmensdaten.firmenidentifikation.umsatzsteuerIdentnummer = response.data.firmenidentifikation.umsatzsteuerIdentnummer
            state.unternehmensdaten.firmenidentifikation.steuernummer = response.data.firmenidentifikation.steuernummer
        }
        if (response.data.rechtsform) {
            Object.assign(state.unternehmensdaten.rechtsform, response.data.rechtsform)
        }
        if (response.data.inhaber) {
            Object.assign(state.unternehmensdaten.inhaber, response.data.inhaber)
        }
        if (response.data.immobilien) {
            Object.assign(state.unternehmensdaten.immobilien, response.data.immobilien)
        }
        if (response.data.geschaeftstaetigkeit) {
            Object.assign(state.unternehmensdaten.geschaeftstaetigkeit, response.data.geschaeftstaetigkeit)
        }
        if (response.data.umsatz) {
            Object.assign(state.unternehmensdaten.umsatz, response.data.umsatz)
        }
        if (response.data.mitarbeiter) {
            Object.assign(state.unternehmensdaten.mitarbeiter, response.data.mitarbeiter)
        }
        if (response.data.importExport) {
            Object.assign(state.unternehmensdaten.importExport, response.data.importExport)
        }
        if (response.data.banken) {
            Object.assign(state.unternehmensdaten.banken, response.data.banken)
        }
        if (response.data.ansprechpartner) {
            Object.assign(state.unternehmensdaten.ansprechpartner, response.data.ansprechpartner)
        }
        if (response.data.vcAnsprechpartner) {
            Object.assign(state.unternehmensdaten.vcAnsprechpartner, response.data.vcAnsprechpartner)
        }
        state.unternehmensdatenPreValidated = false
    }
    state.ajaxError = null
    try {
        state.unternehmensdatenOriginalAsString = JSON.stringify(state.unternehmensdaten)
        state.unternehmensdatenChangedIgnore = false
    } catch (e) {
        state.unternehmensdatenOriginalAsString = ''
        state.unternehmensdatenChangedIgnore = true
    }
}


/**
 * Should be called, when unternehmensdaten returns an error response.
 *
 * @param {UserState} state
 * @param {Object} error
 */

export function ACTIVATE_UBO_FAILED (state, error) {
    state.unternehmensdatenFetching = false
    state.ajaxError = error
    state.activated = false
    state.unternehmensdatenOriginalAsString = ''
    state.unternehmensdatenChangedIgnore = true
}

/**
 * Should be called, when updateBusinessdata is called.
 *
 * @param {UserState} state
 *
 */

export function UPDATE_UBO_SENDING (state) {
    state.unternehmensdatenSending = true
}

/**
 * Should be called, when updateBusinessdata returns success.
 *
 * @param {UserState} state
 *
 */

export function UPDATE_UBO_SUCCESS (state) {
    state.unternehmensdatenSending = false
    state.ajaxError = null
    try {
        state.unternehmensdatenOriginalAsString = JSON.stringify(state.unternehmensdaten)
        state.unternehmensdatenChangedIgnore = false
    } catch (e) {}
}

export function DEACTIVATE_UBO (state) {
    state.activated = false
}


/**
 * Should be called, when updateBusinessdata returns an error response.
 *
 * @param {UserState} state
 * @param {Object} error
 */

export function UPDATE_UBO_FAILED (state, error) {
    state.unternehmensdatenSending = false
    state.ajaxError = error
    state.unternehmensdatenChangedIgnore = true
}

/**
 * Set true to block warning on unsaved changes.
 *
 * @param {UserState} state
 * @param {boolean} ignore
 */
export function ON_SET_UNTERNEHMENSDATEN_CHANGED_IGNORE (state, ignore) {
    state.unternehmensdatenChangedIgnore = ignore
}

/**
 * Should be called to set the current consentRecherche.
 *
 * @param {UserState} state
 * @param {Boolean} data
 *
 */
export function ON_CHANGE_CONSENT_RECHERCHE (state, data) {
    state.consentRecherche = data
}

/**
 * Should be called to set the current crefonumber.
 *
 * @param {UserState} state
 * @param {Number} data
 *
 */
export function ON_CHANGE_CREFONUMBER (state, data) {
    state.crefonummer = data
}

/**
 * Should be called to set the current active umsatz.
 *
 * @param {UserState} state
 * @param {umsatz} data
 *
 */
export function ON_CHANGE_TURNOVER (state, data) {
    if (state.unternehmensdaten) {
        Object.assign(state.unternehmensdaten.umsatz, data)
        state.unternehmensdatenChangedIgnore = false
    }
    trackMutationAction('umsatz', 'ubo_action_ON_CHANGE_TURNOVER', state)
}

/**
 * Should be called to set the current mitarbeiter umsatz.
 *
 * @param {UserState} state
 * @param {mitarbeiter} data
 *
 */
export function ON_CHANGE_BUSINESS_EMPLOYEES_DATA (state, data) {
    if (state.unternehmensdaten) {
        Object.assign(state.unternehmensdaten.mitarbeiter, data)
        state.unternehmensdatenChangedIgnore = false
    }
    trackMutationAction('mitarbeiter', 'ubo_action_ON_CHANGE_BUSINESS_EMPLOYEES_DATA', state)
}

/**
 * Should be called to set the current active firmenidentifikation.
 *
 * @param {UserState} state
 * @param {firmenidentifikation} data
 *
 */
export function ON_CHANGE_COMPANY_IDENTIFICATION (state, data) {
    if (state.unternehmensdaten) {
        Object.assign(state.unternehmensdaten.firmenidentifikation, data)
        state.unternehmensdatenChangedIgnore = false
    }
    trackMutationAction('firmenidentifikation', 'ubo_action_ON_CHANGE_COMPANY_IDENTIFICATION', state)
}

/**
 * Should be called to set the current active rechtsform.
 *
 * @param {UserState} state
 * @param {rechtsform} data
 *
 */
export function ON_CHANGE_LEGAL_FORM_INFORMATION (state, data) {
    if (state.unternehmensdaten) {
        Object.assign(state.unternehmensdaten.rechtsform, data)
        state.unternehmensdatenChangedIgnore = false
    }
    trackMutationAction('rechtsform', 'ubo_action_ON_CHANGE_LEGAL_FORM_INFORMATION', state)
}

/**
 * Should be called to set the current active inhaber.
 *
 * @param {UserState} state
 * @param {Inhaber} data
 *
 */
export function ON_CHANGE_OWNER (state, data) {
    if (state.unternehmensdaten) {
        Object.assign(state.unternehmensdaten.inhaber, data)
        state.unternehmensdatenChangedIgnore = false
    }
    trackMutationAction('inhaber', 'ubo_action_ON_CHANGE_OWNER', state)
}

/**
 * Should be called to set the current active geschaeftstaetigkeit.
 *
 * @param {UserState} state
 * @param {geschaeftstaetigkeit} data
 *
 */
export function ON_CHANGE_BUSINESS_ACTIVITY (state, data) {
    if (state.unternehmensdaten) {
        Object.assign(state.unternehmensdaten.geschaeftstaetigkeit, data)
        state.unternehmensdatenChangedIgnore = false
    }
    trackMutationAction('geschaeftstaetigkeit', 'ubo_action_ON_CHANGE_BUSINESS_ACTIVITY', state)
}

/**
 * Should be called to set the current active ansprechpartner
 *
 * @param {UserState} state
 * @param {ansprechpartner} data
 *
 */
export function ON_CHANGE_CONTACT_PERSON (state, data) {
    if (state.unternehmensdaten) {
        Object.assign(state.unternehmensdaten.ansprechpartner, data)
        state.unternehmensdatenChangedIgnore = false
    }
    trackMutationAction('ansprechpartner', 'ubo_action_ON_CHANGE_CONTACT_PERSON', state)
}

/**
 * Should be called to set the current active ansprechpartner
 *
 * @param {UserState} state
 * @param {String} data
 *
 */
export function ON_CHANGE_NOTES (state, data) {
    if (state.unternehmensdaten) {
        state.unternehmensdaten.anmerkungen = data
        state.unternehmensdatenChangedIgnore = false
    }
    trackMutationAction('anmerkungen', 'ubo_action_ON_CHANGE_NOTES', state)
}

/**
 * Should be called to set the current active immobilien
 *
 * @param {UserState} state
 * @param {immobilien} data
 *
 */
export function ON_CHANGE_REAL_ESTATE (state, data) {
    if (state.unternehmensdaten) {
        state.unternehmensdaten.immobilien = data
        state.unternehmensdatenChangedIgnore = false
    }
    trackMutationAction('immobilien', 'ubo_action_ON_CHANGE_REAL_ESTATE', state)
}

/**
 * Should be called to set the current active importExport
 *
 * @param {UserState} state
 * @param {importExport} data
 *
 */
export function ON_CHANGE_IMPORT_EXPORT (state, data) {
    if (state.unternehmensdaten) {
        state.unternehmensdaten.importExport = data
        state.unternehmensdatenChangedIgnore = false
    }
    trackMutationAction('importExport', 'ubo_action_ON_CHANGE_IMPORT_EXPORT', state)
}

/**
 * Should be called to set the current active importExport
 *
 * @param {UserState} state
 * @param {banken} data
 *
 */
export function ON_CHANGE_BANKEN (state, data) {
    if (state.unternehmensdaten) {
        state.unternehmensdaten.banken = data
        state.unternehmensdatenChangedIgnore = false
    }
    trackMutationAction('banken', 'ubo_action_ON_CHANGE_BANKEN', state)
}

/**
 * Anhänge die mit den Unternehmensdaten mit gesesendet werden sollen.
 *
 * @param {UserState} state
 * @param {Array<File>} dokumente
 */
export function ON_CHANGE_DOKUMENTE (state, dokumente) {
    if (dokumente instanceof Array) {
        state.dokumente = dokumente
    } else {
        state.dokumente = []
    }
    state.unternehmensdatenChangedIgnore = false
    trackMutationAction('dokumente', 'ubo_action_ON_CHANGE_DOKUMENTE', state)
}

/**
 * Anhänge die mit den Unternehmensdaten mit gesesendet werden sollen.
 *
 * @param {UserState} state
 * @param {string} dokumenteAnmerkungen
 */
export function ON_CHANGE_DOKUMENTE_ANMERKUNGEN (state, dokumenteAnmerkungen) {
    if (typeof dokumenteAnmerkungen === 'string') {
        state.dokumenteAnmerkungen = dokumenteAnmerkungen
    } else {
        state.dokumenteAnmerkungen = ''
    }
    state.unternehmensdatenChangedIgnore = false
    trackMutationAction('dokumenteAnmerkungen', 'ubo_action_ON_CHANGE_DOKUMENTE_ANMERKUNGEN', state)
}

/**
 * @param {String} chapter
 * @param {UserState} state
 *
 */
function trackMutationAction (chapter, name, state) {
    let properties = {
        8: getDescriptionFromChapterCode(chapter),
        4: getClzFromUserState(state),
        12: getCrefoNumberFromState(state),
        10: 2,
        5: "OK",
        6: "UBO",
    }
    HelperWebtrekk.trackAction(name, properties)
}

/**
 * @param {String} chapter
 *
 */

function getDescriptionFromChapterCode (chapter) {

    let result = ''
    if (chapter === 'firmenidentifikation') {
        result = 'Firmenidentifikation übernommen'
    } else if (chapter === 'rechtsform') {
        result = 'Rechtsform übernommen'
    } else if (chapter === 'inhaber') {
        result = 'Inhaber übernommen'
    } else if (chapter === 'umsatz') {
        result = 'Umsatz übernommen'
    } else if (chapter === 'mitarbeiter') {
        result = 'Mitarbeiter übernommen'
    } else if (chapter === 'immobilien') {
        result = 'Immobilien übernommen'
    } else if (chapter === 'geschaeftstaetigkeit') {
        result = 'Geschäftstaetigkeit übernommen'
    } else if (chapter === 'importExport') {
        result = 'Import/Export übernommen'
    } else if (chapter === 'banken') {
        result = 'Banken übernommen'
    } else if (chapter === 'ansprechpartner') {
        result = 'Ansprechpartner übernommen'
    } else if (chapter === 'anmerkungen') {
        result = 'Anmerkungen übernommen'
    } else if (chapter === 'dokumente') {
        result = 'Dokumente übernommen'
    } else if (chapter === 'dokumenteAnmerkungen') {
        result = 'Anmerkungen zu Dokumenten übernommen'
    }
    return result
}

/**
 * @param {UserState} state
 *
 */

function getClzFromUserState (state) {
    let clz = ''
    if (state && state.unternehmensdaten && state.unternehmensdaten.kopfdaten && state.unternehmensdaten.kopfdaten.clz) {
        clz = state.unternehmensdaten.kopfdaten.clz
    }
    return clz
}

/**
 * @param {UserState} state
 *
 */

function getCrefoNumberFromState (state) {
    let result = ''
    if (state && state.crefonummer) {
        result = state.crefonummer
    }
    return result
}
