<template>
    <date-picker
        class="crefo-datepicker"
        :lang="lang"
        :format="dateFormat"
        :title-format="dateTitleFormat"
        :time-title-format="dateTimeTitleFormat"
        :range-separator="dateRangeSeparator"
        v-bind="$attrs"
        v-on="$listeners"
    ></date-picker>
</template>

<script>
    /**
     * Displays a datepicker.
     *
     * Example usage:
     * <input-date
     *     type="date"
     *     v-model="data"
     *     format="DD.MM.YYYY"
     *     :disabledDate="notAllowed"
     * ></input-date>
     *
     * methods: {
     *     notAllowed (date) {
     *         return Date.now() - date.getTime() < 0
     *     }
     * }
     *
     * @see https://www.npmjs.com/package/vue2-datepicker
     */
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    export default {
        name: 'InputDate',
        components: { DatePicker },
        data () {
            return {
                formatOfDate: 'DD.MM.YYYY',
                formatOfTime: 'HH:mm:ss',
                formatOfDateTime: 'DD.MM.YYYY HH:mm:ss',
                formatOfWeek: 'DD.MM.YYYY (ww)',
                formatOfRangeSeparator: ' - ',
                lang: (() => {
                    let lang
                    switch (this.$store.state.userModule.dateLocale) {
                        case 'en':
                            lang = {
                                formatLocale: {
                                    months: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
                                    monthsShort: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
                                    weekdays: [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ],
                                    weekdaysShort: [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ],
                                    weekdaysMin: [ 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa' ],
                                    firstDayOfWeek: 0,
                                    firstWeekContainsDate: 1
                                },
                                yearFormat: 'YYYY',
                                monthFormat: 'MMM',
                                monthBeforeYear: 'true'
                            }
                            this.formatOfDate = 'MM/DD/YYYY'
                            this.formatOfTime = 'h:mm:ss A'
                            this.formatOfDateTime = 'MM/DD/YYYY h:mm:ss A'
                            this.formatOfWeek = 'MM/DD/YYYY (ww)'
                            this.formatOfRangeSeparator = ' ~ '
                            break
                        case 'de-at':
                            lang = {
                                formatLocale: {
                                    months: [ 'Jänner', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ],
                                    monthsShort: [ 'Jän', 'Feb', 'März', 'Apr', 'Mai', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez' ],
                                    weekdays: [ 'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag' ],
                                    weekdaysShort: [ 'So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.' ],
                                    weekdaysMin: [ 'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa' ],
                                    firstDayOfWeek: 1,
                                    firstWeekContainsDate: 4
                                },
                                yearFormat: 'YYYY',
                                monthFormat: 'MMM',
                                monthBeforeYear: 'true'
                            }
                            break
                        case 'de':
                        default:
                            lang = {
                                formatLocale: {
                                    months: [ 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ],
                                    monthsShort: [ 'Jan', 'Feb', 'März', 'Apr', 'Mai', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez' ],
                                    weekdays: [ 'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag' ],
                                    weekdaysShort: [ 'So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.' ],
                                    weekdaysMin: [ 'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa' ],
                                    firstDayOfWeek: 1,
                                    firstWeekContainsDate: 4
                                },
                                yearFormat: 'YYYY',
                                monthFormat: 'MMM',
                                monthBeforeYear: 'true'
                            }
                            break
                    }

                    return lang
                })()
            }
        },
        computed: {
            dateFormat () {
                if (this.$attrs && typeof this.$attrs.format === 'string') {
                    return this.$attrs.format
                }

                let type = 'date'
                if (this.$attrs && typeof this.$attrs.type === 'string') {
                    type = this.$attrs.type
                }
                switch (type) {
                    case 'datetime':
                        return this.formatOfDateTime
                        break
                    case 'time':
                        return this.formatOfTime
                        break
                    case 'week':
                        return this.formatOfWeek
                        break
                    default:
                        return this.formatOfDate
                }
            },
            dateTitleFormat () {
                if (this.$attrs && typeof this.$attrs['title-format'] === 'string') {
                    return this.$attrs['title-format']
                }
                return this.dateFormat
            },
            dateTimeTitleFormat () {
                if (this.$attrs && typeof this.$attrs['time-title-format'] === 'string') {
                    return this.$attrs['time-title-format']
                }
                return this.dateFormat
            },
            dateRangeSeparator () {
                if (this.$attrs && typeof this.$attrs['range-separator'] === 'string') {
                    return this.$attrs['range-separator']
                }

                return this.formatOfRangeSeparator
            }
        }
    }
</script>

<style lang="less">
    .crefo-datepicker {
        width: 100%;

        .mx-input-wrapper {
            color: #7f7f7f;
            border-width: 1px;
            border-color: #b5b5b5;
            border-style: solid;
            flex: 1 1 auto;
            position: relative;
            transition: border-color 0.15s ease-in-out;

            input.mx-input {
                flex: 1 1 auto;
                font-size: 15px;
                font-weight: normal;
                line-height: 26px;
                padding: 1rem 3.2rem 1rem 1.2rem;
                outline: none;
                height: auto;
                max-width: 100%;
                min-width: 0;
                width: 100%;
                background-color: transparent;
                border-style: none;
                color: #4c4c4c;
                border-color: #b5b5b5;
                background: #ffffff;
                box-shadow: none;
            }

            .mx-icon-calendar, .mx-icon-clear {
                right: 1.2rem;
                color: #7f7f7f;
            }

            &:hover {
                border-color: #009ee2;

                .mx-icon-calendar, .mx-icon-clear {
                    color: #7f7f7f;
                }
            }
        }

        &.disabled {
            .mx-input-wrapper {
                &:hover {
                    border-color: #b5b5b5;
                }
            }
        }
    }

    .mx-datepicker-popup {
        margin-top: -1px;
        box-shadow: none !important;
    }

    .mx-datepicker-main {
        border-width: 1px;
        border-color: #b5b5b5;
        border-style: solid;
        transition: border-color 0.15s ease-in-out;
        color: #4c4c4c !important;
        font-size: 15px !important;
        line-height: 26px !important;
        font-family: 'Open Sans', Arial, Helvetica, sans-serif !important;

        &:hover {
            border-color: #009ee2;
        }

        .mx-btn {
            color: #ffffff;
            background-color: #009ee2;
            line-height: 56px;
            font-size: 15px;
            font-weight: 600;
            padding: 0 13.5px;
            margin: 0;
            border: none;
            border-radius: 0;
            white-space: normal;

            &:hover {
                background-color: #006eb7;
            }
        }

        .mx-datepicker-body {
            .mx-time {
                width: 360px;

                .mx-time-header {
                    height: 70px;
                    padding-top: 6px;
                    border-bottom-color: #b5b5b5;

                    .mx-btn {
                        text-align: center;
                    }
                }

                .mx-time-content {
                    height: 239px;

                    .mx-time-column {
                        border-left-color: #b5b5b5;

                        li {
                            padding: 8px 0;
                            height: 31px;
                        }

                        .mx-time-item {
                            font-size: 15px;
                            line-height: 15px;

                            &.active,
                            &:hover {
                                background-color: transparent;
                                color: #009ee2;
                            }

                            &.active {
                                font-weight: 700;
                            }
                        }
                    }
                }
            }

            .mx-calendar {
                width: 360px;
                padding: 15px 18px;

                &+.mx-calendar {
                    border-left-color: #b5b5b5;
                }

                .mx-calendar-header {
                    margin-bottom: 15px;

                    @media only screen and (max-width: 1199px) {
                        margin-bottom: 10px;
                    }

                    @media only screen and (max-width: 768px) {
                        margin-bottom: 7.5px;
                    }

                    .mx-btn {
                        min-width: 32px;
                        min-height: 32px;
                        color: #4c4c4c;
                        background-color: transparent;
                        line-height: 32px;
                        font-weight: normal;
                        white-space: nowrap;

                        &:hover {
                            color: #009ee2;
                        }
                    }

                    .mx-calendar-header-label {
                        font-size: 15px;
                    }
                }

                .mx-calendar-content {
                    height: 230px;

                    .mx-table th, td {
                        font-size: 15px;
                        line-height: 15px;
                        font-weight: normal;
                        text-align: center;
                        transition: color 0.1s ease-in-out;
                    }

                    .mx-table th {
                        font-weight: 600;
                    }

                    .mx-week-number {
                        opacity: .6;
                    }

                    .mx-table-date {
                        .today {
                            color: #009ee2;
                        }
                    }

                    .mx-table-date,
                    .mx-table-month,
                    .mx-table-year {
                        .cell {
                            &.active,
                            &:hover {
                                background-color: transparent;
                                color: #009ee2;
                            }

                            &.active {
                                font-weight: 700;
                            }
                        }
                    }

                    .mx-table-year {
                        padding: 0 30px;
                    }

                    .not-current-month {
                        color: #b5b5b5;

                        &:hover {
                            color: #b5b5b5;
                        }
                    }

                    .cell {
                        &:hover {
                            background-color: transparent;
                            color: #009ee2;
                        }

                        &.disabled {
                            color: #ffffff;
                            background-color: #b5b5b5;

                            &:hover {
                                color: #ffffff;
                                background-color: #b5b5b5;
                            }
                        }
                    }
                }

                &.mx-calendar-week-mode {
                    .mx-date-row {
                        transition: background-color 0.1s ease-in-out;

                        &:hover {
                            background-color: rgba(0, 157, 224, 0.05);

                            td {
                                color: #009ee2;
                            }
                        }
                    }
                }
            }
        }

        .mx-datepicker-footer {
            text-align: center;
            border-top-color: #b5b5b5;
        }
    }
</style>
