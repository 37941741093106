<template>
    <div>
        <ul>
            <li
                class="ubo-step box-spacing"
                :class="{ 'ubo-step--active': isStepActive(1), 'has-errors': hasErrors(1) }"
            >
                <div class="ubo-step-number">
                    <span>1</span>
                </div>
                <div
                    class="ubo-step-name"
                    data-qa="menue-firmenidentifikation"
                    @click.prevent="$emit('select-step', 1)"
                >
                    Firmenidentifikation
                </div>
                <div
                    class="ubo-step-progress"
                    :class="{ 'ubo-step-progress--complete': isStepComplete(1) }"
                >
                    <span class="text-small">{{ fuellgrad.anteilFirmenidentifikation }}</span>
                </div>
            </li>
            <li
                class="ubo-step box-spacing"
                :class="{ 'ubo-step--active': isStepActive(2), 'has-errors': hasErrors(2) }"
            >
                <div class="ubo-step-number">
                    <span>2</span>
                </div>
                <div
                    class="ubo-step-name"
                    data-qa="menue-inhaber"
                    @click.prevent="$emit('select-step', 2)"
                >
                    <span v-if="inhabergefuehrt">
                        Inhaber
                    </span>
                    <span v-else>
                        Entscheider
                    </span>
                </div>
                <div
                    class="ubo-step-progress"
                    :class="{ 'ubo-step-progress--complete': isStepComplete(2) }"
                >
                    <span class="text-small">{{ fuellgrad.anteilInhaber }}</span>
                </div>
            </li>
            <li
                class="ubo-step box-spacing"
                :class="{ 'ubo-step--active': isStepActive(3), 'has-errors': hasErrors(3) }"
            >
                <div class="ubo-step-number">
                    <span>3</span>
                </div>
                <div
                    class="ubo-step-name"
                    data-qa="menue-rechtsform-geschaeftstaetigkeit"
                    @click.prevent="$emit('select-step', 3)"
                >
                    <span v-if="inhabergefuehrt">
                        Rechtsform & Geschäfts­tätigkeit
                    </span>
                    <span v-else>
                        Geschäfts­tätigkeit
                    </span>
                </div>
                <div
                    class="ubo-step-progress"
                    :class="{ 'ubo-step-progress--complete': isStepComplete(3) }"
                >
                    <span class="text-small">{{ fuellgrad.anteilRechtsformGeschaeftstaetigkeit }}</span>
                </div>
            </li>
            <li
                class="ubo-step box-spacing"
                :class="{ 'ubo-step--active': isStepActive(4), 'has-errors': hasErrors(4) }"
            >
                <div class="ubo-step-number">
                    <span>4</span>
                </div>
                <div
                    class="ubo-step-name"
                    data-qa="menue-umsatz-ma"
                    @click.prevent="$emit('select-step', 4)"
                >
                    Umsatz & Mitarbeiter
                </div>
                <div
                    class="ubo-step-progress"
                    :class="{ 'ubo-step-progress--complete': isStepComplete(4) }"
                >
                    <span class="text-small">{{ fuellgrad.anteilUmsatzangaben }}</span>
                </div>
            </li>
            <li
                class="ubo-step box-spacing"
                :class="{ 'ubo-step--active': isStepActive(5), 'has-errors': hasErrors(5) }"
            >
                <div class="ubo-step-number">
                    <span>5</span>
                </div>
                <div
                    class="ubo-step-name"
                    data-qa="menue-unterlagen-einreichen"
                    @click.prevent="$emit('select-step', 5)"
                >
                    Unterlagen einreichen
                </div>
                <div
                    class="ubo-step-progress"
                    :class="{ 'ubo-step-progress--complete': isStepComplete(5) }"
                >
                    <span
                        class="text-small"
                        v-show="fuellgrad.anteilUnterlagen"
                    >
                        {{ fuellgrad.anteilUnterlagen }}
                    </span>
                </div>
            </li>
            <li
                class="ubo-step box-spacing"
                :class="{ 'ubo-step--active': isStepActive(6), 'has-errors': hasErrors(6) }"
            >
                <div class="ubo-step-number">
                    <span>6</span>
                </div>
                <div
                    class="ubo-step-name"
                    data-qa="menue-sonstige-unternehmensdaten"
                    @click.prevent="$emit('select-step', 6)"
                >
                    Sonstige Unternehmens­daten
                </div>
                <div
                    class="ubo-step-progress"
                    :class="{ 'ubo-step-progress--complete': isStepComplete(6) }"
                >
                    <span class="text-small">{{ fuellgrad.anteilSonstigeUnternehmensdaten }}</span>
                </div>
            </li>
            <li
                class="ubo-step box-spacing"
                :class="{ 'ubo-step--active': isStepActive(7), 'has-errors': hasErrors(7) }"
            >
                <div class="ubo-step-number">
                    <span>7</span>
                </div>
                <div
                    class="ubo-step-name"
                    data-qa="menue-ansprechpartner"
                    @click.prevent="$emit('select-step', 7)"
                >
                    Ansprechpartner, Anmerkungen
                </div>
                <div
                    class="ubo-step-progress"
                    :class="{ 'ubo-step-progress--complete': isStepComplete(7) }"
                >
                    <span class="text-small">{{ fuellgrad.anteilAnsprechpartner }}</span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'Menue',
    props: {
        errors: {},
        menuConfig: {},
        inhabergefuehrt: null,
        fuellgrad: {}
    },
    computed: {
        isStepActive() {
            return step => this.menuConfig.activeStep === step
        },
        isStepComplete() {
            return (step) => {
                switch (step) {
                    case 1:
                        return this.fuellgrad.anteilFirmenidentifikation === '100'
                    case 2:
                        return this.fuellgrad.anteilInhaber === '100'
                    case 3:
                        return this.fuellgrad.anteilRechtsformGeschaeftstaetigkeit === '100'
                    case 4:
                        return this.fuellgrad.anteilUmsatzangaben === '100'
                    case 5:
                        return this.fuellgrad.anteilUnterlagen === '100'
                    case 6:
                        return this.fuellgrad.anteilSonstigeUnternehmensdaten === '100'
                    case 7:
                        return this.fuellgrad.anteilAnsprechpartner === '100'
                    default:
                        return false
                }
            }
        },
        hasErrors: function () {
            return step => {
                if (this.errors) {
                    const fields = {
                        '1': [ 'firmierung', 'strasse', 'hausnummer', 'hausnummerZusatz', 'plz', 'ort', 'land',
                            'telefonnummer-land', 'telefonnummer-vorwahl', 'telefonnummer-nummer',
                            'telefonnummer-durchwahl', 'mobilnummer-land', 'mobilnummer-vorwahl', 'mobilnummer-nummer',
                            'faxnummer-land', 'fax-vorwahl', 'fax-nummer', 'fax-durchwahl', 'emailAdresse',
                            'internetAdresse', 'steuernummer', 'umsatzsteuerIdentnummer' ],
                        '2': [ 'inhaber-vorname', 'inhaber-nachname', 'inhaber-geburtsname', 'inhaber-geburtsdatum',
                            'inhaber-land', 'inhaber-plz', 'inhaber-ort', 'inhaber-ortsteil', 'inhaber-strasse',
                            'inhaber-hausnummer', 'inhaber-hausnummerZusatz' ],
                        '3': [ 'geschaeftstaetigkeit-eingetragenerGegenstand', 'geschaeftstaetigkeit-anzahlKunden',
                            'geschaeftstaetigkeit-prozentanteilPrivatkunden',
                            'geschaeftstaetigkeit-prozentanteilOeffentlicheHand',
                            'geschaeftstaetigkeit-anzahlLieferanten' ],
                        '4': [ 'umsatz-jahr', 'umsatz-umsatzerwartung', 'umsatz-betrag', 'umsatz-betragVorjahr',
                            'mitarbeiter-jahr', 'mitarbeiter-anzahlGesamt', 'mitarbeiter-anzahlGesamtVorjahr',
                            'mitarbeiter-anzahlInhaber', 'mitarbeiter-anzahlAngestellte',
                            'mitarbeiter-anzahlGewerblicheMitarbeiter', 'mitarbeiter-anzahlAuszubildende',
                            'mitarbeiter-anzahlTeilzeitkräfte', 'mitarbeiter-anzahlAushilfen',
                            'mitarbeiter-anzahlFreieMitarbeiter' ],
                        '5': [ 'dokumente-anmerkungen', 'dokumente-files-0' ],
                        '6': [ 'immobilien-betriebsraeumeGemietet', 'immobilien-betriebsraeumeFlaeche',
                            'immobilien-betriebsraeumeMiete', 'importExport-importAnteil', 'importExport-importLand-0',
                            'importExport-importLand-1', 'importExport-importLand-2', 'importExport-importLand-3',
                            'importExport-importLand-4', 'importExport-importLand-5', 'importExport-importLand-6',
                            'importExport-importLand-7', 'importExport-importLand-8', 'importExport-importLand-9',
                            'importExport-importLand-10', 'importExport-importLand-11', 'importExport-importLand-12',
                            'importExport-importLand-13', 'importExport-importLand-14', 'importExport-importLand-15',
                            'importExport-importLand-16', 'importExport-importLand-17', 'importExport-importLand-18',
                            'importExport-importLand-19', 'importExport-importLand-20', 'importExport-exportAnteil',
                            'importExport-exportLand-0', 'importExport-exportLand-1', 'importExport-exportLand-2',
                            'importExport-exportLand-3', 'importExport-exportLand-4', 'importExport-exportLand-5',
                            'importExport-exportLand-6', 'importExport-exportLand-7', 'importExport-exportLand-8',
                            'importExport-exportLand-9', 'importExport-exportLand-10', 'importExport-exportLand-11',
                            'importExport-exportLand-12', 'importExport-exportLand-13', 'importExport-exportLand-14',
                            'importExport-exportLand-15', 'importExport-exportLand-16', 'importExport-exportLand-17',
                            'importExport-exportLand-18', 'importExport-exportLand-19', 'importExport-exportLand-20',
                            'banken-iban-0', 'banken-iban-1', 'banken-iban-2', 'banken-iban-3', 'banken-iban-4',
                            'banken-iban-5', 'banken-iban-6', 'banken-iban-7', 'banken-iban-8', 'banken-iban-9',
                            'banken-iban-10', 'banken-iban-11', 'banken-iban-12', 'banken-iban-13', 'banken-iban-14',
                            'banken-iban-15' ],
                        '7': [ 'ansprechpartner-anrede', 'ansprechpartner-name', 'ansprechpartner-funktion',
                            'ansprechpartner-telefon', 'ansprechpartner-mobil', 'ansprechpartner-fax',
                            'ansprechpartner-email', 'anmerkungen' ]
                    }
                    if (fields[String(step)]) {
                        if (fields[step].some(fieldname => this.errors[fieldname] instanceof Array && this.errors[fieldname].length)) {
                            this.editEventBus.$emit(`has-errors-step-${String(step)}`, true)
                            this.$emit(`has-errors-step-${String(step)}`, true)
                            return true
                        }
                    }
                }

                this.editEventBus.$emit(`has-errors-step-${String(step)}`, false)
                this.$emit(`has-errors-step-${String(step)}`, false)
                return false
            }
        }
    },
    inject: {
        editEventBus: "editEventBus"
    },
}
</script>

<style scoped lang="less">
    li.ubo-step {
        &.has-errors {
            .ubo-step-number {
                background-color: #e05469 !important;
            }
            .ubo-step-name {
                color: #e05469 !important;
            }
        }
        &.ubo-step--active.has-errors {
            background-color: #e05469 !important;
            .ubo-step-number {
                background-color: #ffffff !important;
            }
            .ubo-step-name {
                color: #ffffff !important;
            }
        }
    }
</style>
