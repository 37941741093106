<template>
    <div class="bg-brand bg-brand-light-grey box-spacing bg-brand--ubo">
        <div class="container" v-show="feedbackSent===true">
            <div class="row">
                <div class="col-12 pt">
                    <h3 class="text-align--center" data-qa="ubo-feedback-bestaetigung">{{ $t("ConfirmationView.Label.Success1") }}</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12 offset-md-1 col-md-10">
                    <h4 class="text-align--center">{{ $t("ConfirmationView.Label.Success2") }}</h4>
                </div>
            </div>
        </div>
        <div class="container" v-show="feedbackSent===false">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-align--center">Sind Sie mit der Prüfung Ihrer Unternehmensdaten zufrieden?</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3 class="text-align--center">Bitte bewerten Sie unser Online-Formular. Wir freuen uns auf Ihr
                        Feedback!</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
                    <crefo-feedback-form
                        context="ubo"
                        betreff="UBO"
                        @crefo-feedback-form-submit-success="successCallback"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>

/**
 *
 *  Send feedback about category (e.g. UBO).
 *
 *  This component needs a Validation Observer (vee-validate) to handle input errors.
 *  Shows an error message based on given ajaxError.
 *
 */

import UboInput from '@/components/commons/ubo-input'
import CrefoErrorMessage from '../helper/crefo-error'
import CrefoFeedbackForm from '@dm/crefo-feedback-form'


export default {
    name: 'Feedback',
    components: {UboInput, CrefoErrorMessage, CrefoFeedbackForm},
    props: {
        crefonummer: null
    },
    data() {
        return {
            feedbackSent: false,
        }
    },
    methods: {
        sendFeedback(feedback) {
            const data = {
                crefonummer: this.crefonummer,
                payload: {
                    anzahlSterne: feedback.rating,
                    anmerkung: feedback.message

                }
            }
            return this.$store.dispatch('userModule/sendFeedback', data)
        },
        successCallback() {
            this.feedbackSent = true
            window.scroll(0, 0)
        }
    }
}
</script>
