<template>
  <div class="loader-wrapper">
    <div class="lds-ring">
      <div :class="'border-'+color" />
      <div :class="'border-'+color" />
      <div :class="'border-'+color" />
      <div :class="'border-'+color" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CrefoRingLoading',
  props: {
    color: {
      type: String,
      default: 'crefo-blue'
    }
  }
}
</script>

<style scoped lang="less">
    .loader-wrapper {
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        width: 100%;
        text-align: center;
    }

    .lds-ring {
        display: inline-block;
        position: relative;
        width: 2em;
        height: 2em;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 2em;
        height: 2em;
        margin: .2em;
        border: .2em solid;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;

        &.border-crefo-blue {
            border-color: #009ee2 transparent transparent transparent;
        }
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
