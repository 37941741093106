<template>
    <div class="container">
        <div class="row mb-large ubo-intro-header">
            <div class="col-12 col-md-12 col-lg-10 col-xl-11">
                <h1 class="marketing mt">
                    <h1 class="marketing mt">
                        <span>Creditreform</span><br>
                        <span>Unternehmens-</span><br>
                        <span>befragung</span>
                    </h1>
                </h1>
            </div>
        </div>
        <div class="row justify-content-start mb-large ubo-intro-text">
            <div class="col-12 col-lg-6">
                <div class="h2 mb-small" v-if="anredeName">
                    <span>Hallo {{ anredeName }},</span>
                    <br>
                    willkommen zur Creditreform Unter­nehmens­befragung
                </div>
                <div class="h2 mb-small" v-else>
                    Willkommen zur<br>Creditreform<br>Unternehmensbefragung
                </div>
                <p>
                    Mit korrekten und vollständigen Unternehmensdaten tragen Sie aktiv zu einer besseren
                    Bonitätsbewertung Ihres Unternehmens bei.
                </p>
                <p>
                    <a
                        href="#"
                        role="button"
                        class="btn btn-default btn-icon-next"
                        @click.prevent="scrollToStart"
                    >
                        <span>Interview starten</span>
                    </a>
                </p>
            </div>
            <div class="col-12 col-sm-auto col-lg-6 col-xl-auto">
                <div class="bg-white box-shadow-xy box-spacing">
                    <div class="row">
                        <div class="col col-ubo-intro-text-progress-counter">
                            <div class="ubo-intro-text-progress-counter">
                                <strong class="text-brand-darkest-blue">{{ anteilGesamt }} %</strong>
                                <i class="crefo-brand-icon icon-building icon-color-darkest-blue"
                                   aria-hidden="true"></i>
                                <svg height="146" width="146">
                                    <circle cx="73" cy="73" r="68" stroke="#edece5" stroke-width="10" fill="none"/>
                                    <path :d="getProgressCoordinates(anteilGesamt)" stroke="#009ee2" stroke-width="10"
                                          fill="none"/>
                                </svg>
                            </div>
                        </div>
                        <div class="col">
                            <div class="h3 mb-small" :class="guterAnfang">Ein guter Anfang</div>
                            <p>Ihr Profil ist zu {{ anteilGesamt }} % gepflegt.</p>
                            <!-- div class="bg-brand-light-grey ubo-intro-text-progress-box small-box-spacing">
                                <strong class="text-brand-darkest-blue">Letztes Update</strong><br>
                                <span>24.08.2020</span>
                                <i class="crefo-ui-icon icon-edit-pen icon-color-blue" aria-hidden="true"></i>
                            </div -->
                        </div>
                    </div>
                </div>
                <a :href="$t('URL.MehrInfos')"
                   class="btn btn-default btn-min btn-purple btn-icon-beratung mt" role="button">
                    <span>{{ $t('UboEingabeformularView.Button.Hilfe') }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

/**
 *
 */

export default {
    name: 'Kopf',
    props: {
        scrollToStart: null,
        getProgressCoordinates: null,
        vorname: null,
        nachname: null,
        anteilGesamt: null,
        letztesUpdate: null,
    },
    computed: {
        anredeName() {
            let result = ""
            if (this.vorname) {
                result = result.concat(this.vorname)
            }
            result = result.concat(" ")
            if (this.nachname) {
                result = result.concat(this.nachname)
            }
            return result.trim()
        },
        guterAnfang() {
            let result
            if (this.anteilGesamt && Number(this.anteilGesamt) >= 30.0) {
                result = 'guter-anfang--active'
            }else{
                result = 'guter-anfang--hidden'
            }
            return result
        }

    },
    methods: {}
}
</script>
<style scoped lang="less">

.guter-anfang--hidden {
    visibility: hidden;

}
.guter-anfang--active {
    visibility: visible;
}


</style>
