/**
 *
 * @param {String} obj
 * @param {Boolean}  edit
 * @return {String}
 *
 */

export const formatString = (obj) => {
    let result = null
    if (obj) {
        result = String(obj)
    }
    return result
}

/**
 *
 * @param {String} obj
 * @return {String}
 *
 */

export const parseString = (obj) => {
    let result = null
    if (obj) {
        result = new String(obj)
    }
    return result
}

/**
 *
 * @param {Number} obj
 * @return {String}
 *
 */

export const formatNumber = (obj) => {
    let result = null
    if (obj) {
        result = String(obj)
    }
    return result
}


/**
 *
 * @param {Number} obj
 * @return {Number}
 *
 */

export const parseNumber = (obj) => {
    let result = null
    if (obj) {
        let formattedObj = String(obj).replace(/[^0-9]/g, '')
        if (!isNaN(formattedObj)) {
            result = new Number(formattedObj)
        }
    }
    return result
}


/**
 *
 * @param {String} obj
 * @return {Boolean}
 *
 */

export const parseBoolean = (obj) => {
    let result = null
    if (obj) {
        if("true"===obj){
            result = true
        }
        if("false"===obj){
            result = false
        }
    }
    return result
}


/**
 *
 * @param {Number} obj
 * @return {String}
 *
 */

export const formatAmount = (obj) => {
    let result = null
    if (obj) {
        result = Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(obj)
    }
    return result
}

/**
 *
 * @param {Number} obj
 * @return {Number}
 *
 */

export const parseAmount = (obj) => {
    let result = null
    if (obj) {
        let formattedObj = String(obj).replace(/[^0-9,]/g, '').replace(',', '.')
        if (isNaN(formattedObj)) {
            formattedObj = '0'
        }
        result = new Number(formattedObj)
    }
    return result
}

/**
 *
 * @param {Object} obj
 * @return {String}
 *
 */

export const formatDate = (obj) => {
    let result = null
    if (obj && typeof obj.jahr === 'number' && typeof obj.monat === 'number' && typeof obj.tag === 'number') {
        result = `${ ('0' + obj.tag).slice(-2) }.${ ('0' + obj.monat).slice(-2) }.${ obj.jahr }`
    }
    return result
}

/**
 *
 * @param {String} obj
 * @return {Object}
 *
 */

export const parseDate = (obj) => {
    let dateFormat = /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/
    let result = null
    if (obj && typeof obj === 'string' && obj.match(dateFormat)) {
        result = {
            jahr: Number(obj.slice(6, 10)),
            monat: Number(obj.slice(3, 5)),
            tag: Number(obj.slice(0, 2))
        }
    }
    return result
}
