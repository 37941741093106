<template>
    <div>
        <div class="row mb lp_header">
            <div class="col-12 col-lg-10 mt">
                <img src="@/assets/img/unternehmensbefragung.png"/>
            </div>
            <div class="col-12 mt">
                <h1 class="marketing mt">
                    <span>Creditreform</span><br>
                    <span>Unternehmens-</span><br>
                    <span>befragung</span>
                </h1>
            </div>
        </div>
        <div class="row mb">
            <div class="col-12">
                <div class="h1">Willkommen zur Creditreform Unter­nehmens­befragung</div>
            </div>
            <div
                v-if="[ 'fr_FR', 'fr_LU' ].includes($i18n.locale)"
                class="col-12 col-lg-11"
                data-qa="hinweis-fr-lu"
            >
                <div class="box-shadow-xy box-spacing bg-brand-light-grey mb">
                    <p style="display: flex; margin-bottom: 0; gap: 10px; justify-content: flex-start; position: relative; left: 0; top: -10px;">
                        <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAIAAAAVyRqTAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpBMjUyMkUxODE3NzkxMUUyODY3Q0FBOTFCQzlGNjlDRiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpDQjc4RjdFMDE3NzkxMUUyODY3Q0FBOTFCQzlGNjlDRiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkEyNTIyRTE2MTc3OTExRTI4NjdDQUE5MUJDOUY2OUNGIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkEyNTIyRTE3MTc3OTExRTI4NjdDQUE5MUJDOUY2OUNGIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+NYcTvAAAADBJREFUeNpiZFCYz4Ab/L+fgEf2q7YTHlkmBpqBUaNHjR41etToUaNHjaad0QABBgAMOgSHJZqdhAAAAABJRU5ErkJggg=="
                            width="20"
                            height="13"
                            alt="FR"
                        />
                        <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAIAAAAVyRqTAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoyMDQ3NDU2MjE3ODIxMUUyQTcxNDlDNEFCRkNENzc2NiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoyMDQ3NDU2MzE3ODIxMUUyQTcxNDlDNEFCRkNENzc2NiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkUwMDQyMDU1MTc4MTExRTJBNzE0OUM0QUJGQ0Q3NzY2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkUwMDQyMDU2MTc4MTExRTJBNzE0OUM0QUJGQ0Q3NzY2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+HeS18gAAADFJREFUeNpifK+ty0AbwMRAMzBq9LAwmvH///+jATJqNG7Awrjk3miAjBo9IEYDBBgAMnEF8ALV3YIAAAAASUVORK5CYII="
                            width="20"
                            height="13"
                            alt="LU"
                        />
                    </p>
                    <p
                        class="text-semibold"
                        style="margin-bottom: 0;"
                    >
                        Cette rubrique n'est actuellement pas disponible en français, veuillez nous en excuser.<br>
                        D'autres langues sont  en cours de réalisation et seront disponibles prochainement.<br>
                        Pour toute question, veuillez vous adresser à Creditreform Luxembourg SA sous
                        <a href="mailto:teamrecherche@luxembourg.creditreform.lu">teamrecherche@luxembourg.creditreform.lu</a>
                    </p>
                </div>
            </div>
            <div class="col-12 col-xl-10">
                <slot>
                    <p>
                        Eine gute Bonitätsbewertung beginnt mit den eigenen Unternehmensdaten. Dabei dürfen Sie
                        nicht vergessen, dass diese Bewertung nicht nur Ihnen vorliegt, sondern auch Kreditgebern
                        und Geschäftspartnern, die über Ihr Unternehmen eine Bonitätsauskunft abrufen. Mit korrekten
                        und vollständigen Unternehmensdaten tragen Sie aktiv dazu bei, dass Ihre Bonitätsbewertung
                        verbessert wird.
                    </p>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UboLandingpageIntroText'
    }
</script>

<style scoped lang="less">
    .lp_header {
        position: relative;

        div:nth-child(1) {
            z-index: 1;
        }

        div:nth-child(2) {
            min-height: 397px;
            z-index: 5;
        }

        .marketing {
            z-index: 5;
        }

        img {
            position: absolute;
            top: 0;
            right: 0;
            width: 570px;
            height: 397px;
            z-index: 1;
            pointer-events: none;
        }

        @media only screen and (max-width: 1199px) {
            div:nth-child(2) {
                min-height: 320px;
            }

            img {
                width: 459px;
                height: 320px;
            }
        }

        @media only screen and (max-width: 991px) {
            div:nth-child(2) {
                min-height: 300px;
            }

            img {
                width: 431px;
                height: 300px;
            }
        }

        @media only screen and (max-width: 767px) {
            div:nth-child(2) {
                min-height: 270px;
            }

            img {
                width: 388px;
                height: 270px;
            }
        }
    }
</style>

