<template>
    <div v-if="isVisible">
        <crefo-ring-loading v-if="isLoading" />
        <div
            v-else
            class="crefo-input crefo-ui-select"
        >
            <div class="input-group">
                <label
                    for="crefonummer-select"
                    data-qa="crefonummer-select-label"
                >
                    <strong>Unternehmensauswahl</strong>
                </label>
                <div class="select-group">
                    <div class="input-wrapper">
                        <select
                            id="crefonummer-select"
                            v-model="value"
                            data-qa="crefonummer-select-liste"
                        >
                            <option
                                v-for="(crefonummer, index) in crefonummern"
                                :key="index"
                                :value="crefonummer"
                                :data-qa="'crefonummer-select-' + index"
                            >
                                {{ getUnternehmensname(crefonummer) }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getKurzprofil } from '@/services'
    import CrefoRingLoading from '../helper/crefo-ring-loading'

    export default {
        name: 'CrefonummerSelect',
        components: { CrefoRingLoading },
        props: {
            selectedCrefonummer: {
                type: String,
                default: null
            },
            crefonummern: {
                type: Array,
                default: () => []
            }
        },
        data () {
            return {
                isLoading: true,
                unternehmen: []
            }
        },
        computed: {
            value: {
                get () {
                    return this.selectedCrefonummer
                },
                set (value) {
                    this.$emit('selected-crefonummer', value)
                }
            },
            isVisible () {
                return this.crefonummern.length > 1
                    && this.$store.state.userModule.user.cr_membernumber === null
            }
        },
        methods: {
            fetchUnternehmensnamen () {
                let loaded = 0

                this.crefonummern.forEach(crefonummer => {
                    getKurzprofil(crefonummer)
                        .then(response => {
                            let firmierung = crefonummer

                            if (typeof response?.data?.adresse?.firmierung === 'string' && response.data.adresse.firmierung.length) {
                                firmierung += ' - ' + response.data.adresse.firmierung
                            }
                            if (typeof response?.data?.adresse?.ort === 'string' && response.data.adresse.ort.length) {
                                firmierung += ' (' + response.data.adresse.ort + ')'
                            }

                            this.unternehmen.push({
                                crefonummer,
                                firmierung
                            })
                            ++loaded
                        })
                        .catch(error => {
                            this.unternehmen.push({
                                crefonummer,
                                firmierung: crefonummer
                            })
                            ++loaded
                        })
                        .finally(() => {
                            if (loaded >= this.crefonummern.length) {
                                this.isLoading = false
                            }
                        })
                })
            },
            getUnternehmensname (crefonummer) {
                const entry = this.unternehmen.find(item => item.crefonummer === crefonummer)
                if (entry) {
                    return entry.firmierung
                } else {
                    return crefonummer
                }
            }
        },
        mounted () {
            if (this.isVisible) {
                this.fetchUnternehmensnamen()
            }
        }
    }
</script>
