/**
 * @param {UserState} state
 */
export function isUnternehmensdatenUnsaved(state) {
    if (state.unternehmensdatenChangedIgnore) {
        return false
    }
    if (process && process.env && process.env.NODE_ENV === 'test' && process.env.TEST_LEAVING_PAGE !== 'true') {
        return false
    }

    if (typeof state.dokumenteAnmerkungen === 'string' && state.dokumenteAnmerkungen.length) {
        return true
    }
    if (state.dokumente instanceof Array && state.dokumente.length) {
        return true
    }

    try {
        return state.unternehmensdatenOriginalAsString !== JSON.stringify(state.unternehmensdaten)
    } catch (e) {}

    return false
}
