<template>
    <div
        class="bg-brand bg-brand-light-grey box-spacing"
        :class="{ 'big-box-spacing': bigBoxSpacing }"
    >
        <div class="container">
            <slot></slot>
            <div class="row">
                <div class="col-12 col-md-10">
                    <crefo-kurzprofil
                        :kurzprofil="fetchKurzprofil"
                        :crefonummer="crefonummer"
                        :show-header="showHeader"
                        v-on="$listeners"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CrefoKurzprofil from '@dm/crefo-kurzprofil'
    import * as api from '../../services/index'
    export default {
        name: 'UboSuggestedKurzprofil',
        components: { CrefoKurzprofil },
        props: {
            crefonummer: {
                type: String,
                required: true
            },
            showHeader: {
                type: Boolean,
                required: false,
                default: true
            },
            bigBoxSpacing: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        methods: {
            fetchKurzprofil (crefonummer) {
                return api.getKurzprofil(crefonummer)
            }
        }
    }
</script>
