<template>
    <div
        v-if="showFooter"
        class="vc-contact-container">
        <div class="vc-contact bg-brand-darkest-blue">
            <div class="container">
                <div class="row">
                    <div class="col-12 pt">
                        <h2 class="vc-name text-white">Kontaktieren Sie uns!</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4 mb">
                        <i class="crefo-brand-icon icon-phone-arrow icon-color-white" aria-hidden="true"></i>
                        <p class="text-white pt-small" data-qa="content-data-phone">
                            Technische Fragen<br>
                            +49 2131 109-300
                        </p>
                    </div>
                    <div class="col-12 col-md-4 mb">
                        <i class="crefo-brand-icon icon-envelope icon-color-white" aria-hidden="true"></i>
                        <p class="text-white pt-small" data-qa="content-data-email">mc@creditreform.de</p>
                    </div>
                    <div class="col-12 col-md-4 mb">
                        <i class="crefo-brand-icon icon-user-open icon-color-white" aria-hidden="true"></i>
                        <p class="text-white pt-small" data-qa="content-data-user">
                            Technische Fragen<br>
                            +49 2131 109-300
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: 'UboContactFooter',
        data () {
            return {
                showFooterTimeoutElapsed: false
            }
        },
        computed: {
            ...mapState('userModule', [ 'authenticated' ]),
            showFooter () {
                return this.authenticated !== true && this.showFooterTimeoutElapsed
            }
        },
        mounted() {
            setTimeout(() => {
                this.showFooterTimeoutElapsed = true
            }, 1000)
        }
    }
</script>

<style scoped lang="less">
    .vc-contact-container {
        margin-top: auto;
        align-self: flex-end;
        width: 100%;

        .vc-contact {
            margin-top: 60px;
            margin-bottom: -60px;
            text-align: center;

            i {
                font-size: 42px;
            }
        }
    }
</style>
