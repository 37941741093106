/**
 * @type {UserState}
 */
export default {
    authenticated: false,
    activated: false,
    token: null,
    consentRecherche: null,
    unternehmensdatenFetching: null,
    unternehmensdatenPreValidated: true,
    unternehmensdatenSending: null,
    crefonummer: null,
    isUserStateDetermined: false,
    userState: null,
    dateLocale: 'de',
    language: 'de',
    ajaxError: null,
    user: {
        cr_id: null,
        givenName: '',
        surname: '',
        email: null,
        user_roles: [],
        cr_membernumber: null,
        actForMemberId: null,
        actForMemberName: ''
    },
    dokumente: [],
    dokumenteAnmerkungen: null,
    unternehmensdaten: {
        kopfdaten: {
            clz: null
        },
        firmenidentifikation: {
            firmierung: null,
            gewerbename: null,
            handelsname: null,
            adressdaten: {
                land: "DE",
                plz: null,
                ort: null,
                ortsteil: null,
                strasse: null,
                hausnummer: null,
                hausnummerZusatz: null,
            },
            telefonnummer: null,
            mobilnummer: null,
            faxnummer: null,
            emailAdresse: null,
            internetAdresse: null,
            umsatzsteuerIdentnummer: null,
            steuernummer: null
        },
        rechtsform: {
            gewerblichRegistriert: null,
            inhabergefuehrt: null,
        },
        inhaber: {
            vorname: null,
            nachname: null,
            geburtsname: null,
            geburtsdatum: {
                jahr: null,
            },
            adresse: {
                land: null,
                plz: null,
                ort: null,
                ortsteil: null,
                strasse: null,
                hausnummer: null,
                hausnummerZusatz: null
            }
        },
        immobilien: {
            betriebsraeumeGemietet: null,
            betriebsraeumeFlaeche: null,
            betriebsraeumeMiete: null
        },
        geschaeftstaetigkeit: {
            eingetragenerGegenstand: null,
            vertriebsweg: [],
            anzahlKunden: null,
            prozentanteilPrivatkunden: null,
            prozentanteilOeffentlicheHand: null,
            anzahlLieferanten: null
        },
        umsatz: {
            jahr: null,
            betrag: null,
            betragVorjahr: null,
            umsatzerwartung: null
        },
        mitarbeiter: {
            jahr: null,
            anzahlGesamt: null,
            anzahlGesamtVorjahr: null,
            mitarbeitertyp: []
        },
        importExport: {
            import: null,
            importAnteil: null,
            importLand: [],
            export: null,
            exportAnteil: null,
            exportLand: []
        },
        banken: {
            iban: []
        },
        ansprechpartner: {
            anrede: null,
            name: null,
            funktion: null,
            telefon: null,
            mobil: null,
            email: null,
            fax: null
        },
        anmerkungen: null,
        vcAnsprechpartner: {
            name: null,
            telefon: null,
            fax: null,
            email: null
        }
    },
    unternehmensdatenOriginalAsString: '',
    unternehmensdatenChangedIgnore: false
}

/**
 * @typedef UserState
 * @type {Object}
 * @property {boolean} authenticated - Whether user is authenticated
 * @property {?string} token - The complete keycloak token
 * @property {?boolean} crefonummerFetching - While crefonummer is beeing fetched, null means never fetched till now
 * @property {?string} crefonummer - The currently used crefonummer for the current user (matches the crefo of the abonnement)
 * @property {?boolean} isVertretungsberechtigt - If user is Vertretungsberechtigt for the current Crefonummer
 * @property {boolean} isUserStateDetermined - When all checks needed for Meine Bonität are done
 * @property {string} dateLocale - The current dayjs locale for date formatting
 * @property {?AjaxError} ajaxError - Error information
 * @property {Object} user - User data for the current user
 * @property {?string} user.cr_id - cr_id from Token
 * @property {string} user.givenName - Given Name fetched from Token
 * @property {string} user.surname - Surname fetched from Token
 * @property {?string} user.email - Email fetched from Token
 * @property {Array<string>} user.user_roles - User roles fetched from Token
 * @property {?string} user.cr_membernumber - cr_membernumber fetched from Token
 * @property {?string} user.actForMemberId - Currently active memberId from Unternehmensnavigation
 * @property {string} user.actForMemberName - Currently active MemberName from Unternehmensnavigation
 * @property {boolean} unternehmensdatenPreValidated - Need to initially validate new unternehmensdata?
 * @property {Array<File>} dokumente
 * @property {string} dokumenteAnmerkungen
 * @property {Object} unternehmensdaten - Original data from API, or changed data that was confirmed by pressing "Daten übernehmen"
 * @property {boolean} unternehmensdatenChangedIgnore
 * @see https://github.com/iamkun/dayjs/tree/dev/src/locale
 */
