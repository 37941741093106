<template>
    <div>
        <div class="container">
            <crefo-ring-loading class="mt"/>
        </div>
    </div>
</template>

<script>
    /**
     * The startpage only detects the user state and then decides for forwarding him to:
     *  - Show the ubo activation form.
     *  - Or forward to ubo form, if he is Vertretrungsberechtigt via crefotrust.
     */

    import { mapState } from 'vuex'
    import { checkUserState } from '../helper/user-state'
    import CrefoRingLoading from '@dm/crefo-ring-loading'

    export default {
        name: 'IndexView',
        components: { CrefoRingLoading },
        props: {
            redirectInstant: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            ...mapState('userModule', [ 'isUserStateDetermined', 'userState' ])
        },
        methods: {
            async determineUserState () {
                this.$store.commit('userModule/ON_SET_USER_STATE', await checkUserState(this.$route, 15000, this.redirectInstant))
                this.$store.commit('userModule/ON_SET_USER_STATE_DETERMINED', true)
                await this.$router.push(this.userState.naechsterSchritt)
            }
        },
        created () {
            if (this.isUserStateDetermined) {
              this.$router.push(this.userState.naechsterSchritt)
            } else {
                this.determineUserState()
            }
        }
    }
</script>
