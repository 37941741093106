import { required, required_if, max, max_value, length, regex, numeric, min } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import * as validator from "./validator";
import * as formatter from "./formatter";

extend("two_required_fields", {
    params: [ "field_1", "field_2" ],
    computesRequired: true,
    validate: (value, { field_1, field_2 }) => {
        if (([ '', false, null, undefined ].indexOf(value) !== -1) /* nicht gefüllt */ &&
            (/* eines gefüllt */([ '', false, null, undefined ].indexOf(field_1) === -1) ||
                ([ '', false, null, undefined ].indexOf(field_2) === -1))) {
            return false
        }
        return true
    },
    message:
        `Dieses Feld ist erforderlich.`

})

extend("zip_code", {
    params: [ "country" ],
    computesRequired: true,
    validate: (value, { country }) => {
        if (([ false, null, undefined ].indexOf(country) === -1) && "DE" === country && ([ false, null, undefined ].indexOf(value) === -1) && !/^[0-9]{5}$/.test(value)) {
            return false
        }
        return true
    },
    message:
        `Bitte geben Sie genau 5 Ziffern ein.`

});

extend("iban", {
    computesRequired: true,
    validate: (value) => {
        let returnCode = validator.checkIban(value)
        if (returnCode !== validator.RETURN_CODES.SUCCESS) {
            return false
        }
        return true
    },
    message:
        `Das Format der Bankverbindung ist falsch.`
});


extend('numeric', {
    ...numeric,
    message: (fieldName) => {
        return `Bitte geben Sie nur Ziffern ein.`
    }
});


extend('regex', {
    ...regex,
    message: (fieldName) => {
        return `Ungültiges Format.`
    }
});

extend('required_with_name', {
    ...required,
    message: (fieldName) => {
        return `${ fieldName } ist erforderlich.`
    }
});

extend('required_if', {
    ...required_if,
    message: () => {
        return `Dieses Feld ist erforderlich.`
    },
    computesRequired: true
});

extend('required', {
    ...required,
    message: () => {
        return `Dieses Feld ist erforderlich.`
    }
});

extend('max', {
    ...max,
    message: (fieldName, args) => {
        return `Bitte geben Sie nicht mehr als ${ args.length } Zeichen ein.`
    }
});

extend('min', {
    ...min,
    message: (fieldName, args) => {
        return `Bitte geben Sie mindestens ${ args.length } Zeichen ein.`
    }

});

extend('max_value', {
        ...max_value,
        message: (fieldName, args) => {
            return `Die Anzahl darf ${ args.max } nicht übersteigen.`
        }
    }
);


extend('length', {
    ...length,
    message: (fieldName, args) => {
        return `Bitte geben Sie genau ${ args.length } Zeichen ein.`
    }
});

extend('term_of_service', {
    validate: value => {
        return value === true
    },
    message: () => {
        return null
    }
});
extend('max_sales_amount', {
    validate: value => {
        return formatter.parseAmount(value) <= 9007000000000.0
    },
    message: () => {
        return `Der Betrag darf 9.007.000.000.000,00 € nicht übersteigen.`
    }
});

extend('max_amount', {
    validate: (value, params) => {
        return formatter.parseAmount(value) <= formatter.parseAmount(params[0] + ',' + params[1])
    },
    message: (fieldName, args) => {
        return `Der Wert darf ${ args[0] },${ args[1] } nicht übersteigen.`
    }
});


extend('max_percent', {
    validate: value => {
        return formatter.parseNumber(value) <= 100.0
    },
    message: () => {
        return `Der Prozentsatz darf 100 nicht übersteigen.`
    }
});

extend('min_year', {
    validate: value => {
        let minYear = new Date().getFullYear() - 5
        return formatter.parseAmount(value) >= minYear
    },
    message: () => {
        let minYear = new Date().getFullYear() - 5
        return `Die Jahresangabe darf nicht vor ${ minYear } liegen`
    }
});

extend('max_year', {
    validate: value => {
        let maxYear = new Date().getFullYear() + 1
        return formatter.parseNumber(value) <= maxYear
    },
    message: () => {
        let maxYear = new Date().getFullYear() + 1
        return `Die Jahresangabe darf nicht nach ${ maxYear } liegen`
    }
});

extend('dokumente_anmerkungen_allowed', {
    validate: (value, params) => {
        return value === null || value == '' || params[0] === 'true'
    },
    message: () => {
        return 'Bitte laden Sie mindestens eine Datei hoch, um eine Anmerkung zu den hochgeladenen Dateien mitschicken zu können.'
    }
});
