import i18n from '../i18n'
import * as webtrekk from './webtrekk'


/**
 * Prefix for default error message.
 *
 * If no custom error message is given, then it will try to fetch a default error message based on status code.
 * E.g. Status Code 404 => Will try to fetch translation Error.404.
 * If not found tries to fallback to Error.500, otherwise empty string is returned as default error message.
 *
 * @type {string}
 */
const ERROR_PREFIX = 'Error.'

/**
 * Call Axios.Get and return current or cached result.
 *
 * @param {?AxiosError} axiosError
 * @param {string} errorMessage
 * @param {string} errorPrefixAppend
 * @return {AjaxError}
 */
export const getParsedError = (axiosError, errorMessage= '', errorPrefixAppend = '') => {
    const parsedError = {
        statusCode: getStatusCode(axiosError),
        statusText: getStatusText(axiosError),
        correlationId: getCorrelationId(axiosError),
        defaultErrorMessage: getDefaultErrorMessage(axiosError, errorMessage, errorPrefixAppend),
        responseError: getResponseError(axiosError),
        responseMessage: getResponseMessage(axiosError),
        requestUrl: getRequestUrl(axiosError)
    }

    webtrekk.trackAction(
        'error_message_meine_bonitaet_frontend',
        {
            2: String(parsedError.statusCode),
            3: parsedError.defaultErrorMessage,
            5: String(parsedError.statusCode),
            6: 'error_ubo_frontend',
            7: parsedError.requestUrl,
            8: parsedError.correlationId,
            16: parsedError.responseMessage.substr(0, 250),
            17: parsedError.responseError.substr(0, 250)
        }
    )

    return parsedError
}

/**
 * Get status code of axiosError.
 *
 * @param {?AxiosError} axiosError
 * @return {number}
 */
export const getStatusCode = axiosError => {
    const statusCode = getErrorProperty(axiosError, 'status')
    return typeof statusCode === 'number' ? statusCode : 0
}

/**
 * Get status text of axiosError.
 *
 * @param {?AxiosError} axiosError
 * @return {string}
 */
export const getStatusText = axiosError => {
    const statusText = getErrorProperty(axiosError, 'statusText')
    return typeof statusText === 'string' ? statusText : ''
}

/**
 * Get correlationId of axiosError.
 *
 * @param {?AxiosError} axiosError
 * @return {string}
 */
export const getCorrelationId = axiosError => {
    const headers = getErrorProperty(axiosError, 'headers')
    if (typeof headers === 'object' && headers !== null) {
        if (typeof headers['correlationId'] === 'string') {
            return headers['correlationId']
        }
        if (typeof headers['correlationid'] === 'string') {
            return headers['correlationid']
        }
    }

    return ''
}

/**
 * Get default error message based on status code.
 *
 * @param {?AxiosError} axiosError
 * @param {string} errorMessage
 * @param {string} errorPrefixAppend
 * @return {string}
 */
export const getDefaultErrorMessage = (axiosError, errorMessage, errorPrefixAppend) => {
    if (typeof errorMessage === 'string' && errorMessage.length) {
        return errorMessage
    }

    const statusCode = getStatusCode(axiosError)
    const prefix = errorPrefixAppend.length ? ERROR_PREFIX + errorPrefixAppend + '.' : ERROR_PREFIX

    if (i18n.te(prefix + statusCode)) {
        return i18n.t(prefix + statusCode)
    } else if (i18n.te(prefix + '500')) {
        return i18n.t(prefix + '500')
    } else if (errorPrefixAppend.length) {
        if (i18n.te(ERROR_PREFIX + statusCode)) {
            return i18n.t(ERROR_PREFIX + statusCode)
        } else if (i18n.te(ERROR_PREFIX + '500')) {
            return i18n.t(ERROR_PREFIX + '500')
        }
    }

    return ''
}

/**
 * Get error property of spring boot response text.
 *
 * @param {?AxiosError} axiosError
 * @return {string}
 */
export const getResponseError = axiosError => {
    const response = getResponseObject(axiosError)
    if ('error' in response) {
        return String(response.error)
    }

    return ''
}

/**
 * Get message property of spring boot response text.
 *
 * @param {?AxiosError} axiosError
 * @return {string}
 */
export const getResponseMessage = axiosError => {
    const response = getResponseObject(axiosError)
    if ('message' in response) {
        return String(response.message)
    }

    return ''
}

/**
 * Get request url of axiosError.
 *
 * @param {?AxiosError} axiosError
 * @return {string}
 */
export const getRequestUrl = axiosError => {
    let responseURL = getErrorProperty(axiosError, 'responseURL')
    if (typeof responseURL !== 'string') {
        return ''
    }

    const indexMC = responseURL.indexOf('meine.creditreform.de/')
    if (indexMC > 0) {
        responseURL = responseURL.substr(indexMC + 21)
    }
    if (responseURL.indexOf('?') >= 0) {
        responseURL = responseURL.substr(0, responseURL.indexOf('?'));
    }
    responseURL = responseURL.replace(new RegExp('[0-9]+', 'g'), 'XXXX');
    return responseURL
}

/**
 * Returns parsed response text as object.
 *
 * @param {AxiosError} axiosError
 * @return {{}|any}
 */
function getResponseObject(axiosError) {
    let response = getErrorProperty(axiosError, 'data')
    if (typeof response !== 'object' || response === null) {
        response = getErrorProperty(axiosError, 'response')
    }

    if (typeof response === 'object' && response !== null) {
        return response
    }

    if (typeof response === 'string' && response.length) {
        try {
            response = JSON.parse(response)
            if (typeof response === 'object' && response !== null) {
                return response
            }
        } catch (e) {}
    }

    return {}
}

/**
 * Returns property of axiosError or all properties.
 *
 * @param {AxiosError} axiosError
 * @param {?string} propertyName
 * @return {null|*}
 */
function getErrorProperty(axiosError, propertyName) {
    function getProperty(obj, prop) {
        if (typeof obj === 'object' && obj !== null) {
            if (typeof prop === 'string' && prop.length) {
                if (prop in obj) {
                    return obj[prop]
                } else {
                    return null
                }
            }
        }

        return null
    }

    if (axiosError && axiosError.response) {
        const errorData = getProperty(axiosError.response, propertyName)
        if (errorData !== null) {
            return errorData
        }
    }

    if (axiosError && axiosError.request) {
        return getProperty(axiosError.request, propertyName)
    }

    return null
}

/**
 * @typedef AjaxError
 * @type {Object}
 * @property {number} statusCode
 * @property {string} statusText
 * @property {string} correlationId
 * @property {string} defaultErrorMessage
 * @property {string} responseError
 * @property {string} responseMessage
 * @property {string} requestUrl
 */
