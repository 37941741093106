import axios from 'axios'

// Set default headers to prevent caching
axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0'
}

/**
 * Before any request, we will check the lifetime of the Keycloak token.
 * If it's already expired or expiring soon (within the next 10 seconds),
 * we will force a token update before sending the request.
 */
axios.interceptors.request.use(
    async (config) => {
        if (window.keycloak) {
            let tokenExpires = Math.round(window.keycloak.tokenParsed.exp + window.keycloak.timeSkew - new Date().getTime() / 1000)
            if (tokenExpires < 10) {
                console.log('[axios] Token expires soon. Updating token before sending request')
                await window.updateKeycloakToken()
            } else {
                console.log('[axios] Token still valid. No need to refresh before sending request')
            }
            config.headers.Authorization = `Bearer ${ window.keycloak.token }`
        }

        if (sessionStorage.getItem('actForMemberId') !== null) {
            config.headers['X-Act-For-Member-Id'] = sessionStorage.getItem('actForMemberId')
        } else {
            delete config.headers['X-Act-For-Member-Id']
        }

        return config
    },
    (error) => Promise.reject(error)
)

/**
 * Sends a POST request to receive unternehmensdaten
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getUnternehmensdaten = (data) => {
    const headers = buildHeader(data)
    const config = {
        headers
    }
    return axios.get(`/api/ubo-backend/unternehmensdaten/${ data.crefoNumber }`, config)
}

/**
 * Sends a POST request to update unternehmensdaten
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const postUnternehmensdaten = (data) => {
    const formData = new FormData()
    if (data) {
        try {
            formData.append('UnternehmensdatenLieferungRequest', new Blob(
                [ JSON.stringify(data.unternehmensdaten) ],
                { type: 'application/json' }
            ))
            if (data.dokumente instanceof Array && data.dokumente.length) {
                data.dokumente.forEach(file => {
                    if (file instanceof File) {
                        formData.append('dokumente', file)
                    }
                })
                if (typeof data.dokumenteAnmerkungen === 'string' && data.dokumenteAnmerkungen.length) {
                    formData.append('kommentar', new Blob(
                        [ data.dokumenteAnmerkungen ],
                        { type: 'text/plain' }
                    ))
                }
            }
        } catch (e) {
            return new Promise((resolve, reject) => {
                reject({
                    response: {
                        status: 500
                    }
                })
            })
        }
    }
    const headers = buildHeader(data)
    return axios.post(`/api/ubo-backend/unternehmensdaten/${ data.crefonummer }`, formData, {
        headers
    })
}

/**
 * Sends a POST request to send feedback
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const postFeedback = (data) => {
    const headers = buildHeader(data)
    let config = {
        headers
    }
    return axios.post(`/api/ubo-backend/feedbacks/${ data.crefonummer }`, data.payload, config)
}

/**
 * Sends a GET request to receive personal user data
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getCbraUser = () =>
    axios.get(`/cbra/user`)

/**
 * Get User data for current user.
 *
 * @return {Promise<AxiosResponse<T>>}
 */
export const getUser = () => {
    let loginHost = 'login.creditreform.de'
    const regex = new RegExp('(.+)cp\\.meine\\.creditreform\\.')
    const matchingHost = window.location.host.match(regex)
    if (matchingHost && matchingHost[1]) {
        loginHost = matchingHost[1] + 'sso.' + loginHost
    }

    return axios.get(`https://${loginHost}/api/user-management/persons/me`, {
        transformRequest: (data, headers) => {
            transformHeadersForCORS(headers)
            return data
        }
    })
}

/**
 * Sends a GET request to receive personal user data
 *
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const getTestTransactions = () =>
    axios.get('/cbra/transactions?pageSize=3&pageStartIndex=0&sort=OBSERVATION_END_TIME_DESC&transactionType=REPORT&favourites=false&observationType=ALL_OBSERVATION')

/**
 * Send a GET request to fetch matching company
 *
 * @param {string} crefonummer
 * @returns {Promise<AxiosResponse<T>>}
 * @todo Replace api endpoint
 */
export const getKurzprofil = (crefonummer) =>
    axios.get(`/api/ubo/kurzprofil/${ crefonummer }`)

/**
 * Erstellt das Post-Registration-Token für die Registrierung
 *
 * @param {object} data
 * @param {string} data.origin
 * @param {string} data.crefonummer
 * @return {Promise<AxiosResponse<any>>}
 */
export const getPostRegistrationToken = (data) =>
    axios.post('/api/ubo-backend/registrierung-actions/erzeuge-post-registration-token', data)

/**
 * Bestätigt die Registrierung für einen Post-Registration-Token. Im Backend werden dann Crid aus dem Usertoken und das
 * PRT / die Crefo miteinander verknüpft
 *
 * @param {string} postRegistrationToken
 * @return {Promise<AxiosResponse<any>>}
 */
export const doConfirmRegistration = (postRegistrationToken) =>
    axios.post('/api/ubo-backend/registrierung-actions/bestaetige-registrierung', { postRegistrationToken })

/**
 * Die CrId muss mit der crid des angemeldeten Benutzers übereinstimmen
 *
 * @param {string} crid
 * @return {Promise<AxiosResponse<any>>}
 */
export const getCrefonummerByCrid = (crid) =>
    axios.get(`/api/ubo-backend/registrierte-nutzer/${ crid }/crefonummer`)

/**
 * Get Member data for current user.
 *
 * @return {Promise<AxiosResponse<T>>}
 */
export const getMitglied = () =>
    axios.get(`/cbra/members`)

/**
 *
 * @param {object} data
 * @param {string} data.consentRecherche
 *
 */
const buildHeader = (data) => {
    let headers = null
    if (data) {
        headers = {}
        if (data.consentRecherche===true||data.consentRecherche===false) {
            headers.UboConsentRecherche = data.consentRecherche
        }
    }
    return headers
}

/**
 * Transform axios headers for cors requests.
 *
 * @param {Array<string>} headers
 * @return {*[]}
 */
function transformHeadersForCORS (headers = []) {
    headers['Cache-Control'] = 'no-cache'
    delete headers['X-Act-For-Member-Id']
    delete headers['Pragma']
    delete headers['Expires']
    return headers
}
