<template>
    <div class="row ubo-form-step" :class="{ 'ubo-form-step--active': activeFormStep(6), 'has-errors': stepHasErrors }">
        <div class="col-12 d-xl-none ubo-step box-spacing" :class="activeStep(6)">
            <div class="ubo-step-number">
                <span>6</span>
            </div>
            <div class="ubo-step-name" data-qa="menue-mobil-sonstige-unternehmensdaten" @click.prevent="close">Sons­ti­ge Unter­nehmens­daten</div>
            <div class="ubo-step-progress">
                <span class="text-small">{{ fuellgrad.anteilSonstigeUnternehmensdaten }}</span>
            </div>
            <i class="crefo-ui-icon" aria-hidden="true"></i>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="h1">Sons­ti­ge Unter­nehmens­daten</div>
            <p>
                Mit Ihren Angaben verbessern Sie die Datenbasis unserer Firmendatenbank und die Qualität der
                Bonitätsbewertung.
            </p>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter" v-if="inhabergefuehrt===true">
            <div class="ubo-form-mode-view" v-if="!isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-bearbeiten-immobilien" @click.prevent="open">
                        Bearbeiten
                        <i class="crefo-ui-icon icon-edit-pen" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Im­mobilien</div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('immobilien-betriebsraeumeGemietet') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">
                        {{ $t("UboEingabeformularView.Label.ImmobilienBetriebsraeumeGemietet") }}
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ formatter.formatBoolean(this.betriebsraeumeGemietet) }}
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('immobilien-betriebsraeumeFlaeche') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">
                        {{ $t("UboEingabeformularView.Label.ImmobilienBetriebsraeumeFlaeche") }}
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ formatter.formatFlaeche(this.betriebsraeumeFlaeche) }}
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('immobilien-betriebsraeumeMiete') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">
                        {{ $t("UboEingabeformularView.Label.ImmobilienBetriebsraeumeMiete") }}
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ formatter.formatBetrag(this.betriebsraeumeMiete) }}
                    </div>
                </div>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-schliessen-immobilien" @click.prevent="close">
                        Schliessen
                        <i class="crefo-ui-icon icon-close" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Im­mobilien</div>
                <div class="row">
                    <div class="col-12">
                        <ubo-input
                            field="immobilien-betriebsraeumeGemietet"
                            data-qa="ubo-form-sonstige-unternehmensdaten-betriebsraeume-gemietet-message"
                        >
                            <input
                                id="businessPremisesRented"
                                type="checkbox"
                                v-model="betriebsraeumeGemietet"
                                data-no-crefo-ui="true"
                                data-qa="ubo-form-sonstige-unternehmensdaten-betriebsraeume-gemietet"
                            >
                            <label for="businessPremisesRented">{{ $t("UboEingabeformularView.Label.ImmobilienAktivierenWennBetriebsraeumeGemietet") }}</label>
                        </ubo-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <input-field rules="max_amount:9.999.999.999,99"
                                     type="text"
                                     field="immobilien-betriebsraeumeFlaeche"
                                     unit="m²"
                                     :label="$t('UboEingabeformularView.Label.ImmobilienBetriebsraeumeFlaeche')"
                                     lazy
                                     v-model="betriebsraeumeFlaeche"/>

                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field rules="max_amount:9.999.999.999,99"
                                     type="text"
                                     field="immobilien-betriebsraeumeMiete"
                                     unit="EUR"
                                     :label="$t('UboEingabeformularView.Label.ImmobilienBetriebsraeumeMiete')"
                                     lazy
                                     v-model="betriebsraeumeMiete"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb">
                        <button data-qa="ubo-uebernehmen-immobilien" class="btn btn-default btn-min btn-small" :disabled="stepHasErrors"
                                @click.prevent="updateFormData">Änderungen
                            übernehmen
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="ubo-form-mode-view" v-if="!isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-bearbeiten-import" @click.prevent="open">
                        Bearbeiten
                        <i class="crefo-ui-icon icon-edit-pen" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Import</div>
                <div class="row no-gutters ubo-form-display">
                    <div class="col-12 col-lg-4 ubo-form-display-label">
                        {{ $t("UboEingabeformularView.Label.ImportExportImport") }}
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ formatter.formatBoolean(isImport) }}
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('importExport-importAnteil') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">
                        {{ $t("UboEingabeformularView.Label.ImportExportImportAnteil") }}
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ formatter.formatPercent(importAnteil) }}
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" v-for="(landName, index) in importExport.importLand">
                    <div class="col-12 col-lg-4 ubo-form-display-label" :class="{ 'has-errors': hasErrors('importExport-exportLand-' + index) }">
                        <span
                            v-if="index === 0">{{
                                $t("UboEingabeformularView.Label.ImportExportImportLaender")
                            }}</span>
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data" :class="{ 'has-errors': hasErrors('importExport-importLand-' + index) }">
                        {{ landName }}
                    </div>
                </div>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-schliessen-import" @click.prevent="close">
                        Schliessen
                        <i class="crefo-ui-icon icon-close" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Import</div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="crefo-input">
                            <div class="input-group">
                                <div class="radio-group">
                                    <div class="input-wrapper pt-small">
                                        <ValidationProvider
                                            v-slot="{ invalid, changed }">
                                            <p>{{ $t("UboEingabeformularView.Label.ImportExportImport") }}</p>
                                            <div class="crefo-radio">
                                                <div class="radio-btn">
                                                    <input type="radio" id="import-true" name="import"
                                                           value=true
                                                           v-model="isImport"
                                                           data-qa="ubo-form-import-ja"
                                                           data-no-crefo-ui="false">
                                                    <label for="import-true">
                                                        <span class="radio-box"><span
                                                            class="radio-box-circle"></span></span>
                                                        <span class="radio-label--ubo"
                                                              :class="{'has-changed':changed&&isImport===true}"> Ja </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider
                                            v-slot="{ invalid, changed }">
                                            <div class="crefo-radio">
                                                <div class="radio-btn">
                                                    <input type="radio" id="import-false" name="import"
                                                           value=false
                                                           v-model="isImport"
                                                           data-qa="ubo-form-import-nein"
                                                           data-no-crefo-ui="false">
                                                    <label for="import-false">
                                                        <span class="radio-box"><span
                                                            class="radio-box-circle"></span></span>
                                                        <span class="radio-label--ubo"
                                                              :class="{'has-changed':changed&&isImport===false}"> Nein </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field rules="max_percent"
                                     type="text"
                                     field="importExport-importAnteil"
                                     unit="%"
                                     :label="$t('UboEingabeformularView.Label.ImportExportImportAnteil')"
                                     v-model="importAnteil"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8 col-xl-10">
                        <p>{{ $t("UboEingabeformularView.Label.ImportExportImportLaender") }}</p>
                    </div>
                    <div class="col-4 col-xl-2">
                        <button class="btn btn-default btn-min btn-small" data-qa="add-button-import"
                                @click.prevent="addCountry('import')"><i
                            class="crefo-ui-icon icon-collapse-open text-bold"
                            aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div class="row" v-for="(cy, index) in this.importExport.importLand">
                    <div class="col-8 col-xl-10">
                        <input-field rules="required|max:50"
                                     type="text"
                                     :field="'importExport-importLand-'+index"
                                     v-model="importExport.importLand[index]"
                                     checkDirty/>
                    </div>
                    <div class="col-4 col-xl-2">
                        <button class="btn btn-default btn-min btn-small" data-qa="remove-button"
                                @click.prevent="removeCountry('import', index)"><i
                            class="crefo-ui-icon icon-collapse-close text-bold"
                            aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb pt">
                        <button data-qa="ubo-uebernehmen-import" class="btn btn-default btn-min btn-small" :disabled="stepHasErrors"
                                @click.prevent="updateFormData">Änderungen
                            übernehmen
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="ubo-form-mode-view" v-if="!isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-bearbeiten-export" @click.prevent="open">
                        Bearbeiten
                        <i class="crefo-ui-icon icon-edit-pen" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Export</div>
                <div class="row no-gutters ubo-form-display">
                    <div class="col-12 col-lg-4 ubo-form-display-label">
                        {{ $t("UboEingabeformularView.Label.ImportExportExport") }}
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ formatter.formatBoolean(isExport) }}
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('importExport-exportAnteil') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">
                        {{ $t("UboEingabeformularView.Label.ImportExportExportAnteil") }}
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ formatter.formatPercent(exportAnteil) }}
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" v-for="(landName, index) in importExport.exportLand">
                    <div class="col-12 col-lg-4 ubo-form-display-label" :class="{ 'has-errors': hasErrors('importExport-exportLand-' + index) }">
                        <span
                            v-if="index === 0">{{
                                $t("UboEingabeformularView.Label.ImportExportExportLaender")
                            }}</span>
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data" :class="{ 'has-errors': hasErrors('importExport-exportLand-' + index) }">
                        {{ landName }}
                    </div>
                </div>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-schliessen-export" @click.prevent="close">
                        Schliessen
                        <i class="crefo-ui-icon icon-close" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Export</div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="crefo-input">
                            <div class="input-group">
                                <div class="radio-group">
                                    <div class="input-wrapper pt-small">
                                        <ValidationProvider
                                            v-slot="{ invalid, changed }">
                                            <p>{{ $t("UboEingabeformularView.Label.ImportExportExport") }}</p>
                                            <div class="crefo-radio">
                                                <div class="radio-btn">
                                                    <input type="radio" id="export-true" name="export"
                                                           value=true
                                                           v-model="isExport"
                                                           data-qa="ubo-form-export-ja"
                                                           data-no-crefo-ui="false">
                                                    <label for="export-true">
                                                        <span class="radio-box"><span
                                                            class="radio-box-circle"></span></span>
                                                        <span class="radio-label--ubo"
                                                              :class="{'has-changed':changed&&isExport===true}"> Ja </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider
                                            v-slot="{ invalid, changed }">
                                            <div class="crefo-radio">
                                                <div class="radio-btn">
                                                    <input type="radio" id="export-false" name="export"
                                                           value=false
                                                           v-model="isExport"
                                                           data-qa="ubo-form-export-nein"
                                                           data-no-crefo-ui="false">
                                                    <label for="export-false">
                                                        <span class="radio-box"><span
                                                            class="radio-box-circle"></span></span>
                                                        <span class="radio-label--ubo"
                                                              :class="{'has-changed':changed&&isExport===false}"> Nein </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field rules="max_percent"
                                     type="text"
                                     field="importExport-exportAnteil"
                                     unit="%"
                                     :label="$t('UboEingabeformularView.Label.ImportExportExportAnteil')"
                                     v-model="exportAnteil"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8 col-xl-10">
                        <p>{{ $t("UboEingabeformularView.Label.ImportExportExportLaender") }}</p>
                    </div>
                    <div class="col-4 col-xl-2">
                        <button class="btn btn-default btn-min btn-small" data-qa="add-button-export"
                                @click.prevent="addCountry('export')"><i
                            class="crefo-ui-icon icon-collapse-open text-bold"
                            aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div class="row" v-for="(cy, index) in this.importExport.exportLand">
                    <div class="col-8 col-xl-10">
                        <input-field rules="required|max:50"
                                     type="text"
                                     :field="'importExport-exportLand-'+index"
                                     v-model="importExport.exportLand[index]"
                                     checkDirty/>
                    </div>
                    <div class="col-4 col-xl-2">
                        <button class="btn btn-default btn-min btn-small" data-qa="remove-button"
                                @click.prevent="removeCountry('export', index)"><i
                            class="crefo-ui-icon icon-collapse-close text-bold"
                            aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb pt">
                        <button data-qa="ubo-uebernehmen-export" class="btn btn-default btn-min btn-small" :disabled="stepHasErrors"
                                @click.prevent="updateFormData">Änderungen
                            übernehmen
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="ubo-form-mode-view" v-if="!isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-bearbeiten-banken" @click.prevent="open">
                        Bearbeiten
                        <i class="crefo-ui-icon icon-edit-pen" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Banken</div>
                <div class="row no-gutters ubo-form-display" v-for="(iban, index) in this.banken.iban">
                    <div class="col-12 col-lg-4 ubo-form-display-label" :class="{ 'has-errors': hasErrors('banken-iban-' + index) }">
                        <span
                            v-if="index === 0">{{
                                $t("UboEingabeformularView.Label.Bankverbindungen")
                            }}</span>
                    </div>
                    <div class="col-12 col-lg-6 ubo-form-display-data" :class="{ 'has-errors': hasErrors('banken-iban-' + index) }">
                        {{ iban }}
                    </div>
                </div>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-schliessen-banken" @click.prevent="close">
                        Schliessen
                        <i class="crefo-ui-icon icon-close" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Banken</div>
                <div class="row">
                    <div class="col-8 col-xl-10">
                        <p>{{ $t("UboEingabeformularView.Label.Bankverbindungen") }}</p>
                    </div>
                    <div class="col-4 col-xl-2">
                        <button class="btn btn-default btn-min btn-small" data-qa="add-button-banken"
                                @click.prevent="addBank()"><i
                            class="crefo-ui-icon icon-collapse-open text-bold"
                            aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div class="row" v-for="(iban, index) in this.banken.iban">
                    <div class="col-8 col-xl-10">
                        <input-field rules="required|iban"
                                     type="text"
                                     checkDirty
                                     :field="'banken-iban-'+index"
                                     v-model="banken.iban[index]"
                                     placeholder="DE11888888889999999999"/>

                    </div>
                    <div class="col-4 col-xl-2">
                        <button class="btn btn-default btn-min btn-small" data-qa="remove-button"
                                @click.prevent="removeBank(index)"><i
                            class="crefo-ui-icon icon-collapse-close text-bold"
                            aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb pt">
                        <button data-qa="ubo-uebernehmen-banken" class="btn btn-default btn-min btn-small" :disabled="stepHasErrors"
                                @click.prevent="updateFormData">Änderungen
                            übernehmen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as formatter from '@/helper/formatter'
import {ValidationProvider} from 'vee-validate'
import UboRadio from "@/components/commons/ubo-radio";
import InputDate from '@/components/helper/input-date'
import UboInput from '@/components/commons/ubo-input'
import UboSelect from '@/components/commons/ubo-select'
import InputField from '@/components/commons/input-field'
export default {
    name: 'SonstigeUnternehmensdaten',
    components: { ValidationProvider, UboRadio, InputDate, UboInput, UboSelect, InputField },
    props: {
        menuConfig: {},
        errors: {},
        inhabergefuehrt: null,
        fuellgrad: {}
    },
    data() {
        return {
            immobilien: null,
            importExport: null,
            banken: null,
            stepHasErrors: false
        }
    },
    computed: {
        renderEditMode() {
            return (this.menuConfig.edit && this.menuConfig.activeStep === 6)
                || this.$store.state.userModule.unternehmensdatenPreValidated === false
                || this.stepHasErrors
        },
        isEditModeActive() {
            return this.menuConfig.edit && this.menuConfig.activeStep === 6
        },
        hasErrors: function () {
            return fieldname => this.errors && this.errors[fieldname] instanceof Array && this.errors[fieldname].length
        },
        betriebsraeumeGemietet: {
            get() {
                let result = null
                if (this.immobilien) {
                    result = this.immobilien.betriebsraeumeGemietet
                }
                return result
            },
            set(newBetriebsraeumeGemietet) {
                if (this.immobilien) {
                    this.immobilien.betriebsraeumeGemietet = newBetriebsraeumeGemietet
                }
            }
        },
        betriebsraeumeFlaeche: {
            get() {
                let result = null
                if (this.immobilien) {
                    result = formatter.formatAmount(this.immobilien.betriebsraeumeFlaeche)
                }
                return result
            },
            set(newBetriebsraeumeFlaeche) {
                if (this.immobilien) {
                    this.immobilien.betriebsraeumeFlaeche = formatter.parseAmount(newBetriebsraeumeFlaeche)
                }
            }
        },
        betriebsraeumeMiete: {
            get() {
                let result = null
                if (this.immobilien) {
                    result = formatter.formatAmount(this.immobilien.betriebsraeumeMiete)
                }
                return result
            },
            set(newBetriebsraeumeMiete) {
                if (this.immobilien) {
                    this.immobilien.betriebsraeumeMiete = formatter.parseAmount(newBetriebsraeumeMiete)
                }
            }
        },
        isImport: {
            get() {
                let result = null
                if (this.importExport) {
                    result = this.importExport.import
                }
                return result
            },
            set(newImport) {
                if (this.importExport) {
                    this.importExport.import = formatter.parseBoolean(newImport)
                }
            }
        },
        importAnteil: {
            get() {
                let result = null
                if (this.importExport) {
                    result = formatter.formatNumber(this.importExport.importAnteil)
                }
                return result
            },
            set(newImportAnteil) {
                if (this.importExport) {
                    this.importExport.importAnteil = formatter.parseNumber(newImportAnteil)
                }
            }
        },
        isExport: {
            get() {
                let result = null
                if (this.importExport) {
                    result = this.importExport.export
                }
                return result
            },
            set(newExport) {
                if (this.importExport) {
                    this.importExport.export = formatter.parseBoolean(newExport)
                }
            }
        },
        exportAnteil: {
            get() {
                let result = null
                if (this.importExport) {
                    result = formatter.formatNumber(this.importExport.exportAnteil)
                }
                return result
            },
            set(newExportAnteil) {
                if (this.importExport) {
                    this.importExport.exportAnteil = formatter.parseNumber(newExportAnteil)
                }
            }
        },
    },
    methods: {
        activeFormStep(step) {
            return this.menuConfig.activeStep === step
        },
        activeStep(step) {
            let result = null
            if (this.menuConfig.activeStep === step) {
                result = 'ubo-step--active'
            }
            return result
        },
        addCountry(type) {
            if ("import" === type) {
                this.importExport.importLand = [""].concat(this.importExport.importLand)
            } else {
                this.importExport.exportLand = [""].concat(this.importExport.exportLand)
            }

        },
        removeCountry(type, index) {
            if ("import" === type) {
                this.importExport.importLand.splice(index, 1);
            } else {
                this.importExport.exportLand.splice(index, 1);
            }
        },
        addBank() {
            this.banken.iban = [""].concat(this.banken.iban)
        },
        removeBank(index) {
            this.banken.iban.splice(index, 1);
        },
        open() {
            this.restoreFormData()
            this.$emit("edit-step", 6)
        },
        close() {
            this.$emit('select-step', 6)
        },
        restoreFormData() {
            try {
                this.immobilien = JSON.parse(JSON.stringify(this.$store.state.userModule.unternehmensdaten.immobilien))
                this.importExport = JSON.parse(JSON.stringify(this.$store.state.userModule.unternehmensdaten.importExport))
                this.banken = JSON.parse(JSON.stringify(this.$store.state.userModule.unternehmensdaten.banken))
            } catch (e) {}
        },
        updateFormData() {
            this.$store.commit('userModule/ON_CHANGE_REAL_ESTATE', this.immobilien)
            this.$store.commit('userModule/ON_CHANGE_IMPORT_EXPORT', this.importExport)
            this.$store.commit('userModule/ON_CHANGE_BANKEN', this.banken)
            this.$emit('save-step')
            this.$emit("select-step", 6)
        }
    },
    inject: {
        editEventBus: "editEventBus",
        formatter: "formatter"
    },
    created: function () {
        this.editEventBus.$on('has-errors-step-6', (hasErrors) => {
            this.stepHasErrors = hasErrors
        })
        this.restoreFormData()
    }
}
</script>

<!-- Formularkomponenten -->
<style scoped lang="less">
.radio-label--ubo {
    line-height: 30px;
    padding-left: .25rem;
    vertical-align: top;
    color: #4c4c4c;
}

.has-changed {
    color: #8acac1 !important;
}


button[data-qa="add-button-import"],
button[data-qa="add-button-export"],
button[data-qa="add-button-banken"] {
    position: absolute;
    right: 15px;
    min-width: 0;
    padding: 0 4px;
}

button[data-qa="remove-button"] {
    position: absolute;
    top: 41px;
    right: 15px;
    min-width: 0;
    padding: 0 4px;
}


.ubo-form-chapter {
    display: none;
    position: relative;
    border-bottom: 1px solid #edece5;

    &:last-child {
        padding-bottom: 30px !important;

        @media only screen and (min-width: 1200px) {
            padding-bottom: 40px !important;
            border-bottom: 0 none transparent;
        }
    }

    .h2 {
        padding-right: 140px;
    }

    .ubo-form-edit {
        position: absolute;
        top: 33px;
        right: 17px;
        width: 130px;
        height: 20px;
        font-weight: 600;
        text-align: right;

        @media only screen and (max-width: 1199px) {
            top: 23px;
        }
        @media only screen and (max-width: 767px) {
            top: 18px;
        }

        a {
            text-transform: uppercase;

            i {
                position: relative;
                top: 5px;
            }
        }
    }

    .ubo-form-display {
        align-items: flex-end;

        &:last-child {
            margin-bottom: 30px;
        }

        .ubo-form-display-label, .ubo-form-display-data {
            padding-top: 30px;
            padding-bottom: 15px;
            border-bottom: 1px solid #edece5;

            @media only screen and (max-width: 991px) {
                border-bottom: 0 none transparent;
            }
        }

        .ubo-form-display-label {
            padding-right: 15px;

            @media only screen and (max-width: 991px) {
                padding-bottom: 5px;
            }
        }

        .ubo-form-display-data {
            font-weight: 600;

            .crefo-brand-icon {
                color: #b5b5b5;
                font-size: 12px;
            }

            @media only screen and (max-width: 991px) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    .ubo-form-mode-edit {
        .ubo-input-wrapper-label {
            margin-bottom: -2px;

            strong {
                font-size: 20px;
                color: #004884;
            }
        }

        .crefo-input {
            margin-bottom: 0;

            .input-group {
                margin-bottom: 10px;

                input {
                    &::placeholder {
                        color: #b5b5b5;
                    }
                }
            }
        }
    }
}


.ubo-form-step {
    &--active {
        .ubo-form-chapter {
            display: block;
        }
    }
}
</style>
