<template>
    <div class="row">
        <div class="col-12">
            <h1 data-qa="teaser-intro">
                Das könnte Sie auch interessieren
            </h1>
        </div>
        <div class="col-12 col-lg-4 mb">
            <div
                class="box-shadow-xy"
                data-qa="teaser-meine-bonitaet"
            >
                <div class="image-box">
                    <img
                        src="@/assets/svg/service-meine-bonitaet.svg"
                        width="350"
                        height="242"
                        alt=""
                    >
                </div>
                <div class="box-spacing bg-white">
                    <div class="content-box">
                        <p class="h2 mb-small">
                            Meine Bonität
                        </p>
                        <p>
                            Mit dem kostenpflichten Abonnement halten Sie die Bonität Ihres Unternehmens permanent
                            im Blick – und das bereits ab 10 Euro im Monat.
                        </p>
                        <router-link
                            to="/portal/meine-bonitaet"
                            class="link-icon-next"
                        >
                            <span>Mehr erfahren</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-4 mb">
            <div
                class="box-shadow-xy"
                data-qa="teaser-my-esg"
            >
                <div class="image-box">
                    <img
                        src="@/assets/svg/service-my-esg.svg"
                        width="270"
                        height="236"
                        alt=""
                        style="position: relative; top: 15px;"
                    >
                </div>
                <div class="box-spacing bg-white">
                    <div class="content-box">
                        <p class="h2 mb-small">
                            Meine Nachhaltigkeit prüfen
                        </p>
                        <p>
                            Beantworten Sie den ESG-Fragebogen und erfahren Sie, wie nachhaltig Ihr
                            Unternehmen aufgestellt ist.
                        </p>
                        <router-link
                            to="/portal/myesg"
                            class="link-icon-next"
                        >
                            <span>Mehr erfahren</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-4 mb">
            <div
                class="box-shadow-xy"
                data-qa="teaser-firmeneintraege"
            >
                <div class="image-box">
                    <img
                        src="@/assets/svg/service-firmeneintraege.png"
                        width="700"
                        height="352"
                        alt=""
                    >
                </div>
                <div class="box-spacing bg-white">
                    <div class="content-box">
                        <p class="h2 mb-small">
                            Firmeneinträge
                        </p>
                        <p>
                            Sie suchen Informationen zu einem Unternehmen? Mit über 88 Mio. Wirtschaftsinformationen
                            sind wir Ihr Experte für topaktuelle Firmendaten.
                        </p>
                        <a
                            href="https://firmeneintrag.creditreform.de/"
                            class="link-icon-next"
                            target="_blank"
                        >
                            <span>Mehr erfahren</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TrippleTeaser'
    }
</script>

<style scoped lang="less">
    .image-box {
        display: flex;
        height: 175px;
        max-height: 175px;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        background-color: #f6f6f2;
    }

    .content-box {
        position: relative;
        min-height: 222px;

        .link-icon-next {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
</style>
