import './set-public-path'
import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'

import App from './App.vue'

import router from './router'
import i18n from './i18n'
import store from './store'

import CrefoUiVue from '@dm/crefo-ui-vue'

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(CrefoUiVue)

const vueLifecycles = singleSpaVue({
    Vue,
    appOptions: {
        render: (h) => h(App),
        router,
        store,
        i18n
    }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
