<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h1>Erfahren Sie mehr über Creditreform und unsere Erfolgsfaktoren</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-4 mb">
                <div class="box-shadow-xy box-spacing bg-white">
                    <h3>
                        Eine große Community für mehr Sicherheit
                    </h3>
                    <p>
                        Die 157.000 Creditreform Mitglieder verlassen sich nicht auf ihr Bauchgefühl und fragen
                        jährlich über 15 Mio. Auskünfte ab.
                    </p>
                    <a
                        :href="this.$t('URL.UeberCreditreform')"
                        class="link-icon-next"
                        role="button"
                        data-qa="ueber-creditreform"
                    >
                        <span>{{ this.$t('ActivationView.Label.Infos') }}</span>
                    </a>
                </div>
            </div>
            <div class="col-12 col-lg-4 mb">
                <div class="box-shadow-xy box-spacing bg-white">
                    <h3>
                        Unsere Quellen - alles außer gewöhnlich!
                    </h3>
                    <p>
                        Wir kombinieren modernste Technologie und individuelle Recherche um den größtmöglichen
                        Wissensvorsprung und die höchste Qualität zu bieten.
                    </p>
                    <a
                        :href="this.$t('URL.UnsereQuellen')"
                        class="link-icon-next"
                        role="button"
                        data-qa="ueber-creditreform"
                    >
                        <span>{{ $t('ActivationView.Label.Infos') }}</span>
                    </a>
                </div>
            </div>
            <div
                v-if="page==='UboBestaetigungView'"
                class="col-12 col-lg-4 mb"
            >
                <div class="box-shadow-xy box-spacing bg-white">
                    <h3>
                        Das passiert mit Ihren Angaben
                    </h3>
                    <p>
                        Wozu brauchen wir die Daten aus der Unternehmensbefragung? Und wie werden diese verarbeitet?
                        Wir geben Ihnen Einblick in die Tätigkeit unserer Analysten: „How to Crefo”!
                    </p>
                    <a
                        :href="$t('URL.IhreAngaben')"
                        class="link-icon-next"
                        role="button"
                        data-qa="ueber-creditreform"
                    >
                        <span>{{ $t('ActivationView.Label.Infos') }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /**
     *
     *  Show information about sucess factors
     *
     */

    export default {
        name: 'Erfolgsfaktoren',
        props: {
            page:{
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped lang="less">
    .box-shadow-xy {
        height: 400px;

        a {
            position: absolute;
            right: 45px;
            bottom: 30px;
        }
    }

    @media only screen and (max-width: 991px) {
        .box-shadow-xy {
            height: auto;
            padding-bottom: 50px!important;
        }
    }
</style>
