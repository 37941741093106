<template>
    <div class="row mb-large">
        <div class="col-12 col-xl-8">
            <h2>Ihre Vorteile</h2>
            <ul class="list-unordered list-checked">
                <li>
                    <strong>Transparenz:</strong>
                    Erfahren Sie, welche Daten über Ihr Unternehmen gespeichert sind.
                </li>
                <li>
                    <strong>Kontrolle:</strong>
                    Berichtigen Sie falsche und ergänzen Sie unvollständige Daten.
                    Damit tragen Sie zu einer besseren Bonitätsbewertung bei.
                </li>
                <li>
                    <strong>Eigenwerbung:</strong>
                    Nutzen Sie Ihre gute Bonität und kommunizieren Sie diese, egal ob bei
                    Finanzierungsgesprächen oder gegenüber Ihren Geschäftspartnern.
                </li>
            </ul>
        </div>
        <div class="col-12 col-xl-4">
            <div class="crefo-ui-alert info">
                <strong class="h3 text-white">7 von 10</strong>
                Wirtschaftsauskünften in Deutschland kommen von Creditreform. Mit jeder Anfrage zu einem
                Unternehmen überprüfen wir unsere Daten und deren Bewertung auf Aktualität. Dadurch ist
                unsere Datenbank nicht nur qualitativ, sondern auch im Hinblick auf die Anzahl der verfügbaren
                Datensätze führend.
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UboLandingpageVorteileText'
    }
</script>

<style scoped lang="less">
    .h3 {
        margin: 0;
        display: inline;
        line-height: 18px;
    }
</style>
