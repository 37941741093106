<template>
    <div class="container">
        <div class="row mb">
            <div class="col-12">
                <div class="h2">Ihre Vorteile</div>
            </div>
            <div class="col-12 col-md-4">
                <!-- p>
                    Zombie ipsum reversus ab viral inferno, nam rick grimes malum cerebro. De carne lumbering animata
                    corpora quaeritis. Summus brains sit, morbo vel maleficia? De apocalypsi gorger omero undead
                    survivor dictum mauris.
                </p -->
                <ul class="list-unordered list-checked">
                    <li>Transparenz: Erfahren Sie, welche Daten über Ihr Unternehmen gespeichert sind.</li>
                </ul>
            </div>
            <div class="col-12 col-md-4">
                <ul class="list-unordered list-checked">
                    <li>Kontrolle: Berichtigen Sie falsche und ergänzen Sie unvollständige Daten.</li>
                </ul>
            </div>
            <div class="col-12 col-md-4">
                <!-- ul class="list-unordered list-checked">
                    <li>Vorteil lorem ipsum dolor</li>
                    <li>Lorem ipsum dolor sit amet</li>
                </ul -->
                <ul class="list-unordered list-checked">
                    <li>Eigenwerbung: Nutzen Sie Ihre gute Bonität und kommunizieren Sie diese.</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

/**
 *
 */

export default {
    name: 'Fuss',
    props: {
        scrollToStart: null,
        getProgressCoordinates: null
    }
}
</script>
