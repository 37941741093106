import * as formatter from "@/helper/formatter";
import i18n from "@/i18n";
import * as keys from "@/helper/keys";

/**
 *
 * @param {Array} obj
 * @return {Array}
 *
 */
export const reduceEmployeeType = (mitarbeitertyp) => {
    let result
    if (mitarbeitertyp) {
        result = mitarbeitertyp.filter(et => [ "1", "2", "3", "4", "5", "6" ].includes(et.mitarbeiterart))
        const inhaber = mitarbeitertyp.filter(et => [ "7", "8", "9", "10", "11", "12", "14", "15", "16", "17", "18" ].includes(et.mitarbeiterart))
        const gesamtanzahlInhaber = inhaber.reduce(function (accumulator, et) {
            return accumulator + et.anzahl;
        }, 0)
        if (gesamtanzahlInhaber !== 0) {
            result = result.concat({ mitarbeiterart: "7", anzahl: gesamtanzahlInhaber })
        }
    } else {
        result = []
    }
    return result
}

export const umsatzBuilder = (jahr, betrag, betragVorjahr, umsatzerwartung) => {
    const umsatz = {
        jahr: formatter.parseNumber(jahr),
        betrag: formatter.parseAmount(betrag),
        betragVorjahr: formatter.parseAmount(betragVorjahr),
        umsatzerwartung
    }
    return umsatz
}

export const communicationNumberBuilder = (land, vorwahl, nummer, durchwahl) => {
    let communicationNumber = null
    if ((land !== null && land.length) ||
        (vorwahl !== null && vorwahl.length) ||
        (nummer !== null && nummer.length) ||
        (durchwahl !== null && durchwahl.length)) {
        communicationNumber = {
            land,
            vorwahl,
            nummer,
            durchwahl
        }
    }
    return communicationNumber
}

const punkteFirmenidentifikation = (firmenidentifikation, inhabergefuehrt) => {
    let punkte = 0
    if (firmenidentifikation) {

        if (inhabergefuehrt === true) {
            if (firmenidentifikation.firmierung) {
                punkte += 1
            }
            if (firmenidentifikation.adressdaten) {
                const adressdaten = firmenidentifikation.adressdaten
                if (adressdaten.strasse !== null) {
                    punkte += 1
                }
                if (adressdaten.plz !== null) {
                    punkte += 1
                }
                if (adressdaten.ort !== null) {
                    punkte += 1
                }
                if (adressdaten.hausnummer !== null || adressdaten.hausnummerZusatz !== null) {
                    punkte += 1
                }
            }
        }
        if (firmenidentifikation.telefonnummer !== null) {
            const kommunikationsnummer = firmenidentifikation.telefonnummer
            if (kommunikationsnummer.vorwahl !== null && kommunikationsnummer.nummer !== null) {
                punkte += 1
            }
        }
        if (firmenidentifikation.mobilnummer !== null) {
            const kommunikationsnummer = firmenidentifikation.mobilnummer
            if (kommunikationsnummer.vorwahl !== null && kommunikationsnummer.nummer !== null) {
                punkte += 1
            }
        }
        if (firmenidentifikation.faxnummer !== null) {
            const kommunikationsnummer = firmenidentifikation.faxnummer
            if (kommunikationsnummer.vorwahl !== null && kommunikationsnummer.nummer !== null) {
                punkte += 0.5
            }
        }
        if (firmenidentifikation.emailAdresse !== null) {
            punkte += 1
        }
        if (firmenidentifikation.internetAdresse !== null) {
            punkte += 1
        }
        if (firmenidentifikation.umsatzsteuerIdentnummer !== null) {
            punkte += 1
        }
        if (firmenidentifikation.steuernummer !== null) {
            punkte += 1
        }
    }
    return punkte
}

const punkteInhaber = (inhaber) => {
    let punkte = 0
    if (inhaber) {
        if (inhaber.vorname !== null) {
            punkte += 1
        }
        if (inhaber.nachname !== null) {
            punkte += 1
        }
        if (inhaber.geburtsdatum !== null && inhaber.geburtsdatum.jahr !== null) {
            punkte += 1
        }
        if (inhaber.adresse) {
            const adresse = inhaber.adresse
            if (adresse.land !== null) {
                punkte += 1
            }
            if (adresse.plz !== null) {
                punkte += 1
            }
            if (adresse.ort !== null) {
                punkte += 1
            }
            if (adresse.strasse !== null) {
                punkte += 1
            }
            if (adresse.hausnummer !== null || adresse.hausnummerZusatz !== null) {
                punkte += 1
            }
        }
    }
    return punkte
}
const punkteRechtsformGeschaeftstaetigkeit = (rechtsform, geschaeftstaetigkeit) => {
    let punkte = 0
    if (rechtsform) {
        if (rechtsform.inhabergefuehrt === true) {
            if (rechtsform.gewerblichRegistriert !== null) {
                punkte += 1
            }
        }
    }
    if (geschaeftstaetigkeit) {
        if (rechtsform.inhabergefuehrt === true) {
            if (geschaeftstaetigkeit.eingetragenerGegenstand !== null) {
                punkte += 1
            }
        }
        if (geschaeftstaetigkeit.anzahlKunden !== null) {
            punkte += 1
        }
        if (geschaeftstaetigkeit.prozentanteilPrivatkunden !== null) {
            punkte += 1
        }
        if (geschaeftstaetigkeit.prozentanteilOeffentlicheHand !== null) {
            punkte += 1
        }
        if (geschaeftstaetigkeit.anzahlLieferanten !== null) {
            punkte += 1
        }
    }
    return punkte
}

const punkteUmsatzangabenMitarbeiter = (umsatz, mitarbeiter) => {
    let punkte = 0
    if (umsatz) {
        if (umsatz.jahr !== null) {
            punkte += 1
        }
        if (umsatz.betrag !== null) {
            punkte += 1
        }
        if (umsatz.betragVorjahr !== null) {
            punkte += 1
        }
        if (umsatz.umsatzerwartung !== null) {
            punkte += 1
        }
    }
    if (mitarbeiter) {
        if (mitarbeiter.jahr !== null) {
            punkte += 1
        }
        if (mitarbeiter.anzahlGesamt !== null) {
            punkte += 1
        }
        if (mitarbeiter.anzahlGesamtVorjahr !== null) {
            punkte += 1
        }
        if (mitarbeiter.mitarbeitertyp !== null) {
            const reducedMitarbeitertyp = reduceEmployeeType(mitarbeiter.mitarbeitertyp)
            reducedMitarbeitertyp.forEach((mitarbeitertyp) => {
                if ([ "1", "2", "3", "4", "5", "6", "7" ].includes(mitarbeitertyp.mitarbeiterart)) {
                    if (mitarbeitertyp.anzahl && mitarbeitertyp.anzahl !== null) {
                        punkte += 1
                    }
                }
            })
        }
    }
    return punkte
}

const punkteUnternehmensdaten = (immobilien, importExport, banken) => {
    let punkte = 0
    if (immobilien) {
        if (immobilien.betriebsraeumeGemietet !== null) {
            punkte += 1
        }
        if (immobilien.betriebsraeumeFlaeche !== null) {
            punkte += 1
        }
        if (immobilien.betriebsraeumeMiete !== null) {
            punkte += 1
        }
    }
    if (importExport) {
        if (importExport.import !== null) {
            punkte += 1
            if (importExport.import === true) {
                if (importExport.importAnteil !== null) {
                    punkte += 1
                }
                if (importExport.importLand !== null && importExport.importLand.length !== 0) {
                    punkte += 1
                }
            }
        }
        if (importExport.export !== null) {
            punkte += 1
            if (importExport.export === true) {
                if (importExport.exportAnteil !== null) {
                    punkte += 1
                }
                if (importExport.exportLand !== null && importExport.exportLand.length !== 0) {
                    punkte += 1
                }
            }
        }
    }
    if (banken) {
        if (banken !== null && banken.iban !== null && banken.iban.length !== 0) {
            punkte += 1
        }
    }
    return punkte
}

const punkteAnsprechpartner = (ansprechpartner) => {
    let punkte = 0
    if (ansprechpartner) {
        if (ansprechpartner.anrede !== null) {
            punkte += 1
        }
        if (ansprechpartner.name !== null) {
            punkte += 1
        }
        if (ansprechpartner.funktion !== null) {
            punkte += 1
        }
        if (ansprechpartner.telefon !== null) {
            punkte += 1
        }
        if (ansprechpartner.mobil !== null) {
            punkte += 1
        }
        if (ansprechpartner.email !== null) {
            punkte += 1
        }
        if (ansprechpartner.fax !== null) {
            punkte += 1
        }
    }
    return punkte
}

const punkteGesamt = (unternehmensdaten) => {
    return (punkteFirmenidentifikation(unternehmensdaten.firmenidentifikation, unternehmensdaten.rechtsform.inhabergefuehrt) + punkteInhaber(unternehmensdaten.inhaber) + punkteRechtsformGeschaeftstaetigkeit(unternehmensdaten.rechtsform, unternehmensdaten.geschaeftstaetigkeit) + punkteUmsatzangabenMitarbeiter(unternehmensdaten.umsatz, unternehmensdaten.mitarbeiter) + punkteUnternehmensdaten(unternehmensdaten.immobilien, unternehmensdaten.importExport, unternehmensdaten.banken) + punkteAnsprechpartner(unternehmensdaten.ansprechpartner))


}


const punkteMaxBuilder = (unternehmensdaten) => {

    const punkteMax = {
        // firmierung 1 adresse 4 kontakt 4.5 steuer 2
        firmenidentifikation: 6.5,
        inhaber: 8.0,
        rechtsformGeschaeftstaetigkeit: 4,
        umsatzangabenMitarbeiter: 14,
        unternehmensdaten: 6,
        ansprechpartner: 7,
        gesamt: 0
    }

    if (unternehmensdaten.rechtsform) {
        if (unternehmensdaten.rechtsform.inhabergefuehrt === true) {
            punkteMax.firmenidentifikation += 5
            punkteMax.rechtsformGeschaeftstaetigkeit += 2
        }
    }

    if (unternehmensdaten.importExport) {
        if (unternehmensdaten.importExport.import !== null) {
            if (unternehmensdaten.importExport.import === true) {
                punkteMax.unternehmensdaten += 2
            }
        }
        if (unternehmensdaten.importExport.export !== null) {
            if (unternehmensdaten.importExport.export === true) {
                punkteMax.unternehmensdaten += 2
            }
        }
    }
    punkteMax.gesamt = punkteMax.firmenidentifikation + punkteMax.inhaber + punkteMax.rechtsformGeschaeftstaetigkeit + punkteMax.umsatzangabenMitarbeiter + punkteMax.unternehmensdaten + punkteMax.ansprechpartner
    return punkteMax
}

export const fuellgradBuilder = (unternehmensdaten, dokumente, dokumenteAnmerkungen) => {

    const punkteMax = punkteMaxBuilder(unternehmensdaten)
    const fuellgrad = {
        anteilFirmenidentifikation: (punkteFirmenidentifikation(unternehmensdaten.firmenidentifikation, unternehmensdaten.rechtsform.inhabergefuehrt) * 100.0 / punkteMax.firmenidentifikation).toFixed(),
        anteilInhaber: (punkteInhaber(unternehmensdaten.inhaber) * 100.0 / punkteMax.inhaber).toFixed(),
        anteilRechtsformGeschaeftstaetigkeit: (punkteRechtsformGeschaeftstaetigkeit(unternehmensdaten.rechtsform, unternehmensdaten.geschaeftstaetigkeit) * 100.0 / punkteMax.rechtsformGeschaeftstaetigkeit).toFixed(),
        anteilUmsatzangaben: (punkteUmsatzangabenMitarbeiter(unternehmensdaten.umsatz, unternehmensdaten.mitarbeiter) * 100.0 / punkteMax.umsatzangabenMitarbeiter).toFixed(),
        anteilUnterlagen: dokumente.length > 0 ? (100).toFixed() : 0,
        anteilSonstigeUnternehmensdaten: (punkteUnternehmensdaten(unternehmensdaten.immobilien, unternehmensdaten.importExport, unternehmensdaten.banken) * 100.0 / punkteMax.unternehmensdaten).toFixed(),
        anteilAnsprechpartner: (punkteAnsprechpartner(unternehmensdaten.ansprechpartner) * 100.0 / punkteMax.ansprechpartner).toFixed(),
        anteilGesamt: (punkteGesamt(unternehmensdaten) * 100.0 / punkteMax.gesamt).toFixed(),
    }
    return fuellgrad

}

