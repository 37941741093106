<template>
    <div>
        <div class="container">
            <ubo-landingpage-intro-text />
            <ubo-landingpage-suche-unternehmen @company-selected="onCompanySelected" />
        </div>
        <div class="mb">
            <ubo-suggested-kurzprofil
                v-if="crefonummer"
                :crefonummer="crefonummer"
                :show-header="false"
                v-on:crefo-kurzprofil-fetching-success="onKurzprofilAvailable"
                v-on:crefo-kurzprofil-fetching-error="onKurzprofilNoData"
                v-on:crefo-kurzprofil-fetching="onKurzprofilFetching"
            />
            <div
                v-if="crefonummer && kurzprofilData"
                class="bg-brand bg-brand-light-grey do-request-access"
            >
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-10">
                            <template v-if="isUboAvailable">
                                <p>
                                    <strong>Jetzt in Ihre Unternehmensbefragung einsteigen</strong><br>
                                    Zum Schutz Ihres Unternehmens müssen Sie sich zunächst registrieren und als vertretungsberechtigt verifizieren:
                                </p>
                                <button
                                    class="btn btn-default"
                                    data-qa="authorize-option-digital"
                                    @click.prevent="onRegisterForUbo"
                                >
                                    Jetzt für Unternehmensbefragung registrieren
                                </button>
                            </template>
                            <template v-if="kurzprofilLoaded && !isUboAvailable">
                                <div
                                    class="crefo-ui-alert info"
                                    data-qa="authorize-option-not-available"
                                >
                                    Die Unternehmensbefragung ist nur für Unternehmen mit Sitz in {{ allowedCountriesText }} verfügbar. Für eine
                                    Aktualisierung Ihrer Unternehmensdaten mit Sitz außerhalb kontaktieren Sie uns bitte.
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <ubo-landingpage-unternehmen-nicht-gefunden />
            <ubo-landingpage-drei-schritte-text :active-step="1" />
            <ubo-landingpage-vorteile-text />
        </div>
    </div>
</template>

<script>
    import UboLandingpageIntroText from '../components/landingpage/intro-text'
    import UboLandingpageSucheUnternehmen from '../components/landingpage/suche-unternehmen'
    import UboSuggestedKurzprofil from '../components/kurzprofil/suggested-kurzprofil'
    import UboLandingpageUnternehmenNichtGefunden from '../components/landingpage/unternehmen-nicht-gefunden'
    import UboLandingpageDreiSchritteText from '../components/landingpage/drei-schritte-text'
    import UboLandingpageVorteileText from '../components/landingpage/vorteile-text'
    import { mapState } from 'vuex'
    import GeneralUtilityHelper from '@/helper/general'
    import * as api from '../services/index.js'
    export default {
        name: 'UboLandingpageView',
        components: {
            UboLandingpageIntroText,
            UboLandingpageSucheUnternehmen,
            UboSuggestedKurzprofil,
            UboLandingpageUnternehmenNichtGefunden,
            UboLandingpageDreiSchritteText,
            UboLandingpageVorteileText
        },
        props: {
            preselectedCrefonummer: {
                type: String,
                required: false,
                default: ''
            }
        },
        data () {
            return {
                crefonummer: this.preselectedCrefonummer,
                kurzprofilData: null,
                kurzprofilLoaded: false
            }
        },
        computed: {
            ...mapState('userModule', [ 'authenticated' ]),
            isUboAvailable () {
                return this.kurzprofilData
                    && this.kurzprofilData.adresse
                    && GeneralUtilityHelper.isCountryAllowed(this.kurzprofilData.adresse.land)
            },
            allowedCountriesText () {
                let allowedCountriesText = GeneralUtilityHelper.getAllowedCountriesByName(this.$store.state.userModule.language).join(', ')
                const lastComma = allowedCountriesText.lastIndexOf(', ')
                if (lastComma > 0) {
                    return allowedCountriesText.substring(0, lastComma) + ' und ' + allowedCountriesText.substring(lastComma + 2)
                } else {
                    return allowedCountriesText
                }
            }
        },
        watch: {
            authenticated: function (newValue, oldValue) {
                if (newValue && !oldValue) {
                    this.$store.commit('userModule/ON_SET_USER_STATE_DETERMINED', false)
                    this.$router.push({ name: 'index' })
                }
            }
        },
        methods: {
            onCompanySelected (crefonummer) {
                this.crefonummer = crefonummer
            },
            onKurzprofilAvailable (kurzprofilData) {
                this.kurzprofilData = kurzprofilData
                this.kurzprofilLoaded = true
            },
            onKurzprofilNoData () {
                this.kurzprofilData = null
                this.kurzprofilLoaded = true
            },
            onKurzprofilFetching () {
                this.kurzprofilData = null
                this.kurzprofilLoaded = false
            },
            async onRegisterForUbo () {
                let loginHost = 'login.creditreform.de'
                const regex = new RegExp('(.+)cp\\.meine\\.creditreform\\.')
                const matchingHost = window.location.host.match(regex)
                if (matchingHost && matchingHost[1]) {
                    loginHost = matchingHost[1] + 'sso.' + loginHost
                }

                let prt = '7be9491a-d337-4bf1-8ca1-fd1b2e286958'
                try {
                    const response = await api.getPostRegistrationToken({
                        origin: window.location.origin + window.location.pathname,
                        crefonummer: this.crefonummer
                    })
                    if (response && response.data && typeof response.data['post-registration-token'] === 'string') {
                        prt = response.data['post-registration-token']
                    }
                } catch (error) {
                    console.log('Could not fetch post registration token')
                }

                if (this.crefonummer.length) {
                    window.location.href =
                        `https://${loginHost}/benutzerverwaltung/registrieren?prk=UBO&prt=${prt}&crefonummer=${this.crefonummer}`
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .do-request-access {
        margin-top: -15px;
        padding: 0 30px 30px 30px;

        @media only screen and (min-width: 768px) {
            margin-top: -30px;
            padding: 0 40px 40px 40px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: -40px;
            padding: 0 60px 60px 60px;
        }
    }

    .mt-small {
        margin-top: 7.5px;

        @media only screen and (min-width: 768px) {
            margin-top: 10px;
        }

        @media only screen and (min-width: 1200px) {
            margin-top: 15px;
        }
    }

    .btn-purple {
        background-color: #a763a4;

        &:hover {
            background-color: #894d87;
        }
    }
</style>
