<template>
    <div>
        <div
            class="row"
            :class="{ 'align-items-end': !top, 'align-items-start': top }"
            data-qa="ubo-form-senden-container"
        >
            <div class="col-12 col-xl-6">
                <slot></slot>
            </div>
            <div class="col-12 col-xl-6 mb">
                <div class="row">
                    <div class="col"></div>
                    <div class="col col-auto ubo-ssl text-brand-grey">
                        <i class="crefo-brand-icon icon-lock icon-color-white" aria-hidden="true"></i>
                        <p>SSL-Zertifikat</p>
                        <p>Sichere Verbindung</p>
                    </div>
                    <div class="col col-auto">
                        <button class="btn btn-default btn-icon-next" :disabled="isButtonDisabled" @click="startSendUbo"
                                data-qa="ubo-form-senden"><span>{{
                                $t('UboEingabeformularView.Button.Absenden')
                            }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <modal-dialog :confirmLabel="$t('UboEingabeformularView.ModalDialog.ConsentRecherche.Label')"
                      :cancelLabel="$t('UboEingabeformularView.ModalDialog.Cancel.Label')"
                      :confirm-callback="sendUbo"
                      :cancel-callback="cancel"
                      :confirmButtonDisabled="confirmButtonDisabled"
                      :visible="showModal"
                      :show-spinner="formSend"
        >
            <div class="row">
                <div class="col-12" v-if="!(initialConsentRecherche===true)">
                    <ubo-input
                        field="consent-investigation"
                        data-qa="ubo-form-zustimmung-recherche-message"
                    >
                        <input
                            id="consent-investigation"
                            type="checkbox"
                            v-model=consentRecherche
                            data-no-crefo-ui="true"
                            data-qa="ubo-form-zustimmung-recherche">
                        <label for="consent-investigation" data-qa="consent-investigation-text">Ich bin einverstanden,
                            dass Creditreform mich kontaktiert, um mit mir gemeinsam meine Unternehmensdaten zu prüfen
                            und zu ergänzen. Damit lege ich die Basis für meine eigene Bonitätsbewertung. Meine
                            Zustimmung kann ich jederzeit widerrufen.
                        </label>
                    </ubo-input>
                </div>
                <div class="col-12">
                    <ubo-input
                        field="term-of-service"
                        data-qa="ubo-form-agb-akzeptieren-message"
                        class="no-margin"
                    >
                        <input
                            id="term-of-service-2"
                            type="checkbox"
                            v-model=acceptTermOfService
                            data-no-crefo-ui="true"
                            data-qa="ubo-form-agb-akzeptieren">
                        <label for="term-of-service-2" data-qa="accept-term-of-service-text">Ich habe die <a
                            :href="href"
                            target="_blank"
                            data-qa="agb-download-link"><span>Datenschutzerklärung</span></a> zur Kenntnis
                            genommen und bin damit einverstanden, dass meine Daten zum Zweck der
                            Beantwortung meines Anliegens gespeichert und verwendet werden.
                        </label>
                    </ubo-input>
                </div>
                <div class="col-12">
                    <crefo-error-message
                        v-if="ajaxError !== null"
                        :ajax-error="ajaxError"
                        data-qa="ubo-activation-error"
                    >
                    </crefo-error-message>
                </div>
            </div>
        </modal-dialog>
    </div>
</template>
<script>

/**
 *
 * This Component handles 'Term Of Service', rehandles 'Login Code' and sends UBO.
 *
 * Shows an error message based on given ajaxError Object and optional custom error message.
 *
 */
import {mapState} from "vuex"
import UboInput from '@/components/commons/ubo-input'
import ModalDialog from '@/components/helper/modal-dialog'
import CrefoErrorMessage from '../helper/crefo-error'
export default {
    name: 'Absenden',
    components: {UboInput, ModalDialog, CrefoErrorMessage},
    props: {
        isInvalid: true,
        top: false
    },
    data() {
        return {
            showModal: false,
            acceptTermOfService: false,
            initialConsentRecherche: null,
            code: null,
            formSend: false
        }
    },
    computed: {
        ...mapState('userModule', [
            'crefonummer', 'dokumente', 'dokumenteAnmerkungen', 'unternehmensdaten', 'consentRecherche',
            'unternehmensdatenSending', 'ajaxError']),
        consentRecherche: {
            get() {
                return this.$store.state.userModule.consentRecherche
            },
            set(newConsentRecherche) {
                this.$store.commit('userModule/ON_CHANGE_CONSENT_RECHERCHE', newConsentRecherche)
            }
        },
        isButtonDisabled: function () {
            return this.formSend || this.isInvalid || this.showModal
        },
        termOfServiceNotAccepted: function () {
            return this.acceptTermOfService === false
        },
        href() {
            let link = ''
            if (this.unternehmensdaten.kopfdaten) {
                let clz = this.unternehmensdaten.kopfdaten.clz ? this.unternehmensdaten.kopfdaten.clz : ''
                link = "https://www.creditreform.de/" + clz + "/eu-dsgvo"
            } else {
                link = "https://www.creditreform.de/eu-dsgvo"
            }
            return link
        },
        confirmButtonDisabled() {
            return this.formSend || this.acceptTermOfService === false
        }
    },
    methods: {
        startSendUbo() {
            this.showModal = true
        },
        cancel() {
            this.showModal = false
            this.$store.commit('userModule/UPDATE_UBO_SUCCESS')
        },
        async sendUbo(event) {
            if (this.acceptTermOfService === true && !this.isInvalid) {
                if (this.formSend) {
                    return
                }
                this.formSend = true
                const payload = {
                    crefonummer: this.crefonummer,
                    dokumente: this.dokumente,
                    dokumenteAnmerkungen: this.dokumenteAnmerkungen,
                    unternehmensdaten: this.unternehmensdaten,
                    consentRecherche: this.consentRecherche
                }

                await this.$store.dispatch('userModule/updateBusinessdata', payload)
                if (this.ajaxError === null) {
                    this.showModal = false
                    this.$router.push({ name: 'bestaetigung'})
                }
                this.formSend = false
            }
        }
    },
    created() {
        this.initialConsentRecherche = this.consentRecherche
    }
}
</script>

<style scoped lang="less">
    .crefo-checkbox-error {
        & > input[type="checkbox"] + label:before {
            border-color: #e05469;
        }
    }

    .no-margin {
        margin-bottom: 0;
    }
</style>

