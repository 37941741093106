<template>
    <div class="row ubo-form-step" :class="{ 'ubo-form-step--active': activeFormStep(1), 'has-errors': stepHasErrors }">
        <div class="col-12 d-xl-none ubo-step box-spacing" :class="activeStep(1)">
            <div class="ubo-step-number">
                <span>1</span>
            </div>
            <div class="ubo-step-name" data-qa="menue-mobil-firmenidentifikation" @click.prevent="close">Firmen­identifi­kation</div>
            <div class="ubo-step-progress">
                <span class="text-small">{{ fuellgrad.anteilFirmenidentifikation }}</span>
            </div>
            <i class="crefo-ui-icon" aria-hidden="true"></i>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="h1">Firmen­identifi­kation</div>
            <p>
                In diesem Bereich vervollständigen Sie die Angaben zu Ihrem Unternehmen.
            </p>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="ubo-form-mode-view" v-if="!isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" @click.prevent="open" data-qa="ubo-form-bearbeiten">
                        Bearbeiten
                        <i class="crefo-ui-icon icon-edit-pen" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Name und Adresse Ihres Unternehmens</div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('firmierung') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Name des Unternehmens</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ this.firmierung }}
                        <i class="crefo-brand-icon" :class="{'icon-lock':!inhabergefuehrt}" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('strasse') || hasErrors('hausnummer') || hasErrors('hausnummerZusatz') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Straße, Haus-Nr.</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ this.strasseHausnummer }}
                        <i class="crefo-brand-icon" :class="{'icon-lock':!inhabergefuehrt}" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('plz') || hasErrors('ort') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">PLZ, Ort</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ this.plzOrt }}
                        <i class="crefo-brand-icon" :class="{'icon-lock':!inhabergefuehrt}" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('land') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Land</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">
                        {{ this.land }}
                        <i class="crefo-brand-icon" :class="{'icon-lock':!inhabergefuehrt}" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-schliessen-unternehmen" @click.prevent="close">
                        Schliessen
                        <i class="crefo-ui-icon icon-close" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Name und Adresse Ihres Unternehmens</div>
                <div class="row">
                    <div class="col-12">
                        <input-field :rules="inhabergefuehrt ? 'max:300' : ''"
                                     type="text"
                                     field="firmierung"
                                     :readonly="!inhabergefuehrt"
                                     :label="$t('UboEingabeformularView.Label.Firmierung')"
                                     v-model="firmierung" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <input-field :rules="inhabergefuehrt ? 'max:46' : ''"
                                     type="text"
                                     field="strasse"
                                     :readonly="!inhabergefuehrt"
                                     :label="$t('UboEingabeformularView.Label.Strasse')"
                                     v-model="strasse" />

                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field :rules="inhabergefuehrt ? 'max:8' : ''"
                                     type="text"
                                     field="hausnummer"
                                     :readonly="!inhabergefuehrt"
                                     :label="$t('UboEingabeformularView.Label.Hausnummer')"
                                     v-model="hausnummer" />

                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field :rules="inhabergefuehrt ? 'max:10' : ''"
                                     type="text"
                                     field="hausnummerZusatz"
                                     :readonly="!inhabergefuehrt"
                                     :label="$t('UboEingabeformularView.Label.HausnummerZusatz')"
                                     v-model="hausnummerZusatz" />

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <input-field :rules="inhabergefuehrt ? {zip_code: '@land', max: 10} : ''"
                                     type="text"
                                     field="plz"
                                     :readonly="!inhabergefuehrt"
                                     :label="$t('UboEingabeformularView.Label.Plz')"
                                     v-model="plz" />

                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field :rules="inhabergefuehrt ? 'max:40' : ''"
                                     type="text"
                                     field="ort"
                                     :readonly="!inhabergefuehrt"
                                     :label="$t('UboEingabeformularView.Label.Ort')"
                                     v-model="ort" />

                    </div>
                </div>
                <div class="row" >
                    <div class="col-12" v-if="inhabergefuehrt">
                        <ubo-select field="land">
                            <select v-model="firmenidentifikation.adressdaten.land"
                                    data-qa="ubo-form-unternehmen-land">
                                <option v-for="l in laender" :value="l.value">
                                    {{ l.label }}
                                </option>
                            </select>
                        </ubo-select>
                    </div>
                    <div class="col-12" v-else>
                        <input-field
                                     type="text"
                                     field="land"
                                     readonly
                                     :label="$t('UboEingabeformularView.Label.Land')"
                                     v-model="land" />

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb pt">
                        <button class="btn btn-default btn-min btn-small" :disabled="stepHasErrors"
                                data-qa="ubo-form-aenderungen-uebernehmen"
                                @click.prevent="updateFormData">Änderungen übernehmen
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="ubo-form-mode-view" v-if="!isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-bearbeiten-kontakt" @click.prevent="open">
                        Bearbeiten
                        <i class="crefo-ui-icon icon-edit-pen" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Kontakt</div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('telefonnummer-land') || hasErrors('telefonnummer-vorwahl') || hasErrors('telefonnummer-nummer') || hasErrors('telefonnummer-durchwahl') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Festnetz-Nr.</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.telefon }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('mobilnummer-land') || hasErrors('mobilnummer-vorwahl') || hasErrors('mobilnummer-nummer') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Mobil-Nr.</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.mobil }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('faxnummer-land') || hasErrors('fax-vorwahl') || hasErrors('fax-nummer') || hasErrors('fax-durchwahl') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Fax-Nr.</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.fax }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('emailAdresse') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">E-Mail</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.emailAdresse }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('internetAdresse') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Homepage</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ this.internetAdresse }}</div>
                </div>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-schliessen-kontakt" @click.prevent="close">
                        Schliessen
                        <i class="crefo-ui-icon icon-close" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Kontakt</div>
                <div class="row">
                    <div class="col-12 ubo-input-wrapper-label">
                        <strong>Festnetz-Nr.</strong>
                    </div>
                    <div class="col-12 col-lg-3">
                        <ubo-select field="telefonnummer-land">
                            <select v-model="telefonnummerLand">
                                <option v-for="l in laendervorwahlen" :value="l.key">
                                    {{ l.value }}
                                </option>
                            </select>
                        </ubo-select>
                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field :rules="{ two_required_fields: { field_1: '@telefonnummer-nummer',field_2: '@telefonnummer-durchwahl'}, max: 18, numeric: ''}"
                                     type="text"
                                     field="telefonnummer-vorwahl"
                                     :label="$t('UboEingabeformularView.Label.TelefonnummerVorwahl')"
                                     v-model="telefonnummerVorwahl" />

                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field :rules="{two_required_fields: {field_1: '@telefonnummer-vorwahl',field_2: '@telefonnummer-durchwahl'}, max: 18, numeric: ''}"
                                     type="text"
                                     field="telefonnummer-nummer"
                                     :label="$t('UboEingabeformularView.Label.TelefonnummerNummer')"
                                     v-model="telefonnummerNummer" />

                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field rules="max:10|numeric"
                                     type="text"
                                     field="telefonnummer-durchwahl"
                                     :label="$t('UboEingabeformularView.Label.TelefonnummerDurchwahl')"
                                     v-model="telefonnummerDurchwahl" />

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 ubo-input-wrapper-label">
                        <strong>Mobilfunk-Nr.</strong>
                    </div>
                    <div class="col-12 col-lg-3">
                        <ubo-select field="mobilnummer-land">
                            <select v-model="mobilnummerLand">
                                <option v-for="l in laendervorwahlen" :value="l.key">
                                    {{ l.value }}
                                </option>
                            </select>
                        </ubo-select>
                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field :rules="{required_if: 'mobilnummer-nummer', max: 18, numeric: ''}"
                                     type="text"
                                     field="mobilnummer-vorwahl"
                                     :label="$t('UboEingabeformularView.Label.MobilVorwahl')"
                                     v-model="mobilnummerVorwahl" />
                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field :rules="{required_if: 'mobilnummer-vorwahl', max: 18, numeric: ''}"
                                     type="text"
                                     field="mobilnummer-nummer"
                                     :label="$t('UboEingabeformularView.Label.MobilNummer')"
                                     v-model="mobilnummerNummer" />

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 ubo-input-wrapper-label">
                        <strong>Fax-Nr.</strong>
                    </div>
                    <div class="col-12 col-lg-3">
                        <ubo-select field="faxnummer-land">
                            <select v-model="faxnummerLand">
                                <option v-for="l in laendervorwahlen" :value="l.key">
                                    {{ l.value }}
                                </option>
                            </select>
                        </ubo-select>
                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field :rules="{two_required_fields: {field_1: '@fax-nummer',field_2: '@fax-durchwahl'}, max: 18, numeric: ''}"
                                     type="text"
                                     field="fax-vorwahl"
                                     :label="$t('UboEingabeformularView.Label.FaxVorwahl')"
                                     v-model="faxnummerVorwahl" />

                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field :rules="{two_required_fields: {field_1: '@fax-vorwahl',field_2: '@fax-durchwahl'}, max: 18, numeric: ''}"
                                     type="text"
                                     field="fax-nummer"
                                     :label="$t('UboEingabeformularView.Label.FaxNummer')"
                                     v-model="faxnummerNummer" />

                    </div>
                    <div class="col-12 col-lg-3">
                        <input-field rules="max:10|numeric"
                                     type="text"
                                     field="fax-durchwahl"
                                     :label="$t('UboEingabeformularView.Label.FaxDurchwahl')"
                                     v-model="faxnummerDurchwahl" />

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 ubo-input-wrapper-label">
                        <strong>Internet</strong>
                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field :rules="{regex: /.+@.+\..{2,}/, max: 255,}"
                                     type="text"
                                     field="emailAdresse"
                                     :label="$t('UboEingabeformularView.Label.EmailAdresse')"
                                     v-model="emailAdresse" />

                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field :rules="{regex: /.+\..{2,}/, max: 255}"
                                     type="text"
                                     field="internetAdresse"
                                     :label="$t('UboEingabeformularView.Label.InternetAdresse')"
                                     v-model="internetAdresse" />

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb">
                        <button data-qa="ubo-uebernehmen-kontakt" class="btn btn-default btn-min btn-small" :disabled="stepHasErrors"
                                @click.prevent="updateFormData">Änderungen übernehmen
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="ubo-form-mode-view" v-if="!isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-bearbeiten-steuernummer" @click.prevent="open">
                        Bearbeiten
                        <i class="crefo-ui-icon icon-edit-pen" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Steu­er­num­mer</div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('steuernummer') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Steuer-Nr.</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ steuernummer }}</div>
                </div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('umsatzsteuerIdentnummer') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Umsatzsteuer-Nr.</div>
                    <div class="col-12 col-lg-6 ubo-form-display-data">{{ umsatzsteuerIdentnummer }}</div>
                </div>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-schliessen-steuernummer" @click.prevent="close">
                        Schliessen
                        <i class="crefo-ui-icon icon-close" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Steu­er­num­mer</div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <input-field rules="max:40"
                                     type="text"
                                     field="steuernummer"
                                     :label="$t('UboEingabeformularView.Label.Steuernummer')"
                                     v-model="steuernummer" />

                    </div>
                    <div class="col-12 col-lg-6">
                        <input-field rules="max:40"
                                     type="text"
                                     field="umsatzsteuerIdentnummer"
                                     :label="$t('UboEingabeformularView.Label.UmsatzsteuerIdentnummer')"
                                     v-model="umsatzsteuerIdentnummer" />

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb">
                        <button data-qa="ubo-uebernehmen-steuernummer" class="btn btn-default btn-min btn-small" :disabled="stepHasErrors"
                                @click.prevent="updateFormData">Änderungen übernehmen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UboInput from '@/components/commons/ubo-input'
import UboSelect from '@/components/commons/ubo-select'
import InputField from '@/components/commons/input-field'
import * as formatter from "@/helper/formatter";
import * as keys from "@/helper/keys";
import * as mapper from "@/helper/mapper"
export default {
    name: 'Firmenidentifikation',
    components: { UboInput, UboSelect, InputField },
    props: {
        menuConfig: {},
        errors: {},
        inhabergefuehrt: false,
        fuellgrad: {}
    },
    data() {
        return {
            firmenidentifikation: {},
            stepHasErrors: false
        }
    },
    computed: {
        renderEditMode() {
            return (this.menuConfig.edit && this.menuConfig.activeStep === 1)
                || this.$store.state.userModule.unternehmensdatenPreValidated === false
                || this.stepHasErrors
        },
        isEditModeActive() {
            return this.menuConfig.edit && this.menuConfig.activeStep === 1
        },
        hasErrors: function () {
            return fieldname => this.errors && this.errors[fieldname] instanceof Array && this.errors[fieldname].length
        },
        laender() {
            return keys.countries(this.$store.state.userModule.language)
        },
        laendervorwahlen() {
            return keys.internationalAreaCodes()
        },
        // compound fields
        strasseHausnummer: {
            get() {
                let result = ''
                if (this.firmenidentifikation && this.firmenidentifikation.adressdaten) {
                    if (this.firmenidentifikation.adressdaten.strasse) {
                        result = this.firmenidentifikation.adressdaten.strasse
                    }
                    if (this.firmenidentifikation.adressdaten.hausnummer) {
                        result += ' '
                        result += this.firmenidentifikation.adressdaten.hausnummer
                    }
                    if (this.firmenidentifikation.adressdaten.hausnummerZusatz) {
                        result += this.firmenidentifikation.adressdaten.hausnummerZusatz
                    }
                }
                return result
            }
        },
        plzOrt: {
            get() {
                let result = ''
                if (this.firmenidentifikation && this.firmenidentifikation.adressdaten) {
                    if (this.firmenidentifikation.adressdaten.plz) {
                        result = this.firmenidentifikation.adressdaten.plz
                    }
                    if (this.firmenidentifikation.adressdaten.ort) {
                        result += ' '
                        result += this.firmenidentifikation.adressdaten.ort
                    }
                }
                return result
            }
        },
        land: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.adressdaten) {
                    result = keys.landNameFor(this.firmenidentifikation.adressdaten.land, this.$store.state.userModule.language)
                }
                return result
            },
            set(newLand) {
                if (this.firmenidentifikation && this.firmenidentifikation.adressdaten) {
                    this.firmenidentifikation.adressdaten.land = newLand
                }
            }
        },
        telefon: {
            get() {
                let result = ''
                if (this.firmenidentifikation && this.firmenidentifikation.telefonnummer) {
                    if (this.firmenidentifikation.telefonnummer.land) {
                        result = keys.internationalAreaCodeFor(this.firmenidentifikation.telefonnummer.land)
                    }
                    if (this.firmenidentifikation.telefonnummer.vorwahl) {
                        result += ' '
                        result += this.firmenidentifikation.telefonnummer.vorwahl
                    }
                    if (this.firmenidentifikation.telefonnummer.nummer) {
                        result += ' '
                        result += this.firmenidentifikation.telefonnummer.nummer
                    }
                    if (this.firmenidentifikation.telefonnummer.durchwahl) {
                        result += ' '
                        result += this.firmenidentifikation.telefonnummer.durchwahl
                    }
                }
                return result
            }
        },
        mobil: {
            get() {
                let result = ''
                if (this.firmenidentifikation && this.firmenidentifikation.mobilnummer) {
                    if (this.firmenidentifikation.mobilnummer.land) {
                        result = keys.internationalAreaCodeFor(this.firmenidentifikation.mobilnummer.land)
                    }
                    if (this.firmenidentifikation.mobilnummer.vorwahl) {
                        result += ' '
                        result += this.firmenidentifikation.mobilnummer.vorwahl
                    }
                    if (this.firmenidentifikation.mobilnummer.nummer) {
                        result += ' '
                        result += this.firmenidentifikation.mobilnummer.nummer
                    }
                    if (this.firmenidentifikation.mobilnummer.durchwahl) {
                        result += ' '
                        result += this.firmenidentifikation.mobilnummer.durchwahl
                    }
                }
                return result
            }
        },
        fax: {
            get() {
                let result = ''
                if (this.firmenidentifikation && this.firmenidentifikation.faxnummer) {
                    if (this.firmenidentifikation.faxnummer.land) {
                        result = keys.internationalAreaCodeFor(this.firmenidentifikation.faxnummer.land)
                    }
                    if (this.firmenidentifikation.faxnummer.vorwahl) {
                        result += ' '
                        result += this.firmenidentifikation.faxnummer.vorwahl
                    }
                    if (this.firmenidentifikation.faxnummer.nummer) {
                        result += ' '
                        result += this.firmenidentifikation.faxnummer.nummer
                    }
                    if (this.firmenidentifikation.faxnummer.durchwahl) {
                        result += ' '
                        result += this.firmenidentifikation.faxnummer.durchwahl
                    }
                }
                return result
            }
        },
        // Single fields
        firmierung: {
            get() {
                let result = null
                if (this.firmenidentifikation) {
                    result = this.firmenidentifikation.firmierung
                }
                return result
            },
            set(newFirmierung) {
                if (this.firmenidentifikation) {
                    this.firmenidentifikation.firmierung = formatter.parseString(newFirmierung)
                }
            }
        },
        strasse: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.adressdaten) {
                    result = formatter.formatString(this.firmenidentifikation.adressdaten.strasse)
                }
                return result
            },
            set(newStrasse) {
                if (this.firmenidentifikation && this.firmenidentifikation.adressdaten) {
                    this.firmenidentifikation.adressdaten.strasse = formatter.parseString(newStrasse)
                }
            }
        },
        hausnummer: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.adressdaten) {
                    result = result = formatter.formatNumber(this.firmenidentifikation.adressdaten.hausnummer)
                }
                return result
            },
            set(newHausnummer) {
                if (this.firmenidentifikation && this.firmenidentifikation.adressdaten) {
                    this.firmenidentifikation.adressdaten.hausnummer = formatter.parseNumber(newHausnummer)
                }
            }
        },
        hausnummerZusatz: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.adressdaten) {
                    result = formatter.formatString(this.firmenidentifikation.adressdaten.hausnummerZusatz)

                }
                return result
            },
            set(newHausnummerZusatz) {
                if (this.firmenidentifikation && this.firmenidentifikation.adressdaten) {
                    this.firmenidentifikation.adressdaten.hausnummerZusatz = formatter.parseString(newHausnummerZusatz)
                }

            }
        },
        plz: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.adressdaten) {
                    result = formatter.formatString(this.firmenidentifikation.adressdaten.plz)
                }
                return result
            },
            set(newPlz) {
                if (this.firmenidentifikation && this.firmenidentifikation.adressdaten) {
                    this.firmenidentifikation.adressdaten.plz = formatter.parseString(newPlz)
                }
            }
        },
        ort: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.adressdaten) {
                    result = formatter.formatString(this.firmenidentifikation.adressdaten.ort)
                }
                return result
            },
            set(newOrt) {
                if (this.firmenidentifikation && this.firmenidentifikation.adressdaten) {
                    this.firmenidentifikation.adressdaten.ort = formatter.parseString(newOrt)
                }
            }
        },
        telefonnummerLand: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.telefonnummer) {
                    result = this.firmenidentifikation.telefonnummer.land
                }
                return result
            },
            set(newTelefonnummerLaendervorwahl) {
                if (this.firmenidentifikation) {
                    this.firmenidentifikation.telefonnummer = mapper.communicationNumberBuilder(newTelefonnummerLaendervorwahl, this.telefonnummerVorwahl, this.telefonnummerNummer, this.telefonnummerDurchwahl)
                }
            }
        },
        telefonnummerVorwahl: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.telefonnummer) {
                    result = formatter.formatString(this.firmenidentifikation.telefonnummer.vorwahl)
                }
                return result
            },
            set(newTelefonnummerVorwahl) {
                if (this.firmenidentifikation) {
                    this.firmenidentifikation.telefonnummer = mapper.communicationNumberBuilder(this.telefonnummerLand, newTelefonnummerVorwahl, this.telefonnummerNummer, this.telefonnummerDurchwahl)
                }
            }
        },
        telefonnummerNummer: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.telefonnummer) {
                    result = formatter.formatString(this.firmenidentifikation.telefonnummer.nummer)
                }
                return result
            },
            set(newTelefonnummer) {
                if (this.firmenidentifikation) {
                    this.firmenidentifikation.telefonnummer = mapper.communicationNumberBuilder(this.telefonnummerLand, this.telefonnummerVorwahl, newTelefonnummer, this.telefonnummerDurchwahl)
                }
            }
        },
        telefonnummerDurchwahl: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.telefonnummer) {
                    result = formatter.formatString(this.firmenidentifikation.telefonnummer.durchwahl)
                }
                return result
            },
            set(newTelefonnummerDurchwahl) {
                if (this.firmenidentifikation) {
                    this.firmenidentifikation.telefonnummer = mapper.communicationNumberBuilder(this.telefonnummerLand, this.telefonnummerVorwahl, this.telefonnummerNummer, newTelefonnummerDurchwahl)
                }
            }
        },
        mobilnummerLand: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.mobilnummer) {
                    result = this.firmenidentifikation.mobilnummer.land
                }
                return result
            },
            set(newMobilLaendervorwahl) {
                if (this.firmenidentifikation) {
                    this.firmenidentifikation.mobilnummer = mapper.communicationNumberBuilder(newMobilLaendervorwahl, this.mobilnummerVorwahl, this.mobilnummerNummer, null)
                }
            }
        },
        mobilnummerNummer: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.mobilnummer) {
                    result = formatter.formatString(this.firmenidentifikation.mobilnummer.nummer)
                }
                return result
            },
            set(newMobilNummer) {
                if (this.firmenidentifikation) {
                    this.firmenidentifikation.mobilnummer = mapper.communicationNumberBuilder(this.mobilnummerLand, this.mobilnummerVorwahl, newMobilNummer, null)
                }
            }
        },
        mobilnummerVorwahl: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.mobilnummer) {
                    result = formatter.formatString(this.firmenidentifikation.mobilnummer.vorwahl)
                }
                return result
            },
            set(newMobilVorwahl) {
                if (this.firmenidentifikation) {
                    this.firmenidentifikation.mobilnummer = mapper.communicationNumberBuilder(this.mobilnummerLand, newMobilVorwahl, this.mobilnummerNummer, null)
                }
            }
        },
        faxnummerLand: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.faxnummer) {
                    result = this.firmenidentifikation.faxnummer.land
                }
                return result
            },
            set(newFaxnummerLand) {
                if (this.firmenidentifikation) {
                    this.firmenidentifikation.faxnummer = mapper.communicationNumberBuilder(newFaxnummerLand, this.faxnummerVorwahl, this.faxnummerNummer, this.faxnummerDurchwahl)
                }
            }

        },
        faxnummerVorwahl: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.faxnummer) {
                    result = formatter.formatString(this.firmenidentifikation.faxnummer.vorwahl)
                }
                return result
            },
            set(newFaxVorwahl) {
                if (this.firmenidentifikation) {
                    this.firmenidentifikation.faxnummer = mapper.communicationNumberBuilder(this.faxnummerLand, newFaxVorwahl, this.faxnummerNummer, this.faxnummerDurchwahl)
                }
            }
        },
        faxnummerNummer: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.faxnummer) {
                    result = formatter.formatString(this.firmenidentifikation.faxnummer.nummer)
                }
                return result
            },
            set(newFaxnummer) {
                if (this.firmenidentifikation) {
                    this.firmenidentifikation.faxnummer = mapper.communicationNumberBuilder(this.faxnummerLand, this.faxnummerVorwahl, newFaxnummer, this.faxnummerDurchwahl)
                }
            }
        },
        faxnummerDurchwahl: {
            get() {
                let result = null
                if (this.firmenidentifikation && this.firmenidentifikation.faxnummer) {
                    result = formatter.formatString(this.firmenidentifikation.faxnummer.durchwahl)
                }
                return result
            },
            set(newFaxDurchwahl) {
                if (this.firmenidentifikation) {
                    this.firmenidentifikation.faxnummer = mapper.communicationNumberBuilder(this.faxnummerLand, this.faxnummerVorwahl, this.faxnummerNummer, newFaxDurchwahl)
                }
            }
        },
        emailAdresse: {
            get() {
                let result = null
                if (this.firmenidentifikation) {
                    result = formatter.formatString(this.firmenidentifikation.emailAdresse)
                }
                return result
            },
            set(newEmailAdresse) {
                if (this.firmenidentifikation) {
                    this.firmenidentifikation.emailAdresse = formatter.parseString(newEmailAdresse)
                }
            }
        },
        internetAdresse: {
            get() {
                let result = null
                if (this.firmenidentifikation) {
                    result = formatter.formatString(this.firmenidentifikation.internetAdresse)
                }
                return result
            },
            set(newInternetAdresse) {
                if (this.firmenidentifikation) {
                    this.firmenidentifikation.internetAdresse = formatter.parseString(newInternetAdresse)
                }
            }
        },
        steuernummer: {
            get() {
                let result = null
                if (this.firmenidentifikation) {
                    result = formatter.formatString(this.firmenidentifikation.steuernummer)
                }
                return result
            },
            set(newSteuernummer) {
                if (this.firmenidentifikation) {
                    this.firmenidentifikation.steuernummer = formatter.parseString(newSteuernummer)
                }
            }
        },
        umsatzsteuerIdentnummer: {
            get() {
                let result = null
                if (this.firmenidentifikation) {
                    result = formatter.formatString(this.firmenidentifikation.umsatzsteuerIdentnummer)
                }
                return result
            },
            set(newUmsatzsteuerIdentnummer) {
                if (this.firmenidentifikation) {
                    this.firmenidentifikation.umsatzsteuerIdentnummer = formatter.parseString(newUmsatzsteuerIdentnummer)
                }
            }
        }
    },
    methods: {
        activeFormStep(step) {
            return this.menuConfig.activeStep === step
        },
        activeStep(step) {
            let result = null
            if (this.menuConfig.activeStep === step) {
                result = 'ubo-step--active'
            }
            return result
        },
        open() {
            this.restoreFormData()
            if (this.firmenidentifikation.telefonnummer === null) {
                this.firmenidentifikation.telefonnummer = {
                    land: "DE",
                    vorwahl: null,
                    nummer: null,
                    durchwahl: null
                }
            }
            if (this.firmenidentifikation.mobilnummer === null) {
                this.firmenidentifikation.mobilnummer = {
                    land: "DE",
                    vorwahl: null,
                    nummer: null,
                    durchwahl: null
                }
            }
            if (this.firmenidentifikation.faxnummer === null) {
                this.firmenidentifikation.faxnummer = {
                    land: "DE",
                    vorwahl: null,
                    nummer: null,
                    durchwahl: null
                }
            }
            this.$emit("edit-step", 1)
        },
        close() {
            this.$emit('select-step', 1)
        },
        restoreFormData() {
            try {
                this.firmenidentifikation = JSON.parse(JSON.stringify(this.$store.state.userModule.unternehmensdaten.firmenidentifikation))
            } catch (e) {}
        },
        updateFormData() {
            this.$store.commit('userModule/ON_CHANGE_COMPANY_IDENTIFICATION', this.firmenidentifikation)
            this.$emit('save-step')
            this.$emit("select-step", 1)
        }
    },
    inject: {
        editEventBus: "editEventBus"
    },
    created: function () {
        this.editEventBus.$on('has-errors-step-1', (hasErrors) => {
            this.stepHasErrors = hasErrors
        })
        this.restoreFormData()
    },
    watch: {
        land: function (newVal, oldVal) {
            this.$emit("validate")
        },
        telefonnummerVorwahl: function (newVal, oldVal) {
            this.$emit("validate")
        },
        telefonnummerNummer: function (newVal, oldVal) {
            this.$emit("validate")
        },
        telefonnummerDurchwahl: function (newVal, oldVal) {
            this.$emit("validate")
        },
        mobilnummerVorwahl: function (newVal, oldVal) {
            this.$emit("validate")
        },
        mobilnummerNummer: function (newVal, oldVal) {
            this.$emit("validate")
        },
        faxnummerVorwahl: function (newVal, oldVal) {
            this.$emit("validate")
        },
        faxnummerNummer: function (newVal, oldVal) {
            this.$emit("validate")
        },
        faxnummerDurchwahl: function (newVal, oldVal) {
            this.$emit("validate")
        }
    }
}
</script>

<!-- Formularkomponenten -->
<style scoped lang="less">
.ubo-form-chapter {
    display: none;
    position: relative;
    border-bottom: 1px solid #edece5;

    &:last-child {
        padding-bottom: 30px !important;

        @media only screen and (min-width: 1200px) {
            padding-bottom: 40px !important;
            border-bottom: 0 none transparent;
        }
    }

    .h2 {
        padding-right: 140px;
    }

    .ubo-form-edit {
        position: absolute;
        top: 33px;
        right: 17px;
        width: 130px;
        height: 20px;
        font-weight: 600;
        text-align: right;

        @media only screen and (max-width: 1199px) {
            top: 23px;
        }
        @media only screen and (max-width: 767px) {
            top: 18px;
        }

        a {
            text-transform: uppercase;

            i {
                position: relative;
                top: 5px;
            }
        }
    }

    .ubo-form-display {
        align-items: flex-end;

        &:last-child {
            margin-bottom: 30px;
        }

        .ubo-form-display-label, .ubo-form-display-data {
            padding-top: 30px;
            padding-bottom: 15px;
            border-bottom: 1px solid #edece5;

            @media only screen and (max-width: 991px) {
                border-bottom: 0 none transparent;
            }
        }

        .ubo-form-display-label {
            padding-right: 15px;

            @media only screen and (max-width: 991px) {
                padding-bottom: 5px;
            }
        }

        .ubo-form-display-data {
            font-weight: 600;

            .crefo-brand-icon {
                color: #b5b5b5;
                font-size: 12px;
            }

            @media only screen and (max-width: 991px) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    .ubo-form-mode-edit {
        .ubo-input-wrapper-label {
            margin-bottom: -2px;

            strong {
                font-size: 20px;
                color: #004884;
            }
        }

        .crefo-input {
            margin-bottom: 0;

            &::v-deep .select-selected {
                min-height: 56px;
            }
            &::v-deep .select-items {
                div {
                    min-height: 48px;
                }
            }

            .input-group {
                margin-bottom: 10px;

                input {
                    &::placeholder {
                        color: #b5b5b5;
                    }
                }
            }

        }
    }
}

.ubo-form-step {
    &--active {
        .ubo-form-chapter {
            display: block;
        }
    }
}
</style>
