<template>
    <div class="row ubo-form-step" :class="{ 'ubo-form-step--active': activeFormStep(5), 'has-errors': stepHasErrors }">
        <div class="col-12 d-xl-none ubo-step box-spacing" :class="activeStep(5)">
            <div class="ubo-step-number">
                <span>5</span>
            </div>
            <div class="ubo-step-name" data-qa="menue-mobil-unterlagen" @click.prevent="close">Unterlagen einreichen</div>
            <div class="ubo-step-progress">
                <span
                    class="text-small"
                    v-show="fuellgrad.anteilUnterlagen"
                >
                    {{ fuellgrad.anteilUnterlagen }}
                </span>
            </div>
            <i class="crefo-ui-icon" aria-hidden="true"></i>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="h1">Unterlagen einreichen</div>
            <p>
                In diesem Bereich können Sie ergänzende Unterlagen zu Ihrem Unternehmen anhängen, um möglichst alle
                Informationen, die relevant sind, bereitstellen zu können.
            </p>
            <p>
                Beim Vorliegen eines vollständigen Jahresabschlusses (Bilanz, Gewinn- und Verlustrechnung, Anhang)
                oder einer aktuellen betriebswirtschaftlichen Auswertung (BWA) kann eine deutlich eingehendere
                Beurteilung Ihres Unternehmens erfolgen.
            </p>
        </div>
        <div class="col-12 box-spacing ubo-form-chapter">
            <div class="ubo-form-mode-view mb-small" v-if="!isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-bearbeiten-unterlagen" @click.prevent="open">
                        Bearbeiten
                        <i class="crefo-ui-icon icon-edit-pen" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Ihre Anhänge</div>
                <template v-if="dokumente.length > 0">
                    <div
                        v-for="(file, index) in dokumente"
                        class="row no-gutters ubo-form-display"
                        :data-qa="'ubo-dokument-' + (index + 1)"
                        :class="{ 'has-errors': hasErrors('dokumente-files-' + index) }"
                    >
                        <div class="col-12 col-lg-4 ubo-form-display-label">Anhang {{ index + 1}}</div>
                        <div class="col-12 col-lg-6 ubo-form-display-data">
                            <span
                                class="filename"
                                data-qa="ubo-form-unterlagen-filename"
                            >{{ getFilename(file) }}</span>
                            <span
                                class="filesize"
                                data-qa="ubo-form-unterlagen-filesize"
                            >&nbsp;({{ getFileSizeFormatted(file) }})</span>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <p>
                        Sie haben noch keine Dokumente hinterlegt.
                    </p>
                </template>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="ubo-form-edit">
                    <a href="#" data-qa="ubo-schliessen-unterlagen" @click.prevent="close">
                        Schliessen
                        <i class="crefo-ui-icon icon-close" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="h2">Ihre Anhänge</div>
                <div class="row">
                    <div class="col-12 mb">
                        <validation-provider ref="dokumenteUploadValidation">
                            <crefo-ui-upload
                                accept="application/pdf"
                                @crefo-ui-upload-drop="storeFilesForLaterUpload"
                                @crefo-ui-upload-click="storeFilesForLaterUpload"
                            >
                                <div class="text-small">
                                    <sup>*</sup>
                                    Sie können maximal 10 Dateien hochladen mit insgesamt bis zu 10 MB. Sie können Unterlagen
                                    ausschließlich in Form von PDF-Dokumenten einreichen.
                                </div>
                            </crefo-ui-upload>
                        </validation-provider>
                    </div>
                    <template v-if="dokumente">
                        <div class="col-12 mb">
                            <div
                                v-for="(file, index) in dokumente"
                                :data-qa="'ubo-dokument-temporary-' + (index + 1)"
                                class="row mb-small"
                            >
                                <div class="col-12">
                                    <div class="fileholder">
                                        <span
                                            class="fileicon"
                                            :class="'fileicon--' + getFiletype(file)"
                                        >&nbsp;</span>
                                        <div class="filenameholder">
                                            <span
                                                class="filename"
                                                data-qa="ubo-form-unterlagen-filename"
                                            >{{ getFilename(file) }}</span>
                                            <span
                                                class="filesize"
                                                data-qa="ubo-form-unterlagen-filesize"
                                            >&nbsp;&nbsp;({{ getFileSizeFormatted(file) }})</span>
                                        </div>
                                        <div
                                            class="filedeleteholder"
                                            data-qa="ubo-form-unterlagen-delete"
                                            @click.prevent="onRemoveFile(index)"
                                        >
                                            <span class="filedelete">
                                                <img src="@/assets/icons/trash.svg" width="16" height="17" alt="">
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        v-if="dokumenteErrors[index] && dokumenteErrors[index].length"
                                        class="fileholder fileholder--error"
                                        data-qa="ubo-form-unterlagen-fehler"
                                    >
                                        <div
                                            v-for="(dokumentError) in dokumenteErrors[index]"
                                            class="filenameholder filenameholder--error"
                                        >
                                            {{ $t('UboEingabeformularView.Error.Upload.' + dokumentError) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="dokumenteGlobalErrors.length"
                                class="row mb-small"
                            >
                                <div class="col-12">
                                    <div
                                        class="fileholder fileholder--globalError"
                                        data-qa="ubo-form-unterlagen-summe-fehler"
                                    >
                                        <div
                                            v-for="(dokumentGlobalError) in dokumenteGlobalErrors"
                                            class="filenameholder filenameholder--globalError"
                                        >
                                            {{ $t('UboEingabeformularView.Error.Upload.' + dokumentGlobalError) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="ubo-form-mode-view pt" v-if="!isEditModeActive">
                <div class="h2 no-padding-right" style="margin-bottom: 2px;">An­mer­kun­gen zu Ihren Do­ku­men­ten</div>
                <div class="row no-gutters ubo-form-display" :class="{ 'has-errors': hasErrors('dokumente-anmerkungen') }">
                    <div class="col-12 col-lg-4 ubo-form-display-label">Weitere Anmerkungen und Angaben zu Ihren Dokumenten</div>
                    <div
                        class="col-12 col-lg-6 ubo-form-display-data"
                        data-qa="ubo-form-unterlagen-anmerkungen-value"
                    >{{ this.unterlagenAnmerkungen }}</div>
                </div>
            </div>
            <div class="ubo-form-mode-edit" v-if="renderEditMode" v-show="isEditModeActive">
                <div class="h2 mb-small no-padding-right">An­mer­kun­gen zu Ihren Do­ku­men­ten</div>
                <div class="row">
                    <div class="col-12">
                        <ubo-input
                            field="dokumente-anmerkungen"
                            data-qa="ubo-form-unterlagen-anmerkungen-holder"
                            :has-dokumente="dokumente.length > 0"
                            :class="{ 'crefo-input--disabled': isUnterlagenAnmerkungenDisabled }"
                            style="padding-top: 0;"
                        >
                            <textarea v-model="unterlagenAnmerkungen"
                                      data-qa="ubo-form-unterlagen-anmerkungen"
                                      data-no-crefo-ui="true"
                                      :class="{ 'crefo-textarea--disabled': isUnterlagenAnmerkungenDisabled }"
                                      :disabled="isUnterlagenAnmerkungenDisabled">
                            </textarea>
                        </ubo-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb">
                        <button data-qa="ubo-uebernehmen-unterlagen" class="btn btn-default btn-min btn-small" :disabled="stepHasErrors"
                                @click.prevent="updateFormData()">Änderungen übernehmen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as formatter from '@/helper/formatter'
import UboInput from '@/components/commons/ubo-input'
import { ValidationProvider } from 'vee-validate'
export default {
    name: 'Unterlagen',
    components: { UboInput, ValidationProvider },
    props: {
        menuConfig: {},
        errors: {},
        fuellgrad: {},
        maxSingleFileSize: {
            type: Number,
            default: 10000000
        },
        maxAllFileSize: {
            type: Number,
            default: 10000000
        },
        maxFiles: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            stepHasErrors: false,
            dokumente: [],
            dokumenteErrors: [],
            dokumenteGlobalErrors: [],
            unterlagenAnmerkungenValue: null
        }
    },
    computed: {
        renderEditMode() {
            return (this.menuConfig.edit && this.menuConfig.activeStep === 5)
                || this.$store.state.userModule.unternehmensdatenPreValidated === false
                || this.stepHasErrors
        },
        isEditModeActive() {
            return this.menuConfig.edit && this.menuConfig.activeStep === 5
        },
        hasErrors: function () {
            return fieldname => this.errors && this.errors[fieldname] instanceof Array && this.errors[fieldname].length
        },
        unterlagenAnmerkungen: {
            get() {
                return formatter.formatString(this.unterlagenAnmerkungenValue)
            },
            set(newAnmerkungen) {
                this.unterlagenAnmerkungenValue = formatter.parseString(newAnmerkungen)
            }
        },
        isUnterlagenAnmerkungenDisabled: function () {
            return (this.unterlagenAnmerkungenValue === null || this.unterlagenAnmerkungenValue == '')
                && this.dokumente.length === 0
        }
    },
    methods: {
        onRemoveFile(index) {
            this.dokumenteErrors.splice(index, 1)
            this.dokumente.splice(index, 1)
            this.checkGlobalFilesRules()
        },
        async storeFilesForLaterUpload(files) {
            this.checkFilesForUpload(files)
            this.dokumente = this.dokumente.concat(files)
            this.checkGlobalFilesRules()
        },
        checkFilesForUpload(files) {
            files.forEach(file => this.checkFileForUpload(file))
        },
        checkFileForUpload(file) {
            const fileErrors = []
            if (file.size && file.size > this.maxSingleFileSize) {
                fileErrors.push('FILE_SIZE')
            }
            if (file.type && /\/(pdf|PDF)$/.test(file.type) === false) {
                fileErrors.push('FILE_TYPE')
            } else if (!file.name || /.+\.(pdf|PDF)$/.test(file.name) === false) {
                fileErrors.push('FILE_TYPE')
            }
            this.dokumenteErrors.push(fileErrors)
        },
        checkGlobalFilesRules() {
            this.dokumenteGlobalErrors = []
            if (this.dokumente.length > this.maxFiles) {
                this.dokumenteGlobalErrors.push('FILES_COUNT')
            }
            let sumOfFilesSize = 0
            this.dokumente.forEach(dokument => sumOfFilesSize += dokument.size ? dokument.size : 0)
            if (sumOfFilesSize > this.maxAllFileSize) {
                this.dokumenteGlobalErrors.push('FILES_SIZE')
            }
            this.updateUploadErrorState()
        },
        updateUploadErrorState() {
            let hasErrors = false

            if (this.dokumenteGlobalErrors.length) {
                hasErrors = true
            } else if (this.dokumenteErrors.length) {
                hasErrors = this.dokumenteErrors.find(error => error.length) === undefined ? false : true
            }

            if (hasErrors) {
                this.$refs.dokumenteUploadValidation.setErrors({
                    'dokumente-files-0': [ 'FILE_UPLOAD_ERROR' ]
                })
            } else {
                this.$refs.dokumenteUploadValidation.reset()
            }
        },
        getFilename(file) {
            if (file && file.name) {
                return file.name
            } else if (file && file.fileName) {
                return file.fileName
            } else {
                return ''
            }
        },
        getFiletype(file) {
            if (file && typeof file.type === 'string') {
                const type = file.type.toLowerCase()
                if (type.includes('/pdf')) {
                    return 'pdf'
                } else if (type.includes('/jpeg') || type.includes('/jpg')) {
                    return 'jpg'
                } else if (type.includes('/png')) {
                    return 'png'
                } else if (type.includes('text/plain')) {
                    return 'txt'
                } else if (type.includes('spreadsheetml.sheet')) {
                    return 'xlsx'
                } else if (type.includes('/msexcel')) {
                    return 'xls'
                } else if (type.includes('/xml')) {
                    return 'xml'
                } else if (type.includes('/csv')) {
                    return 'csv'
                } else {
                    return 'file'
                }
            } else {
                return 'file'
            }
        },
        getFileSize(file) {
            if (file && file.size) {
                return file.size
            } else if (file && file.fileSize) {
                return file.fileSize
            } else {
                return 0
            }
        },
        getFileSizeFormatted(file) {
            const fileSize = this.getFileSize(file)
            if (fileSize === 0) {
                return '0 Bytes'
            }
            const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            const i = Math.floor(Math.log(fileSize) / Math.log(1000))
            return parseFloat((fileSize / Math.pow(1000, i)).toFixed(2)) + ' ' + sizes[i]
        },
        activeFormStep(step) {
            return this.menuConfig.activeStep === step
        },
        activeStep(step) {
            let result = null
            if (this.menuConfig.activeStep === step) {
                result = 'ubo-step--active'
            }
            return result
        },
        open() {
            this.restoreFormData()
            this.$emit("edit-step", 5)
        },
        close() {
            this.$emit('select-step', 5)
        },
        restoreFormData() {
            this.dokumente = []
            this.dokumenteErrors = []
            this.dokumenteGlobalErrors = []
            if (this.$store.state.userModule.dokumente instanceof Array) {
                this.$store.state.userModule.dokumente.forEach(file => {
                    this.dokumente.push(file)
                    this.dokumenteErrors.push([])
                })
            }
            this.unterlagenAnmerkungenValue = typeof this.$store.state.userModule.dokumenteAnmerkungen === 'string' ? this.$store.state.userModule.dokumenteAnmerkungen : null
            if (this.$refs.dokumenteUploadValidation) {
                this.$refs.dokumenteUploadValidation.reset()
            }
        },
        updateFormData() {
            this.$store.commit('userModule/ON_CHANGE_DOKUMENTE', this.dokumente)
            this.$store.commit('userModule/ON_CHANGE_DOKUMENTE_ANMERKUNGEN', this.unterlagenAnmerkungenValue ? String(this.unterlagenAnmerkungenValue) : null)
            this.$emit('save-step')
            this.$emit("select-step", 5)
        }
    },
    inject: {
        editEventBus: "editEventBus"
    },
    created: function () {
        this.editEventBus.$on('has-errors-step-5', (hasErrors) => {
            this.stepHasErrors = hasErrors
        })
        this.restoreFormData()
    }
}
</script>

<style scoped lang="less">
.filesize {
    color: #7f7f7f;
    font-weight: normal;
}

.fileholder {
    position: relative;

    .fileicon {
        position: absolute;
        display: inline-block;
        left: 0;
        top: 0;
        width: 27px;
        height: 30px;
        background-image: url('../../assets/icons/add-file.svg');
        background-repeat: no-repeat;
        background-position: top right;

        &--pdf {
            background-image: url('../../assets/icons/pdf.svg');
        }
        &--csv {
            background-image: url('../../assets/icons/csv.svg');
        }
        &--jpg {
            background-image: url('../../assets/icons/jpg.svg');
        }
        &--png {
            background-image: url('../../assets/icons/png.svg');
        }
        &--txt {
            background-image: url('../../assets/icons/txt.svg');
        }
        &--xls {
            background-image: url('../../assets/icons/xls.svg');
        }
        &--xlsx {
            background-image: url('../../assets/icons/xlsx.svg');
        }
        &--xml {
            background-image: url('../../assets/icons/xml.svg');
        }
    }

    .filenameholder {
        margin-left: 37px;
        margin-right: 37px;
        padding-top: 7px;
        overflow-wrap: break-word;

        &--error, &--globalError {
            color: #e05469;
        }
    }

    .filedeleteholder {
        position: absolute;
        display: inline-block;
        top: 0;
        right: 0;
        width: 27px;
        height: 30px;
        cursor: pointer;

        .filedelete {
            margin-top: 7px;
            display: inline-block;
            float: right;
            width: 16px;
            height: 17px;

            img {
                filter: invert(44%) sepia(43%) saturate(2700%) hue-rotate(168deg) brightness(95%) contrast(104%);
            }
        }

        &:hover {
            .filedelete {
                img {
                    filter: invert(26%) sepia(80%) saturate(1179%) hue-rotate(176deg) brightness(109%) contrast(106%);
                }
            }
        }
    }
}

.crefo-input {
    padding-top: 12px;
    padding-bottom: 12px;

    .input-group {
        .error-msg {
            min-height: 30px;
            visibility: hidden;
            display: inline-block !important;
            font-size: 12px !important;
        }

        &.has-error {

            & > input[type="checkbox"] + label:before {
                border-color: #e05469;
            }

            label {
                color: #e05469;
            }

            .error-msg {
                visibility: visible;
            }
        }
    }
    textarea {
        min-height: 200px !important;
    }
}

.ubo-form-chapter {
    display: none;
    position: relative;
    border-bottom: 1px solid #edece5;

    &:last-child {
        padding-bottom: 30px !important;

        @media only screen and (min-width: 1200px) {
            padding-bottom: 40px !important;
            border-bottom: 0 none transparent;
        }
    }

    .h2 {
        padding-right: 140px;
    }

    .ubo-form-edit {
        position: absolute;
        top: 33px;
        right: 17px;
        width: 130px;
        height: 20px;
        font-weight: 600;
        text-align: right;

        @media only screen and (max-width: 1199px) {
            top: 23px;
        }
        @media only screen and (max-width: 767px) {
            top: 18px;
        }

        a {
            text-transform: uppercase;

            i {
                position: relative;
                top: 5px;
            }
        }
    }

    .ubo-form-display {
        align-items: flex-end;

        &:last-child {
            margin-bottom: 30px;
        }

        .ubo-form-display-label, .ubo-form-display-data {
            padding-top: 30px;
            padding-bottom: 15px;
            border-bottom: 1px solid #edece5;

            @media only screen and (max-width: 991px) {
                border-bottom: 0 none transparent;
            }
        }

        .ubo-form-display-label {
            padding-right: 15px;

            @media only screen and (max-width: 991px) {
                padding-bottom: 5px;
            }
        }

        .ubo-form-display-data {
            font-weight: 600;
            overflow-wrap: break-word;

            .crefo-brand-icon {
                color: #b5b5b5;
                font-size: 12px;
            }

            @media only screen and (max-width: 991px) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    .ubo-form-mode-edit {
        .ubo-input-wrapper-label {
            margin-bottom: -2px;

            strong {
                font-size: 20px;
                color: #004884;
            }
        }

        .crefo-input {
            margin-bottom: 0;

            .input-group {
                margin-bottom: 10px;

                input {
                    &::placeholder {
                        color: #b5b5b5;
                    }
                }
            }
        }

        .crefo-textarea--disabled {
            background-color: #edece5;
        }
    }

    .no-padding-right {
        padding-right: 0;
    }
}

.ubo-form-step {
    &--active {
        .ubo-form-chapter {
            display: block;
        }
    }
}
</style>
